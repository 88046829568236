import ActionTypes from 'constants/ActionTypes';
import update from 'immutability-helper';

const initialState = {
    data: [],
    error: {
        status: null,
        text: null,
    },
    errorDelete: {
        status: null,
        text: null,
    },
    errorNew: {
        status: null,
        text: null,
    },
    errorUpdate: {
        status: null,
        text: null,
    },
    fetching: false,
};

const reportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_DELETE_REPORT_FETCHING:
        case ActionTypes.DATA_UPDATE_REPORT_FETCHING:
        case ActionTypes.DATA_NEW_REPORT_FETCHING:
        case ActionTypes.DATA_REPORTS_FETCHING: {
            return update(state, {
                fetching: {
                    $set: true,
                },
            });
        }
        case ActionTypes.DATA_REPORTS_RECEIVED: {
            return update(state, {
                data: {
                    $set: action.payload,
                },
                fetching: {
                    $set: false,
                },
                error: {
                    $set: initialState.error,
                },
            });
        }
        case ActionTypes.DATA_NEW_TRACKING_RECEIVED: {
            return update(state, {
                data: {
                    $set: action.payload.reports,
                },
            });
        }
        case ActionTypes.DATA_NEW_REPORT_RECEIVED: {
            return update(state, {
                data: {
                    $set: action.payload.reports,
                },
                fetching: {
                    $set: false,
                },
                errorNew: {
                    $set: initialState.errorNew,
                },
            });
        }
        case ActionTypes.DATA_UPDATE_REPORT_RECEIVED: {
            return update(state, {
                data: {
                    $apply: reports =>
                        reports.map(report => {
                            if (report.id === action.payload.id) {
                                return update(report, {
                                    alertTriggers: { $set: action.payload.alertTriggers },
                                    emails: { $set: action.payload.emails },
                                    name: { $set: action.payload.name },
                                    type: { $set: action.payload.type },
                                });
                            } else {
                                return report;
                            }
                        }),
                },
                fetching: {
                    $set: false,
                },
                errorUpdate: {
                    $set: initialState.errorUpdate,
                },
            });
        }
        case ActionTypes.DATA_DELETE_REPORT_RECEIVED: {
            return update(state, {
                data: {
                    $set: action.payload.reports,
                },
                fetching: {
                    $set: false,
                },
                errorDelete: {
                    $set: initialState.errorUpdate,
                },
            });
        }
        case ActionTypes.DATA_REPORTS_ERROR: {
            return update(state, {
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                fetching: {
                    $set: initialState.fetching,
                },
            });
        }
        case ActionTypes.DATA_NEW_REPORT_ERROR: {
            return update(state, {
                errorNew: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                fetching: {
                    $set: initialState.fetching,
                },
            });
        }
        case ActionTypes.DATA_UPDATE_REPORT_ERROR: {
            return update(state, {
                errorUpdate: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                fetching: {
                    $set: initialState.fetching,
                },
            });
        }
        case ActionTypes.DATA_DELETE_REPORT_ERROR: {
            return update(state, {
                errorDelete: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                fetching: {
                    $set: initialState.fetching,
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default reportsReducer;
