import { createSelector } from 'reselect';
import moment from 'moment';
import update from 'immutability-helper';
import {
    all,
    ascend,
    compose,
    concat,
    includes,
    descend,
    filter,
    find,
    flatten,
    forEach,
    isEmpty,
    isNil,
    keys,
    map,
    prop,
    propEq,
    slice,
    sortBy,
    sortWith,
    sum,
    toPairs,
    uniq,
    values,
    zipObj,
    toLower,
    trim,
    transduce,
    prepend,
    flip,
    pipe,
    length,
    groupBy,
    uniqBy,
    pathOr,
    divide,
} from 'ramda';
import Defaults from 'mangools-commons/lib/constants/Defaults';
import { DESKTOP, MOBILE } from 'mangools-commons/lib/constants/Platforms';

import TimeframeService from 'services/TimeframeService';
import KeywordSorterService from 'services/KeywordSorterService';
import KeywordFilterService from 'services/KeywordFilterService';
import DateFormatService from 'services/DateFormatService';
import TrackingGroupService from 'services/TrackingGroupService';
import RouterService from 'services/RouterService';

import {
    addKeywordsPanelSelectedListIdsSelector,
    currentQuerySelector,
    currentRouteSelector,
    defaultSortingSettingsSelector,
    defaultTrackingGroupOrderedKeysSelector,
    limitsFetchedSelector,
    newTrackingSelectedListIdsSelector,
    reportsPanelCurrentReportIdSelector,
    topLevelAnnouncementMessageVisibilitySelector,
    trackingFilterSettingsSelector,
    trackingListFilterSelector,
    trackingSelectedKeywordIdsSelector,
    trackingTimeframeSelector,
    trackingQuickFilterSettingsSelector,
    trackingListDropdownSearchSelector,
    locationsgListDropdownSearchSelector,
} from 'selectors/commonSelectors';

import { NUMBER_OF_RANKERS } from 'constants/Other';
import Values from 'constants/Values';
import { REFERENCE_POINT, POINT_1, POINT_2, POINT_3 } from 'constants/TimeframePoints';
import { ANNOUNCEMENT_EMPTY_MESSAGE } from 'reducers/data/announcementsReducer';
import RankDistributionScales from 'constants/RankDistributionScales';
import RoutePaths from 'constants/RoutePaths';
import { newTrackingKeywordsSelector } from 'selectors/sharedSelectors';
import { normalizedStr } from 'services/KeywordUtils';

const trackingsSelector = state => state.data.trackings.data;
export const trackingsFetchedSelector = state => state.data.trackings.fetched;

// NOTE: This selectors are based on current tracking, which has to be downloaded before accessible
export const currentTrackingSelector = createSelector(
    [trackingsFetchedSelector, trackingsSelector, currentQuerySelector],
    (fetched, trackings, query) => {
        if (fetched === true) {
            return find(propEq('id', query.id))(trackings);
        } else {
            return null;
        }
    },
);

export const newTrackingCreatedAreTipsSuggestedSelector = createSelector([trackingsSelector], trackings => {
    return trackings.length <= 1;
});

export const currentTrackingDomainSelector = createSelector([currentTrackingSelector], currentTracking => {
    if (!isNil(currentTracking)) {
        return currentTracking.domain;
    } else {
        return null;
    }
});

export const currentTrackingIdSelector = createSelector([currentTrackingSelector], currentTracking => {
    if (!isNil(currentTracking)) {
        return currentTracking.id;
    } else {
        return null;
    }
});

export const currentTrackingCreatedAtSelector = createSelector([currentTrackingSelector], currentTracking => {
    if (!isNil(currentTracking)) {
        return currentTracking.createdAt;
    } else {
        return null;
    }
});

export const fetchingTrackingsSelector = state => state.data.trackings.fetching;
export const trackingCountSelector = state => state.data.trackings.data.length;

export const firstTrackingIdSelector = state => {
    const firstTracking = state.data.trackings.data[0];

    if (!isNil(firstTracking)) {
        return firstTracking.id;
    } else {
        return null;
    }
};

export const trackingSelector = (state, trackingId) => find(propEq('id', trackingId))(state.data.trackings.data);

export const sortedByDomainTrackingsSelector = createSelector(
    [trackingsSelector, state => state.ui.settings.isWithDeletedTrackings],
    (trackings, isWithDeleted) => {
        if (isWithDeleted) {
            const sorter = sortWith([descend(prop('isDeleted')), ascend(prop('domain'))]);
            const sorted = sorter(trackings);
            return sorted;
        }
        return sortBy(prop('domain'))(trackings);
    },
);

export const filteredAndSortedTrackingItemsSelector = createSelector(
    [sortedByDomainTrackingsSelector, trackingListFilterSelector],
    (sortedTrackings, trackingFilter) => {
        if (trackingFilter.length > 0) {
            const formattedTrackingFilter = compose(toLower, trim)(trackingFilter);
            return sortedTrackings.filter(tracking => tracking.domain.includes(formattedTrackingFilter));
        } else {
            return sortedTrackings;
        }
    },
);

const sortedTrackingsWithPerformanceIndexesSelector = createSelector(
    [filteredAndSortedTrackingItemsSelector],
    trackings =>
        trackings.map(tracking => {
            const timeframe = TimeframeService.getDefault(tracking.createdAt);
            const convert = compose(map(zipObj(['date', 'value'])), toPairs);

            const performanceIndexes = map(
                tf => ({
                    date: tf.date,
                    performanceIndex: tf.value.performanceIndex,
                }),
                convert(tracking.timeframes),
            );

            const indexesInTimeframe = filter(
                index => moment(index.date).isBetween(timeframe.from, timeframe.to, null, '[]'),
                performanceIndexes,
            );

            return {
                createdAt: tracking.createdAt,
                domain: tracking.domain,
                performanceIndexes: indexesInTimeframe,
                id: tracking.id,
                trackingConfig: tracking.trackingConfig,
                trackedKeywordsCount: tracking.trackedKeywordsCount,
                location: tracking.location,
                platformId: tracking.platformId,
                reportsActive: tracking.reportsActive,
                isDeleted: tracking.isDeleted,
            };
        }),
);

/* eslint-disable no-param-reassign */
export const groupedAndSortedTrackingsObjectSelector = createSelector(
    [sortedTrackingsWithPerformanceIndexesSelector, state => state.ui.settings.isWithDeletedTrackings],
    (trackings, isWithDeletedTrackings) => {
        // Create array of group objects with `domain-location_id` as key
        // and an object with `Mobile.label` as key and tracking as value
        // and second object with `Desktop.label` as key and tracking as value
        // Use `null` if tracking for that platform does not exists

        const trackingGroups = {};
        trackings.reduce((result, item) => {
            let key = TrackingGroupService.generateKey({
                domain: item.domain,
                locationId: item.location.id,
            });

            if (isWithDeletedTrackings && item.isDeleted) {
                key = `deleted_${item.id}`;
            }

            if (isNil(result[key])) {
                result[key] = {
                    id: key,
                    [DESKTOP.label]: item.platformId === DESKTOP.id ? item : null,
                    [MOBILE.label]: item.platformId === MOBILE.id ? item : null,
                    ...(item.isDeleted ? { isDeleted: true } : {}),
                };
            } else if (item.platformId === DESKTOP.id) {
                result[key][DESKTOP.label] = item;
            } else if (item.platformId === MOBILE.id) {
                result[key][MOBILE.label] = item;
            }

            return result;
        }, trackingGroups);

        return trackingGroups;
    },
);
/* eslint-enable no-param-reassign */

export const trackingGroupsCountSelector = createSelector(groupedAndSortedTrackingsObjectSelector, pipe(keys, length));

const groupTrackingsByDomainAndFilterBySearch = (trackings, search) => {
    const formattedSearch = compose(toLower, trim)(search);

    const groupedTrackings = groupBy(prop('domain'), trackings);
    const filteredAndSortedGroups = pipe(
        toPairs,
        filter(([domain]) => domain.includes(formattedSearch)),
        map(([domain, items]) => {
            const convert = compose(map(zipObj(['date', 'value'])), toPairs);

            const timeframeArrayGrouped = {};
            let numberOfActiveTrackings = 0;

            const formattedItems = map(item => {
                if (!item.isDeleted) {
                    numberOfActiveTrackings += 1;
                }
                const timeframe = TimeframeService.getDefault(item.createdAt);
                const performanceIndexes = map(
                    tf => ({
                        date: tf.date,
                        performanceIndex: tf.value.performanceIndex,
                        estimatedVisits: tf.value.estimatedVisits,
                    }),
                    convert(item.timeframes),
                );

                const timeframeArray = filter(timeframeItem => {
                    if (
                        !item.isDeleted &&
                        moment(timeframeItem.date).isBetween(timeframe.from, timeframe.to, null, '[]')
                    ) {
                        const existingPerformanceIndexes = pathOr(
                            [],
                            [timeframeItem.date, 'performanceIndex'],
                            timeframeArrayGrouped,
                        );
                        const existingEstimatedVisits = pathOr(
                            [],
                            [timeframeItem.date, 'estimatedVisits'],
                            timeframeArrayGrouped,
                        );
                        timeframeArrayGrouped[timeframeItem.date] = {
                            performanceIndex: [...existingPerformanceIndexes, timeframeItem.performanceIndex],
                            estimatedVisits: [...existingEstimatedVisits, timeframeItem.estimatedVisits],
                        };
                        return true;
                    }
                    return false;
                }, performanceIndexes);

                return {
                    ...item,
                    timeframeArray,
                };
            })(items);

            const timeframeArrayGroupedCalculated = compose(
                sortBy(prop('date')),
                map(([date, tf]) => ({
                    date,
                    performanceIndex:
                        tf.performanceIndex.reduce((acc, val) => acc + val, 0) / tf.performanceIndex.length,
                    estimatedVisits: tf.estimatedVisits.reduce((acc, val) => acc + val, 0),
                })),
                toPairs,
            )(timeframeArrayGrouped);

            return {
                domain,
                numberOfActiveTrackings,
                items: sortBy(prop('createdAt'))(formattedItems),
                timeframeArrayGrouped: timeframeArrayGroupedCalculated,
            };
        }),
        sortBy(prop('domain')),
    )(groupedTrackings);
    return filteredAndSortedGroups;
};

export const groupedTrackingsByDomain = createSelector(
    [trackingsSelector, trackingListFilterSelector],
    (trackings, search) => {
        return groupTrackingsByDomainAndFilterBySearch(trackings, search);
    },
);

export const groupedTrackingsByDomainToTrackingsDropdown = createSelector(
    [trackingsSelector, trackingListDropdownSearchSelector],
    (trackings, search) => {
        return groupTrackingsByDomainAndFilterBySearch(trackings, search);
    },
);

export const locationsWithSameDomain = createSelector(
    [trackingsSelector, currentTrackingSelector, locationsgListDropdownSearchSelector],
    (trackings, currentTracling, locationSearch) => {
        const formattedSearch = compose(toLower, trim)(locationSearch);

        if (!currentTracling) {
            return [];
        }
        const filteredLocations = pipe(
            filter(
                ({ domain, location }) =>
                    domain === currentTracling.domain &&
                    compose(toLower, trim)(location.label).includes(formattedSearch),
            ),
            map(({ location, id }) => ({
                location,
                trackingId: id,
            })),
        )(trackings);
        return uniqBy(({ location }) => location.label)(filteredLocations);
    },
);

export const groupedAndSortedTrackingsSelector = createSelector(
    [groupedAndSortedTrackingsObjectSelector, defaultTrackingGroupOrderedKeysSelector],
    (groupedAndSortedTrackingsObject, trackingGroupOrderedKeys) => {
        // Sorting by trackingGroupOrderedKeys if non-empty array
        if (isEmpty(trackingGroupOrderedKeys)) {
            return values(groupedAndSortedTrackingsObject);
        } else {
            // Grab all tracking group ids (keys in object)
            let remainingTrackingGroupIds = keys(groupedAndSortedTrackingsObject);

            // const deletedKeys = remainingTrackingGroupIds.filter((k)=>k.startsWith("deleted"))

            const deleted = remainingTrackingGroupIds
                .filter(k => k.startsWith('deleted'))
                .map(id => {
                    return groupedAndSortedTrackingsObject[id];
                });

            remainingTrackingGroupIds = remainingTrackingGroupIds.filter(k => !k.startsWith('deleted'));

            // Sort by trackingGroupOrderedKeys by mapping over saved group ids (keys)
            const sorted = trackingGroupOrderedKeys
                .map(groupId => {
                    // Remove used id from remaining ids
                    remainingTrackingGroupIds = remainingTrackingGroupIds.filter(id => id !== groupId);

                    // Map actual tracking group object for that groupId
                    return groupedAndSortedTrackingsObject[groupId];
                })
                .filter(i => i);

            // Handle missing ids in grouped keys array (added groups not present in sorted keys)
            const remaining = remainingTrackingGroupIds.map(groupId => groupedAndSortedTrackingsObject[groupId]);
            const sortedWithRemaining = concat(deleted, concat(sorted, remaining));

            // Handle incorrect (deleted tracking groups not present in sorted keys) (skip them)
            return sortedWithRemaining.filter(trackingGroup => !isNil(trackingGroup));
        }
    },
);

export const currentTrackingOpposingPlatformTrackingSelector = createSelector(
    [currentTrackingSelector, groupedAndSortedTrackingsObjectSelector],
    (currentTracking, groupedAndSortedTrackingsObject) => {
        if (isNil(currentTracking)) {
            return null;
        } else {
            const key = TrackingGroupService.generateKey({
                domain: currentTracking.domain,
                locationId: currentTracking.location.id,
            });

            return currentTracking.platformId === DESKTOP.id
                ? groupedAndSortedTrackingsObject[key]?.[MOBILE.label]
                : groupedAndSortedTrackingsObject[key]?.[DESKTOP.label];
        }
    },
);

// Tracking detail
const trackingDetailTimeframePointsSelector = state => state.data.trackingDetail.data.timeframePoints;
const trackingDetailSelectedStatsTimeframesSelector = state => state.data.trackingDetail.data.selectedStats.timeframes;
const trackingDetailAllStatsTimeframesSelector = state => state.data.trackingDetail.data.allStats.timeframes;

export const areTrackingDetailSelectedStatsSelector = createSelector(
    trackingDetailSelectedStatsTimeframesSelector,
    selectedStats => !isNil(selectedStats) && !isEmpty(selectedStats),
);

const trackingDetailPrimaryStatsTimeframesSelector = createSelector(
    [
        areTrackingDetailSelectedStatsSelector,
        trackingDetailSelectedStatsTimeframesSelector,
        trackingDetailAllStatsTimeframesSelector,
    ],
    (areSelectedStats, selectedStats, allStats) => (areSelectedStats ? selectedStats : allStats),
);

export const trackingDetailTagsSelector = state => state.data.trackingDetail.data.tags.data;
export const trackingDetailCreatedAtSelector = state => state.data.trackingDetail.data.createdAt;
export const trackingDetailDataSelector = state => state.data.trackingDetail.data;
export const trackingDetailDomainSelector = state => state.data.trackingDetail.data.domain;
export const trackingDetailFetchingSelector = state => state.data.trackingDetail.fetching;
export const trackingDetailStatsFetchingSelector = state => state.data.trackingDetail.fetchingStats;
export const trackingDetailTimeframeDataFetchingSelector = state => state.data.trackingDetail.fetchingTimeframeData;
export const trackingDetailIdSelector = state => state.data.trackingDetail.data.id;
export const trackingDetailKeywordCountSelector = state => state.data.trackingDetail.data.keywords.length;
export const trackingDetailKeywordsSelector = state => state.data.trackingDetail.data.keywords;
export const trackingDetailLocationIdSelector = state => state.data.trackingDetail.data.location.id;
export const trackingDetailLocationSelector = state => state.data.trackingDetail.data.location;
export const trackingDetailPlatformIdSelector = state => state.data.trackingDetail.data.platformId;
export const trackingDetailShareTokenSelector = state => state.data.trackingDetail.data.shareToken;
export const trackingDetailTagsFetchingSelector = state => state.data.trackingDetail.data.tags.fetching;
export const trackingDetailFetchingShareTokenSelector = state => state.data.trackingDetail.fetchingShareToken;
export const trackingDetailGoogleBusinessNameSelector = state => state.data.trackingDetail.data.trackingConfig?.name;
export const trackingDetailGoogleBusinessDataSelector = state => state.data.trackingDetail.data.trackingConfig;
export const trackingDetailWhitelabelSettingsSelector = state => state.data.trackingDetail.data.whiteLabel;

export const isTrackingDetailWhitelabelLogoActiveSelector = createSelector(
    [trackingDetailWhitelabelSettingsSelector],
    whiteLabel => !!(whiteLabel.enabled && whiteLabel.logo),
);

export const trackingDetailTimeframePointsObjectSelector = createSelector(
    [trackingTimeframeSelector, trackingDetailTimeframePointsSelector],
    (timeframe, dates) => ({
        [REFERENCE_POINT]: !isNil(timeframe.to) ? timeframe.to : Defaults.NOT_AVAILABLE,
        [POINT_1]: !isNil(dates[0]) ? moment(dates[0]).endOf('day') : Defaults.NOT_AVAILABLE,
        [POINT_2]: !isNil(dates[1]) ? moment(dates[1]).endOf('day') : Defaults.NOT_AVAILABLE,
        [POINT_3]: !isNil(dates[2]) ? moment(dates[2]).endOf('day') : Defaults.NOT_AVAILABLE,
    }),
);

const getTrackingDetailEstimatedVisitsSelector = timeframes => {
    if (!isNil(timeframes)) {
        const convert = compose(map(zipObj(['date', 'value'])), toPairs);
        return map(tf => ({ date: tf.date, estimatedVisits: tf.value.estimatedVisits }), convert(timeframes));
    } else {
        return [];
    }
};
export const trackingDetailEstimatedVisitsSelector = createSelector(
    [trackingDetailPrimaryStatsTimeframesSelector],
    getTrackingDetailEstimatedVisitsSelector,
);
export const trackingDetailAllEstimatedVisitsSelector = createSelector(
    [trackingDetailAllStatsTimeframesSelector],
    getTrackingDetailEstimatedVisitsSelector,
);

const getTrackingDetailPerformanceIndexesData = timeframes => {
    if (!isNil(timeframes)) {
        const convert = compose(map(zipObj(['date', 'value'])), toPairs);
        return map(tf => ({ date: tf.date, performanceIndex: tf.value.performanceIndex }), convert(timeframes));
    } else {
        return [];
    }
};

const getTrackingDetailVisibilityIndexesData = timeframes => {
    if (!isNil(timeframes)) {
        const convert = compose(map(zipObj(['date', 'value'])), toPairs);
        return map(tf => ({ date: tf.date, visibilityIndex: tf.value.visibilityIndex }), convert(timeframes));
    }
    return [];
};

export const trackingDetailPerformanceIndexesSelector = createSelector(
    [trackingDetailPrimaryStatsTimeframesSelector],
    getTrackingDetailPerformanceIndexesData,
);

export const trackingDetailVisibilityIndexesSelector = createSelector(
    [trackingDetailPrimaryStatsTimeframesSelector],
    getTrackingDetailVisibilityIndexesData,
);

export const trackingDetailAllPerformanceIndexesSelector = createSelector(
    [trackingDetailAllStatsTimeframesSelector],
    getTrackingDetailPerformanceIndexesData,
);

const getTrackingDetailTimeframePointsEstimatedVisitsSelector = (timeframe, points, timeframes) => {
    if (!isNil(timeframes) && points[POINT_1] !== Defaults.NOT_AVAILABLE) {
        const tfRefPoint = timeframes[DateFormatService.formatISO(timeframe.to)];
        const tfPoint1 = timeframes[DateFormatService.formatISO(points[POINT_1])];
        const tfPoint2 = timeframes[DateFormatService.formatISO(points[POINT_2])];
        const tfPoint3 = timeframes[DateFormatService.formatISO(points[POINT_3])];

        return {
            [REFERENCE_POINT]: !isNil(tfRefPoint) ? tfRefPoint.estimatedVisits : Defaults.NOT_AVAILABLE,
            [POINT_1]: !isNil(tfPoint1) ? tfPoint1.estimatedVisits : Defaults.NOT_AVAILABLE,
            [POINT_2]: !isNil(tfPoint2) ? tfPoint2.estimatedVisits : Defaults.NOT_AVAILABLE,
            [POINT_3]: !isNil(tfPoint3) ? tfPoint3.estimatedVisits : Defaults.NOT_AVAILABLE,
        };
    } else {
        return {
            [REFERENCE_POINT]: Defaults.NOT_AVAILABLE,
            [POINT_1]: Defaults.NOT_AVAILABLE,
            [POINT_2]: Defaults.NOT_AVAILABLE,
            [POINT_3]: Defaults.NOT_AVAILABLE,
        };
    }
};

export const trackingDetailTimeframePointsEstimatedVisitsSelector = createSelector(
    [
        trackingTimeframeSelector,
        trackingDetailTimeframePointsObjectSelector,
        trackingDetailPrimaryStatsTimeframesSelector,
    ],
    getTrackingDetailTimeframePointsEstimatedVisitsSelector,
);

export const trackingDetailAllTimeframePointsEstimatedVisitsSelector = createSelector(
    [trackingTimeframeSelector, trackingDetailTimeframePointsObjectSelector, trackingDetailAllStatsTimeframesSelector],
    getTrackingDetailTimeframePointsEstimatedVisitsSelector,
);

const getTrackingDetailTimeframePointsPerformanceIndexesData = (timeframe, points, timeframes) => {
    if (!isNil(timeframes) && points[POINT_1] !== Defaults.NOT_AVAILABLE) {
        const tfRefPoint = timeframes[DateFormatService.formatISO(timeframe.to)];
        const tfPoint1 = timeframes[DateFormatService.formatISO(points[POINT_1])];
        const tfPoint2 = timeframes[DateFormatService.formatISO(points[POINT_2])];
        const tfPoint3 = timeframes[DateFormatService.formatISO(points[POINT_3])];

        return {
            [REFERENCE_POINT]: !isNil(tfRefPoint) ? tfRefPoint.performanceIndex : Defaults.NOT_AVAILABLE,
            [POINT_1]: !isNil(tfPoint1) ? tfPoint1.performanceIndex : Defaults.NOT_AVAILABLE,
            [POINT_2]: !isNil(tfPoint2) ? tfPoint2.performanceIndex : Defaults.NOT_AVAILABLE,
            [POINT_3]: !isNil(tfPoint3) ? tfPoint3.performanceIndex : Defaults.NOT_AVAILABLE,
        };
    } else {
        return {
            [REFERENCE_POINT]: Defaults.NOT_AVAILABLE,
            [POINT_1]: Defaults.NOT_AVAILABLE,
            [POINT_2]: Defaults.NOT_AVAILABLE,
            [POINT_3]: Defaults.NOT_AVAILABLE,
        };
    }
};

const getTrackingDetailTimeframePointsVisibilityIndexesData = (timeframe, points, timeframes) => {
    if (!isNil(timeframes) && points[POINT_1] !== Defaults.NOT_AVAILABLE) {
        const tfRefPoint = timeframes[DateFormatService.formatISO(timeframe.to)];
        const tfPoint1 = timeframes[DateFormatService.formatISO(points[POINT_1])];
        const tfPoint2 = timeframes[DateFormatService.formatISO(points[POINT_2])];
        const tfPoint3 = timeframes[DateFormatService.formatISO(points[POINT_3])];

        return {
            [REFERENCE_POINT]: !isNil(tfRefPoint) ? tfRefPoint.visibilityIndex : Defaults.NOT_AVAILABLE,
            [POINT_1]: !isNil(tfPoint1) ? tfPoint1.visibilityIndex : Defaults.NOT_AVAILABLE,
            [POINT_2]: !isNil(tfPoint2) ? tfPoint2.visibilityIndex : Defaults.NOT_AVAILABLE,
            [POINT_3]: !isNil(tfPoint3) ? tfPoint3.visibilityIndex : Defaults.NOT_AVAILABLE,
        };
    }

    return {
        [REFERENCE_POINT]: Defaults.NOT_AVAILABLE,
        [POINT_1]: Defaults.NOT_AVAILABLE,
        [POINT_2]: Defaults.NOT_AVAILABLE,
        [POINT_3]: Defaults.NOT_AVAILABLE,
    };
};

export const trackingDetailTimeframePointsPerformanceIndexesSelector = createSelector(
    [
        trackingTimeframeSelector,
        trackingDetailTimeframePointsObjectSelector,
        trackingDetailPrimaryStatsTimeframesSelector,
    ],
    getTrackingDetailTimeframePointsPerformanceIndexesData,
);

export const trackingDetailTimeframePointsVisibilityIndexesSelector = createSelector(
    [
        trackingTimeframeSelector,
        trackingDetailTimeframePointsObjectSelector,
        trackingDetailPrimaryStatsTimeframesSelector,
    ],
    getTrackingDetailTimeframePointsVisibilityIndexesData,
);

export const trackingDetailAllTimeframePointsPerformanceIndexesSelector = createSelector(
    [trackingTimeframeSelector, trackingDetailTimeframePointsObjectSelector, trackingDetailAllStatsTimeframesSelector],
    getTrackingDetailTimeframePointsPerformanceIndexesData,
);

export const trackingDetailTimeframePointsRankDistributionSelector = createSelector(
    [
        trackingTimeframeSelector,
        trackingDetailTimeframePointsObjectSelector,
        trackingDetailPrimaryStatsTimeframesSelector,
    ],
    (timeframe, points, timeframes) => {
        if (!isNil(timeframes) && points[POINT_1] !== Defaults.NOT_AVAILABLE) {
            const tfRefPoint = timeframes[DateFormatService.formatISO(timeframe.to)];
            const tfPoint1 = timeframes[DateFormatService.formatISO(points[POINT_1])];
            const tfPoint2 = timeframes[DateFormatService.formatISO(points[POINT_2])];
            const tfPoint3 = timeframes[DateFormatService.formatISO(points[POINT_3])];

            return {
                [REFERENCE_POINT]: !isNil(tfRefPoint) ? tfRefPoint.rankDistribution : Defaults.NOT_AVAILABLE,
                [POINT_1]: !isNil(tfPoint1) ? tfPoint1.rankDistribution : Defaults.NOT_AVAILABLE,
                [POINT_2]: !isNil(tfPoint2) ? tfPoint2.rankDistribution : Defaults.NOT_AVAILABLE,
                [POINT_3]: !isNil(tfPoint3) ? tfPoint3.rankDistribution : Defaults.NOT_AVAILABLE,
            };
        } else {
            return {
                [REFERENCE_POINT]: Defaults.NOT_AVAILABLE,
                [POINT_1]: Defaults.NOT_AVAILABLE,
                [POINT_2]: Defaults.NOT_AVAILABLE,
                [POINT_3]: Defaults.NOT_AVAILABLE,
            };
        }
    },
);

export const trackingDetailRankDistributionSelector = createSelector(
    [trackingDetailPrimaryStatsTimeframesSelector],
    timeframes => {
        if (!isNil(timeframes)) {
            const convert = compose(map(zipObj(['date', 'value'])), toPairs);
            return map(
                tf => ({
                    date: tf.date,
                    [RankDistributionScales.TOP]: tf.value.rankDistribution[RankDistributionScales.TOP],
                    [RankDistributionScales.TOP_3]: tf.value.rankDistribution[RankDistributionScales.TOP_3],
                    [RankDistributionScales.TOP_10]: tf.value.rankDistribution[RankDistributionScales.TOP_10],
                    [RankDistributionScales.TOP_20]: tf.value.rankDistribution[RankDistributionScales.TOP_20],
                    [RankDistributionScales.TOP_100]: tf.value.rankDistribution[RankDistributionScales.TOP_100],
                    [RankDistributionScales.REST]: tf.value.rankDistribution[RankDistributionScales.REST],
                }),
                convert(timeframes),
            );
        } else {
            return [];
        }
    },
);

export const trackingDetailKeywordsWithRankChangesSelector = createSelector(
    [trackingDetailKeywordsSelector],
    keywords => {
        const modifiedKws = keywords.map(keyword => {
            const changes = keyword.rankHistory.map(value => {
                if (
                    isNil(keyword.rank) ||
                    keyword.rank === Defaults.NOT_AVAILABLE ||
                    isNil(value) ||
                    value === Defaults.NOT_AVAILABLE
                ) {
                    return Defaults.NOT_AVAILABLE;
                } else if (keyword.rank === Values.RANK_MORE_THAN_100 && value !== Values.RANK_MORE_THAN_100) {
                    // Currently 101+ but was not before
                    return value - 101;
                } else if (keyword.rank !== Values.RANK_MORE_THAN_100 && value === Values.RANK_MORE_THAN_100) {
                    // Currently has value but was 101+ before
                    return 101 - keyword.rank;
                } else {
                    return value - keyword.rank;
                }
            });

            return {
                performanceIndexChange: keyword.performanceIndexChange,
                estimatedVisitsChange: keyword.estimatedVisitsChange,
                createdAt: keyword.createdAt,
                isDeleted: keyword.isDeleted,
                estimatedVisits: keyword.estimatedVisits,
                estimatedVisitsTotal: keyword.estimatedVisitsTotal,
                id: keyword.id,
                keyword: keyword.keyword,
                lastCheckedAt: keyword.lastCheckedAt,
                rank: keyword.rank,
                rankAvg: keyword.rankAvg,
                rankBest: keyword.rankBest,
                mapPack: keyword.mapPack || {},
                featuredSnippet: keyword.featuredSnippet || {},
                rankChange: keyword.rankChange,
                rankHistory: {
                    [POINT_1]: {
                        value: keyword.rankHistory[0],
                        change: changes[0],
                    },
                    [POINT_2]: {
                        value: keyword.rankHistory[1],
                        change: changes[1],
                    },
                    [POINT_3]: {
                        value: keyword.rankHistory[2],
                        change: changes[2],
                    },
                },
                searchVolume: keyword.searchVolume,
                tags: keyword.tags,
                url: keyword.url,
                isMoreUrl: keyword.isMoreUrl,
                estimatedVisitsTotalChange: keyword.estimatedVisitsTotalChange,
                visualMetrics: keyword.visualMetrics,
                isEmptyMainArray: keyword.isEmptyMainArray,
                nearestBeforeDay: keyword.nearestBeforeDay,
                serpFeatures: keyword.serpFeatures,
            };
        });

        return modifiedKws;
    },
);

export const filteredAndSortedTrackingKeywordsSelector = createSelector(
    [
        trackingDetailKeywordsWithRankChangesSelector,
        trackingFilterSettingsSelector,
        trackingQuickFilterSettingsSelector,
        defaultSortingSettingsSelector,
        state => state.ui.settings.isWithDeletedKeywords,
    ],
    (keywords, filterSettings, quickFilterSettings, sortingSettings, isWithDeletedKeywords) => {
        const filtered = KeywordFilterService.filter(keywords, filterSettings, quickFilterSettings);
        const sorted = KeywordSorterService.sort(filtered, sortingSettings);
        if (isWithDeletedKeywords) {
            return filter(i => i.isDeleted, sorted);
        }
        return sorted;
    },
);

export const trackingDetailKeywordsWithEstimatedVisitsChange = createSelector(
    filteredAndSortedTrackingKeywordsSelector,
    keywords => filter(keyword => keyword.estimatedVisitsTotalChange !== Defaults.NO_VALUE, keywords),
);

export const trackingDetailKeywordsWithRankChange = createSelector(
    filteredAndSortedTrackingKeywordsSelector,
    keywords => filter(keyword => keyword.rankChange !== 0 && keyword.rankChange !== Defaults.NOT_AVAILABLE, keywords),
);

export const trackingDetailKeywordsWithPerformanceIndexChange = createSelector(
    trackingDetailKeywordsWithRankChange,
    keywords => filter(keyword => keyword.performanceIndexChange !== Defaults.NO_VALUE, keywords),
);

export const trackingDetailKeywordsEstimatedVisitsChangeSelector = createSelector(
    [trackingDetailKeywordsWithEstimatedVisitsChange],
    keywords => {
        const sortedKeywords = sortBy(prop('estimatedVisitsTotalChange'), keywords);
        const start = sortedKeywords.length - NUMBER_OF_RANKERS >= 0 ? sortedKeywords.length - NUMBER_OF_RANKERS : 0;

        const rankers = compose(
            sortWith([
                descend(prop('estimatedVisitsTotalChange')),
                ascend(prop('rankChange')),
                ascend(prop('keyword')),
            ]),
            filter(keyword => keyword.rankChange < 0),
            slice(start, Infinity),
        )(sortedKeywords);

        const outrankers = compose(
            sortWith([
                ascend(prop('estimatedVisitsTotalChange')),
                descend(prop('rankChange')),
                ascend(prop('keyword')),
            ]),
            filter(keyword => keyword.rankChange > 0),
            slice(0, NUMBER_OF_RANKERS),
        )(sortedKeywords);

        return {
            rankers,
            outrankers,
        };
    },
);

/*
    NOTE:

    Refactor rankers and outrankers into separate selectors in both
    trackingDetailKeywordsEstimatedVisitsChangeSelector and
    trackingDetailKeywordsPerformanceIndexChangeSelector
 */
export const trackingDetailKeywordsPerformanceIndexChangeSelector = createSelector(
    [trackingDetailKeywordsWithPerformanceIndexChange],
    keywords => {
        const sortedKeywords = sortBy(prop('performanceIndexChange'), keywords);
        const start = sortedKeywords.length - NUMBER_OF_RANKERS >= 0 ? sortedKeywords.length - NUMBER_OF_RANKERS : 0;

        const rankers = compose(
            sortWith([descend(prop('performanceIndexChange')), ascend(prop('rankChange')), ascend(prop('keyword'))]),
            filter(keyword => keyword.rankChange < 0),
            slice(start, Infinity),
        )(sortedKeywords);

        const outrankers = compose(
            sortWith([ascend(prop('performanceIndexChange')), descend(prop('rankChange')), ascend(prop('keyword'))]),
            filter(keyword => keyword.rankChange > 0),
            slice(0, NUMBER_OF_RANKERS),
        )(sortedKeywords);

        return {
            rankers,
            outrankers,
        };
    },
);

export const filteredAndSortedTrackingKeywordsSelectorIds = createSelector(
    [filteredAndSortedTrackingKeywordsSelector],
    keywords => keywords.map(kw => kw.id),
);

export const allTrackingDetailsKeywordsRankAvailableSelector = createSelector(
    [trackingDetailKeywordsSelector],
    keywords => all(kw => !isNil(kw.rank) && kw.rank !== Defaults.NOT_AVAILABLE)(keywords),
);

export const trackingDetailKeywordRankingFlowStatsSelector = createSelector(
    [filteredAndSortedTrackingKeywordsSelector],
    keywords => {
        let downCount = 0;
        let sameCount = 0;
        let upCount = 0;

        forEach(kw => {
            const change = kw.rankChange;

            if (change < 0) {
                upCount += 1;
            } else if (change > 0) {
                downCount += 1;
            } else if (change === 0) {
                sameCount += 1;
            }
        }, keywords);

        return { downCount, sameCount, upCount };
    },
);

export const trackingFilteredSelectedKeywordsSelector = createSelector(
    [filteredAndSortedTrackingKeywordsSelector, trackingSelectedKeywordIdsSelector],
    (keywords, selectedKwIds) => {
        return keywords.filter(keyword => selectedKwIds.includes(keyword.id));
    },
);

export const trackingFilteredSelectedKeywordIdsSelector = createSelector(
    [trackingFilteredSelectedKeywordsSelector],
    keywords => keywords.map(({ id }) => id),
);

export const trackingFilteredSelectedKeywordCountSelector = createSelector(
    [trackingFilteredSelectedKeywordsSelector],
    keywords => keywords.length,
);

export const selectedTrackingKeywordsSearchVolumeTotalSelector = createSelector(
    [trackingFilteredSelectedKeywordsSelector],
    keywords => {
        const withSearchVolume = keywords.filter(kw => kw.searchVolume > 0);
        return sum(withSearchVolume.map(kw => kw.searchVolume));
    },
);

export const selectedTrackingKeywordsAvgRankSelector = createSelector(
    [trackingFilteredSelectedKeywordsSelector],
    keywords => {
        const withRank = keywords.filter(kw => kw.rank > 0);
        const ranks = withRank.map(kw => kw.rank);

        if (withRank.length > 0) {
            return sum(ranks) / withRank.length;
        } else {
            return Defaults.NOT_AVAILABLE;
        }
    },
);

export const selectedTrackingKeywordStringsSelector = createSelector(
    [trackingFilteredSelectedKeywordsSelector],
    keywords => keywords.map(kw => kw.keyword),
);

export const assignedTrackingDetailKeywordTagsMapSelector = createSelector(
    [trackingDetailKeywordsSelector],
    keywords => {
        const addedWithCount = {};

        forEach(
            kw =>
                forEach(tag => {
                    if (isNil(addedWithCount[tag.id])) {
                        addedWithCount[tag.id] = {
                            ...tag,
                            assignedKeywordCount: 1,
                        };
                    } else {
                        addedWithCount[tag.id].assignedKeywordCount += 1;
                    }
                }, kw.tags),
            keywords,
        );

        return addedWithCount;
    },
);

export const assignedTrackingDetailKeywordTagsWithAssignedCountSortedByAssignedCountSelector = createSelector(
    [assignedTrackingDetailKeywordTagsMapSelector],
    tagsMapWithAssignedCount =>
        sortWith([descend(prop('assignedKeywordCount')), descend(prop('createdAt'))])(
            Object.values(tagsMapWithAssignedCount),
        ),
);

const allTrackingDetailTagsWithAssignedKwCountSelector = createSelector(
    [trackingDetailTagsSelector, trackingDetailKeywordsSelector],
    (tags, keywords) => {
        const tagAssignedCounts = {};

        // Create an object with tagId as key and assignedKeywordCount as value
        tags.reduce((result, item) => {
            result[item.id] = 0; // eslint-disable-line no-param-reassign
            return result;
        }, tagAssignedCounts);

        // Go through all the tags in all the keywords and add 1 for each occurrence
        forEach(kw => forEach(tag => (tagAssignedCounts[tag.id] += 1), kw.tags), keywords);

        // Add value to tag objects
        return tags.map(tag =>
            update(tag, {
                $merge: {
                    assignedKeywordCount: tagAssignedCounts[tag.id],
                },
            }),
        );
    },
);

export const allTrackingDetailTagsWithAssignedKwCountSortedSelector = createSelector(
    [allTrackingDetailTagsWithAssignedKwCountSelector],
    tagsWithAssignedCount => sortWith([descend(prop('createdAt'))])(tagsWithAssignedCount),
);

export const allTrackingDetailTagsWithAssignedKwCountSortedByAssignedCountSelector = createSelector(
    [allTrackingDetailTagsWithAssignedKwCountSelector],
    tagsWithAssignedCount =>
        sortWith([descend(prop('assignedKeywordCount')), descend(prop('createdAt'))])(tagsWithAssignedCount),
);

// KWF Lists
const listsDataSelector = state => state.data.lists.data;
export const listsFetchingKeywordsSelector = state => state.data.lists.fetchingKeywords;
export const fetchingSuggestedKeywordsSelector = state => state.data.lists.fetchingSuggestedKeywords;
export const listsFetchingSelector = state => state.data.lists.fetching;

export const listsSuggestedKeywordsCountSelector = state => state.data.lists?.suggestedKeywordsCount;
export const listsSuggestedKeywordsCountErrorSelector = state => state.data.lists?.suggestedKeywordsCountError;
export const listsSuggestedKeywordsSelector = state => state.data.lists?.suggestedKeywords?.data?.keywords;
export const newTrackingFilterSettingsSelector = state => state.ui.newTracking?.settings;
export const newTrackingHideSelectedFilterSelector = state => state.ui.newTracking?.settings?.hideSelected;
export const newTrackingQuickFilterSettingsSelector = state => state.ui.newTracking?.quickSettings;
export const newTrackingErrorNewTextSelector = state => state.data.trackings.errorNew.text;

export const suggestedKeywordsSelector = createSelector(
    [
        listsSuggestedKeywordsSelector,
        newTrackingFilterSettingsSelector,
        newTrackingQuickFilterSettingsSelector,
        defaultSortingSettingsSelector,
    ],
    (keywords, filterSettings, quickFilterSettings, sortingSettings) => {
        if (keywords) {
            const filtered = KeywordFilterService.filterSuggestions(keywords, filterSettings, quickFilterSettings);

            return KeywordSorterService.sort(filtered, sortingSettings, ['serpPosition', 'searchCount', 'keyword']);
        }

        return keywords;
    },
);

export const visibleSuggestedKeywordsSelector = createSelector(
    [suggestedKeywordsSelector, newTrackingKeywordsSelector, newTrackingHideSelectedFilterSelector],
    (keywords, tagAreaKws, hideSelected) => {
        if (keywords && hideSelected) {
            return keywords.filter(({ keyword }) => !tagAreaKws.includes(normalizedStr(keyword)));
        }

        return keywords;
    },
);

export const allSuggestedKeywordStringsSelector = createSelector([listsSuggestedKeywordsSelector], list =>
    list?.map(({ keyword }) => keyword.trim()),
);
export const filteredSuggestedKeywordStringsSelector = createSelector([visibleSuggestedKeywordsSelector], list =>
    list?.map(({ keyword }) => keyword.trim()),
);

export const sortedListDataSelector = createSelector([listsDataSelector], lists => {
    const transform = compose(filter(list => list.keywordIds.length > 0));

    return transduce(transform, flip(prepend), [], lists);
});

export const newTrackingSelectedListsKeywordIds = createSelector(
    [listsDataSelector, newTrackingSelectedListIdsSelector],
    (lists, selectedListIds) => {
        const selectedLists = lists.filter(list => includes(list.id, selectedListIds));
        const keywordIds = flatten(selectedLists.map(list => list.keywordIds));
        return uniq(keywordIds);
    },
);

export const addKeywordsPanelSelectedListsKeywordIds = createSelector(
    [listsDataSelector, addKeywordsPanelSelectedListIdsSelector],
    (lists, selectedListIds) => {
        const selectedLists = lists.filter(list => includes(list.id, selectedListIds));
        const keywordIds = flatten(selectedLists.map(list => list.keywordIds));
        return uniq(keywordIds);
    },
);

// Locations
export const locationsFetchingSelector = state => state.data.locations.fetching;
export const locationsSelector = state => state.data.locations.data;

// Tracked keyword detail
const trackingKeywordDetailDataTagsSelector = state => state.data.trackingKeywordDetail.data.tags;
export const trackingKeywordDetailFetchingSelector = state => state.data.trackingKeywordDetail.fetching;
export const trackingKeywordDetailDataSelector = state => state.data.trackingKeywordDetail.data;
export const trackingKeywordDetailItemsSelector = state => state.data.trackingKeywordDetail.data.items;

export const trackingKeywordDetailUnassignedTagsWithAssignedKwCountSortedSelector = createSelector(
    [allTrackingDetailTagsWithAssignedKwCountSortedSelector, trackingKeywordDetailDataTagsSelector],
    (tags, currentKeywordTags) => {
        const currentKwTagIds = currentKeywordTags.map(tag => tag.id);
        return tags.filter(tag => !includes(tag.id, currentKwTagIds));
    },
);

// Reports
export const reportsDataSelector = state => state.data.reports.data;
export const reportsFetchingSelector = state => state.data.reports.fetching;

export const currentReportNameSelector = createSelector(
    [reportsPanelCurrentReportIdSelector, reportsDataSelector],
    (currentId, reports) => {
        const report = find(propEq('id', currentId))(reports);

        if (!isNil(report)) {
            return report.name;
        } else {
            return null;
        }
    },
);

// Announcements
const announcementsDataSelector = state => state.data.announcements.data;
const fetchingAnnouncementsDataSelector = state => state.data.announcements.fetching;

export const announcementsMessageDataSelector = createSelector([announcementsDataSelector], announcementsData => {
    // TODO: Remove after debugging
    // return {
    //     activeFrom: 0,
    //     activeTo: 0,
    //     btnText: 'Read more',
    //     icon: 'rocket',
    //     messageText: 'We\'ve launched the competitor analysis in KWFinder',
    //     newTab: false,
    //     url: 'http://mangools.com/blog'
    // };

    if (isNil(announcementsData)) {
        return ANNOUNCEMENT_EMPTY_MESSAGE;
    } else {
        return announcementsData;
    }
});

export const announcementMesageVisibilitySelector = createSelector(
    [
        fetchingAnnouncementsDataSelector,
        announcementsMessageDataSelector,
        limitsFetchedSelector,
        topLevelAnnouncementMessageVisibilitySelector,
        currentRouteSelector,
    ],
    (fetchingAnnouncements, announcementsMessageData, fetchedLimits, announcementsVisible, currentRoute) => {
        if (
            fetchedLimits === true &&
            fetchingAnnouncements === false &&
            announcementsVisible === true &&
            !RouterService.isSame(currentRoute, RoutePaths.REPORT)
        ) {
            // TODO: Remove after debugging
            // return true;

            // There is an announcement which is active
            if (announcementsMessageData.activeTo > Date.now() / 1000) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
);
