import { arrayOf, bool, shape, string } from 'prop-types';

import NumberOrNAType from 'types/commons/NumberOrNAType';

export type TrackingKeywordRankHistory = TrackingKwRankHistoryItem[];

interface TrackingKwRankHistoryItem {
    date: string | null,
    rank: number | null,
    fsSerpRank: number | null,
    isFSRanking: boolean,
    mapPackRank: number | null,
}

export const TrackingKeywordRankHistoryPropType = arrayOf(
    shape({
        date: string.isRequired,
        rank: NumberOrNAType.isRequired,
        fsSerpRank: NumberOrNAType.isRequired,
        mapPackRank: NumberOrNAType.isRequired,
        isFSRanking: bool.isRequired,
    }),
).isRequired