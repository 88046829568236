import queryString from 'query-string';
import ErrorCodes from 'mangools-commons/lib/constants/ErrorCodes';
import MangoolsClients from 'mangools-commons/lib/constants/MangoolsClients';

import config from 'appConfig';

import {
    parse,
    parseCreate,
    parseDetail,
    parseUpdate,
    parseShareTokenUpdate,
    parseTracking,
} from 'sources/parsers/TrackingDataParser';

import DateFormatService from 'services/DateFormatService';

// Constants
const ENDPOINT = '/trackings';
const CREATE_TRACKINGS_ENDPOINT = '/multiple-trackings';

/**
 * Data source class handling communication with API for tracking data.
 */
class TrackingSource {
    static getData({ accessToken, isWithDeleted }) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}${
            isWithDeleted ? `?is_with_deleted=true` : ''
        }`;
        const headers = {
            'X-Access-Token': accessToken,
        };

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers,
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parse(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static getDetail({ accessToken, report, shareToken, isWithDeleted }, { id, from, to }) {
        const fromWithTZ = DateFormatService.formatISOWithCurrentTZ(from);
        const toWithTZ = DateFormatService.formatISOWithCurrentTZ(to);
        let query = {};
        let headers = {};

        if (report === true) {
            query = queryString.stringify({
                from: fromWithTZ,
                to: toWithTZ,
                share_token: shareToken,
                is_with_deleted: isWithDeleted,
            });
        } else {
            query = queryString.stringify({ from: fromWithTZ, to: toWithTZ, is_with_deleted: isWithDeleted });
            headers = {
                'X-Access-Token': accessToken,
            };
        }

        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}/${id}/detail?${query}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers,
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseDetail(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static getDetailStats({ accessToken, report, shareToken }, { id, from, to }) {
        const fromWithTZ = DateFormatService.formatISOWithCurrentTZ(from);
        const toWithTZ = DateFormatService.formatISOWithCurrentTZ(to);
        let query = {};
        let headers = {};

        if (report === true) {
            query = queryString.stringify({ from: fromWithTZ, to: toWithTZ, share_token: shareToken });
        } else {
            query = queryString.stringify({ from: fromWithTZ, to: toWithTZ });
            headers = {
                'X-Access-Token': accessToken,
            };
        }

        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}/${id}?${query}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers,
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseDetail(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static create(accessToken, { domain, locationIds, platformIds, keywords, trackingConfig }) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${CREATE_TRACKINGS_ENDPOINT}`;

        // Generate body data
        const requestBody = {
            domain,
            keywords,
            location_ids: locationIds,
            platform_ids: platformIds,
            tracking_config: trackingConfig,
        };

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken,
                'X-Mangools-Client': MangoolsClients.APP,
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: data.map(parseCreate),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static delete(accessToken, trackingId) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}/${trackingId}`;

        return fetch(requestURL, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken,
            },
        })
            .then(response => {
                if (response.ok) {
                    return {
                        error: false,
                        payload: null,
                    };
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static restore(accessToken, trackingId) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}/${trackingId}/restore`;

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken,
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseTracking(data.tracking),
                        }))
                        .catch(ex => {
                            return {
                                error: true,
                                payload: {
                                    requestURL,
                                    status: ErrorCodes.PARSE_ERROR,
                                    text: ex.toString(),
                                },
                            };
                        });
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static updateDomain(accessToken, { domain, id, trackingConfig }) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}/${id}`;

        const requestBody = {
            domain,
            tracking_config: trackingConfig,
        };

        return fetch(requestURL, {
            method: 'PATCH',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken,
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseUpdate(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static updateShareToken(accessToken, { id }) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}/${id}/share_token`;

        return fetch(requestURL, {
            method: 'PATCH',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken,
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseShareTokenUpdate(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default TrackingSource;
