import React from 'react';
import classnames from 'classnames';

const TrackingTipsModalTab = ({
    handleClick,
    tabImage,
    tabText,
    stepNumber,
    activeStepNumber,
}: TrackingTipsModalTabProps) => {
    const onClick = () => {
        handleClick(stepNumber);
    };

    return (
        <div
            className={classnames(
                'mg-padding-30 uk-flex uk-flex-column uk-flex-bottom menu-switcher uk-width-1-1 uk-width-medium-1-3',
                {
                    'active-menu-switcher': stepNumber === activeStepNumber,
                },
            )}
            onClick={onClick}
        >
            <div className="uk-flex uk-flex-column uk-flex-middle uk-width-1-1">
                <img className="mg-margin-b-15" src={tabImage} alt="set up weekly or monthly reports" width="60" />
                <span className="font-24 mg-margin-t-5 uk-text-bold uk-text-center">{tabText}</span>
            </div>
        </div>
    );
};

interface TrackingTipsModalTabProps {
    tabImage: string;
    tabText: string;
    handleClick(stepNumber: number): void;
    stepNumber: number;
    activeStepNumber: number | null;
}

export default TrackingTipsModalTab;
