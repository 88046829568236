export const SELECT_ALL_KEYWORDS_TOGGLE = 'SELECT_ALL_KEYWORDS_TOGGLE';
export const COPY_KEYWORDS = 'COPY_KEYWORDS';
export const EXPORT_KEYWORDS = 'EXPORT_KEYWORDS';
export const CTRL_CMD_DOWN_UP = 'CTRL_CMD_DOWN_UP';
export const ARROW_DOWN = 'ARROW_DOWN';
export const ARROW_UP = 'ARROW_UP';
export const SPACE = 'SPACE';
export const ENTER = 'ENTER';
export const SHOW_SHORTCUTS_TOGGLE = 'SHOW_SHORTCUTS_TOGGLE';
export const CLOSE_ALL = 'CLOSE_ALL';
export const SELECTED_KEYWORDS_DELETE = 'SELECTED_KEYWORDS_DELETE';
export const TAG_DROPDOWN_TOGGLE = 'TAG_DROPDOWN_TOGGLE';
export const CONFIRM_DELETE_MESSAGE = 'CONFIRM_DELETE_MESSAGE';
export const ADD_KEYWORDS_PANEL_TOGGLE = 'ADD_KEYWORDS_PANEL_TOGGLE';
export const SEARCH = 'SEARCH';

export default {
    [SELECT_ALL_KEYWORDS_TOGGLE]: ['command+a', 'ctrl+a'],
    [COPY_KEYWORDS]: ['command+c', ' ctrl+c'],
    [EXPORT_KEYWORDS]: 'e',
    [ARROW_DOWN]: ['shift+down', 'shift+j', 'down', 'j'],
    [ARROW_UP]: ['shift+up', 'shift+k', 'up', 'k'],
    [SPACE]: 'space',
    [ENTER]: 'enter',
    [ADD_KEYWORDS_PANEL_TOGGLE]: 'n',
    [CLOSE_ALL]: 'esc',
    [SHOW_SHORTCUTS_TOGGLE]: 'shift+?',
    [CTRL_CMD_DOWN_UP]: [
        { sequence: 'ctrl', action: 'keydown' },
        { sequence: 'ctrl', action: 'keyup' },
        { sequence: 'command', action: 'keydown' },
        { sequence: 'command', action: 'keyup' },
    ],
    [SELECTED_KEYWORDS_DELETE]: navigator.platform.includes('Mac') ? 'backspace' : 'del',
    [TAG_DROPDOWN_TOGGLE]: 't',
    [SEARCH]: ['command+f', 'ctrl+f', '/'],
};
