import { createSelector } from 'reselect';
import isNil from 'ramda/src/isNil';
import filter from 'ramda/src/filter';
import find from 'ramda/src/find';
import pipe from 'ramda/src/pipe';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import sortBy from 'ramda/src/sortBy';
import concat from 'ramda/src/concat';
import ColorSchemes from 'mangools-commons/lib/constants/ColorSchemes';

import TrackingDomainService from 'services/TrackingDomainService';
import { newTrackingKeywordsInLimitSelector, newTrackingKeywordsNotInLimitSelector } from 'selectors/sharedSelectors';

import {
    emailSelector,
    reportsDataSelector,
    trackingTimeframeSelector,
    isReportRouteSelector,
} from 'selectors/commonSelectors';

import {
    trackingDetailTagsSelector,
    filteredAndSortedTrackingKeywordsSelector,
    trackingFilteredSelectedKeywordCountSelector,
    listsSuggestedKeywordsSelector,
    suggestedKeywordsSelector,
    visibleSuggestedKeywordsSelector,
} from 'selectors/dataSelectors';
import { remainingTrackedKeywordLimitSelector } from 'selectors/userSelectors';

import ReportTypes, { ALERT } from 'constants/ReportTypes';
import { NewTrackingStep } from 'components/newTracking/step/NewTrackingStep';
import { defaultPlatformSelector, validLocationsSelector } from './defaultsSelectors';

// New tracking
export const newTrackingDomainSelector = state => state.ui.newTracking.domain;
export const newTrackingFetchingSelector = state => state.data.trackings.fetchingNew;

export const newTrackingKeywordsInTrackingSelector = state => state.ui.newTracking.keywordsInTracking;
export const newTrackingCreatedTrackingSelector = state => {
    const { data } = state.ui.newTracking;
    if (data && data.length !== 0) {
        return data[0];
    }
    return null;
};
export const newTrackingCreatedTrackingIdSelector = createSelector([newTrackingCreatedTrackingSelector], tracking =>
    tracking ? tracking.id : null,
);
export const newTrackingKeywordsFilteredCountSelector = state => state.ui.newTracking.filteredCount;
export const newTrackingStepSelector = state => {
    return state.routing.locationBeforeTransitions.query?.step || NewTrackingStep.Domain;
};
export const newTrackingFilterSettingsSelector = state => state.ui.newTracking?.settings;
export const newTrackingQuickFilterSettingsSelector = state => state.ui.newTracking?.quickSettings;

export const isNewTrackingCreatedSelector = createSelector(
    [newTrackingCreatedTrackingIdSelector],
    newTrackingId => !!newTrackingId,
);

export const newTrackingFilterActiveSelector = createSelector(
    [listsSuggestedKeywordsSelector, suggestedKeywordsSelector],
    (unFilteredList, filteredList) => {
        return unFilteredList?.length > filteredList?.length;
    },
);
export const newTrackingKwsHiddenSelector = createSelector(
    [visibleSuggestedKeywordsSelector, newTrackingFilterActiveSelector],
    (visibleKws, isFilterActive) => {
        return visibleKws?.length === 0 && !isFilterActive;
    },
);

export const newTrackingDomainValidSelector = createSelector([newTrackingDomainSelector], domain =>
    TrackingDomainService.validate(domain),
);

export const newTrackingKeywordsValidSelector = createSelector(
    [newTrackingKeywordsInLimitSelector],
    keywords => !isNil(keywords) && keywords.length > 0,
);

export const newTrackingValidSelector = createSelector(
    [newTrackingKeywordsValidSelector, newTrackingDomainValidSelector],
    (keywordsValid, domainValid) => keywordsValid && domainValid,
);

export const newTrackingKeywordCounterDataSelector = createSelector(
    [
        newTrackingKeywordsInLimitSelector,
        newTrackingKeywordsNotInLimitSelector,
        remainingTrackedKeywordLimitSelector,
        validLocationsSelector,
        defaultPlatformSelector,
    ],
    (keywordsInLimit, keywordsNotInLimit, remainingKeywordsCount, locations, platforms) => {
        const locationsCount = locations.length;
        const plaformsCount = platforms.length;
        const entered = keywordsInLimit.length + keywordsNotInLimit.length;
        const spent = entered * locationsCount * plaformsCount;
        return {
            locationsCount,
            plaformsCount,
            spent,
            keywordsEnteredCount: entered,
            keywordsTrackedCount: 0,
            keywordsLeftCount: remainingKeywordsCount - spent,
        };
    },
);

export const newTrackingKeywordsMetaMapSelector = createSelector(
    [newTrackingKeywordsInLimitSelector, newTrackingKeywordsNotInLimitSelector],
    (keywordsInLimit, keywordsNotInLimit) => {
        const metaMap = {};

        keywordsInLimit.reduce((map, keyword) => {
            // eslint-disable-next-line no-param-reassign
            map[keyword] = {
                isTracked: false,
                isOverLimit: false,
            };
            return map;
        }, metaMap);

        keywordsNotInLimit.reduce((map, keyword) => {
            // eslint-disable-next-line no-param-reassign
            map[keyword] = {
                isTracked: false,
                isOverLimit: true,
            };
            return map;
        }, metaMap);

        return metaMap;
    },
);

// Tracking
export const trackingModifyingKeywordsSelector = state => state.ui.tracking.modifyingKeywords;
export const trackingCurrentKeywordDetailSelector = state => state.ui.tracking.currentKeywordDetailId;
export const trackingUnassigningTagIdSelector = state => state.ui.tracking.unassigningTagId;
export const trackingAnnotationDeletingIdSelector = state => state.ui.tracking.annotationDeletingId;
export const trackingAnnotationUpdatingIdSelector = state => state.ui.tracking.annotationUpdatingId;

const uiSettingsSelector = state => state.ui.settings;

export const isWithDeletedTrackingsSelector = createSelector(
    uiSettingsSelector,
    settings => settings.isWithDeletedTrackings,
);
export const isWithDeletedKeywordsSelector = createSelector(
    uiSettingsSelector,
    settings => settings.isWithDeletedKeywords,
);
export const isWithGroupedByDomainSelector = createSelector(uiSettingsSelector, settings => settings.isGroupedByDomain);

export const trackingAllKeywordsSelectedSelector = createSelector(
    [
        filteredAndSortedTrackingKeywordsSelector,
        trackingFilteredSelectedKeywordCountSelector,
        isWithDeletedKeywordsSelector,
    ],
    (keywords, selectedKeywordsCount) => {
        return keywords.length > 0 && keywords.length === selectedKeywordsCount;
    },
);

export const trackingTimeframeDaysSelector = createSelector([trackingTimeframeSelector], timeframe =>
    timeframe.to.diff(timeframe.from, 'days'),
);

// Tracking filter
export const trackingFilterVisibilitySelector = state => state.ui.trackingFilter.visibility;
export const trackingFilterActiveSelector = state => state.ui.trackingFilter.settings.active;
export const trackingFilterSettingsSelector = state => state.ui.trackingFilter.settings;

// Messages
export const messagesSelector = state => state.ui.messages;
export const accessDeniedMessageVisibilitySelector = state => state.ui.messages.accessDenied.visibility;
export const addedKeywordsKeywordsSelector = state => state.ui.messages.addedKeywords.keywords;
export const addedKeywordsPlatformLabelSelector = state => state.ui.messages.addedKeywords.platformLabel;
export const addedKeywordsPostponedProcessingSelector = state => state.ui.messages.addedKeywords.postponedProcessing;
export const addedKeywordsEstimatedTimeProcessingSelector = state =>
    state.ui.messages.addedKeywords.estimatedTimeProcessing;
export const addedKeywordsTemplateTrackingIdSelector = state => state.ui.messages.addedKeywords.templateTrackingId;
export const addedKeywordsVisibilitySelector = state => state.ui.messages.addedKeywords.visibility;
export const createTrackingDomainSelector = state => state.ui.messages.createTracking.domain;
export const createTrackingFetchingSelector = state => state.ui.messages.createTracking.fetching;
export const createTrackingPlatformLabelSelector = state => state.ui.messages.createTracking.platformLabel;
export const createTrackingEstimatedTimeProcessingSelector = state =>
    state.ui.messages.createTracking.estimatedTimeProcessing;
export const createTrackingTemplateIdSelector = state => state.ui.messages.createTracking.templateTrackingId;
export const createTrackingVisibilitySelector = state => state.ui.messages.createTracking.visibility;
export const upsellVisibilitySelector = state => state.ui.messages.upsell.visibility;
export const deleteConfirmationMessageResourceNameSelector = state => state.ui.messages.deleteConfirmation.resourceName;
export const deleteConfirmationMessageResourceDetailsSelector = createSelector([messagesSelector], messages => {
    return messages.deleteConfirmation.details;
});
export const deleteConfirmationMessageResourceTypeSelector = state => state.ui.messages.deleteConfirmation.resourceType;
export const deleteConfirmationMessageVisibilitySelector = state => state.ui.messages.deleteConfirmation.visibility;
export const failureMessageDetailsSelector = state => state.ui.messages.failure.details;
export const failureMessageHeaderSelector = state => state.ui.messages.failure.header;
export const failureMessageVisibilitySelector = state => state.ui.messages.failure.visibility;
export const loggedOutMessageVisibilitySelector = state => state.ui.messages.loggedOut.visibility;
export const needToSelectKeywordMessageVisibilitySelector = state => state.ui.messages.needToSelectKeyword.visibility;
export const needToSignInMessageVisibilitySelector = state => state.ui.messages.needToSignIn.visibility;
export const newTrackingInfoMessagePostponedProcessingSelector = state =>
    state.ui.messages.newTrackingInfo.postponedProcessing; // eslint-disable-line max-len
export const newTrackingInfoMessageEstimatedTimeProcessingSelector = state =>
    state.ui.messages.newTrackingInfo.estimatedTimeProcessing; // eslint-disable-line max-len
export const newTrackingInfoMessageVisibilitySelector = state => state.ui.messages.newTrackingInfo.visibility;
export const noConnectionMessageVisibilitySelector = state => state.ui.messages.noConnection.visibility;
export const trackingAlreadyExistsKeywordsSelector = state => state.ui.messages.trackingAlreadyExists.keywords;
export const trackingAlreadyExistsTrackingIdSelector = state => state.ui.messages.trackingAlreadyExists.trackingId;
export const trackingAlreadyExistsVisibilitySelector = state => state.ui.messages.trackingAlreadyExists.visibility;
export const unauthorizedMessageVisibilitySelector = state => state.ui.messages.unauthorized.visibility;
export const shortcutsMessageVisibilitySelector = state => state.ui.messages.shortcuts.visibility;
export const invalidShareTokenMessageVisibilitySelector = state => state.ui.messages.invalidShareToken.visibility;
export const editTrackingMessageVisibilitySelector = state => state.ui.messages.editTracking.visibility;
export const EditTrackingMessageDataSelector = state => state.ui.messages.editTracking.tracking;
export const importKwfListMessageVisibilitySelector = state => state.ui.messages.importKwfList.visibility;
export const importKwfListMessageImportTargetSelector = state => state.ui.messages.importKwfList.importTarget;

// NOTE: Only show pricing message when need to sign in is not visible
export const pricingMessageVisibilitySelector = state =>
    state.ui.messages.pricing.visibility && !needToSignInMessageVisibilitySelector(state);

const anyMessageVisibilitySelector = createSelector(
    [
        accessDeniedMessageVisibilitySelector,
        addedKeywordsVisibilitySelector,
        createTrackingVisibilitySelector,
        deleteConfirmationMessageVisibilitySelector,
        failureMessageVisibilitySelector,
        loggedOutMessageVisibilitySelector,
        needToSelectKeywordMessageVisibilitySelector,
        needToSignInMessageVisibilitySelector,
        newTrackingInfoMessageVisibilitySelector,
        noConnectionMessageVisibilitySelector,
        pricingMessageVisibilitySelector,
        shortcutsMessageVisibilitySelector,
        trackingAlreadyExistsVisibilitySelector,
        unauthorizedMessageVisibilitySelector,
        invalidShareTokenMessageVisibilitySelector,
        editTrackingMessageVisibilitySelector,
        upsellVisibilitySelector,
        importKwfListMessageVisibilitySelector,
    ],
    (
        accessDeniedMessageVisible,
        addedKeywordsVisible,
        createTrackingVisible,
        deleteConfirmationMessageVisible,
        failureMessageVisible,
        loggedOutMessageVisible,
        needToSelectKeywordMessageVisible,
        needToSignInMessageVisible,
        newTrackingInfoMessageVisible,
        noConnectionMessageVisible,
        pricingMessageVisible,
        shortcutsMessageVisible,
        trackingAlreadyExistsVisible,
        unauthorizedMessageVisible,
        invalidShareTokenMessageVisible,
        editTrackingMessageVisible,
        upsellVisible,
        importKwfListMessageVisible,
    ) =>
        accessDeniedMessageVisible ||
        addedKeywordsVisible ||
        createTrackingVisible ||
        deleteConfirmationMessageVisible ||
        failureMessageVisible ||
        loggedOutMessageVisible ||
        needToSelectKeywordMessageVisible ||
        needToSignInMessageVisible ||
        newTrackingInfoMessageVisible ||
        noConnectionMessageVisible ||
        pricingMessageVisible ||
        shortcutsMessageVisible ||
        trackingAlreadyExistsVisible ||
        unauthorizedMessageVisible ||
        invalidShareTokenMessageVisible ||
        editTrackingMessageVisible ||
        upsellVisible ||
        importKwfListMessageVisible,
);

// Dropdowns
export const exportDropdownVisibilitySelector = state => state.ui.dropdowns.export.visibility;
export const helpDropdownVisibilitySelector = state => state.ui.dropdowns.help.visibility;
export const importListDropdownVisibilitySelector = state => state.ui.dropdowns.importList.visibility;
export const manageTagsColorDropdownVisibleIdSelector = state => state.ui.dropdowns.manageTagsColor.visibleId;
export const tagsAddKwDropdownVisibilitySelector = state => state.ui.dropdowns.tagsAddKw.visibility;
export const tagsBulkDropdownVisibilitySelector = state => state.ui.dropdowns.tagsBulk.visibility;
export const tagsDropdownVisibilitySelector = state => state.ui.dropdowns.tags.visibility;
export const timeframeDropdownVisibilitySelector = state => state.ui.dropdowns.timeframe.visibility;
export const trackingActionsDropdownVisibilitySelector = state => state.ui.dropdowns.trackingActions.visibility;
export const trackingItemActionsDropdownVisibleIdSelector = state => state.ui.dropdowns.trackingItemActions.visibleId;
export const trackingListDropdownVisibilitySelector = state => state.ui.dropdowns.trackingList.visibility;
export const locationsListDropdownVisibilitySelector = state => state.ui.dropdowns.locationsList.visibility;
export const tagsFilterDropdownVisibilitySelector = state => state.ui.dropdowns.tagsFilter.visibility;
export const trackingsActionsDropdownVisibleIdSelector = state => state.ui.dropdowns.trackingsActions.visible;

// Returns true if any of the dropdowns is visible
export const anyDropdownVisibleSelector = createSelector(
    [
        exportDropdownVisibilitySelector,
        helpDropdownVisibilitySelector,
        importListDropdownVisibilitySelector,
        manageTagsColorDropdownVisibleIdSelector,
        tagsAddKwDropdownVisibilitySelector,
        tagsBulkDropdownVisibilitySelector,
        tagsDropdownVisibilitySelector,
        timeframeDropdownVisibilitySelector,
        trackingListDropdownVisibilitySelector,
        locationsListDropdownVisibilitySelector,
        trackingActionsDropdownVisibilitySelector,
        trackingItemActionsDropdownVisibleIdSelector,
        tagsFilterDropdownVisibilitySelector,
        trackingsActionsDropdownVisibleIdSelector,
    ],
    (
        exportDropdownVisible,
        helpDropdownVisible,
        importListDropdownVisible,
        manageTagsColorDropdownVisibleId,
        tagsAddKwDropdownVisibility,
        tagsBulkDropdownVisible,
        tagsDropdownVisible,
        timeframeDropdownVisible,
        trackingActionsDropdownVisible,
        trackingListDropdownVisible,
        locationsListDropdownVisibile,
        trackingItemActionsVisibleId,
        tagsFilterDropdownVisible,
        trackingsActionsDropdownVisible,
    ) =>
        exportDropdownVisible ||
        helpDropdownVisible ||
        importListDropdownVisible ||
        tagsAddKwDropdownVisibility ||
        tagsDropdownVisible ||
        !isNil(manageTagsColorDropdownVisibleId) ||
        tagsBulkDropdownVisible ||
        timeframeDropdownVisible ||
        trackingListDropdownVisible ||
        trackingActionsDropdownVisible ||
        !isNil(trackingItemActionsVisibleId) ||
        locationsListDropdownVisibile ||
        tagsFilterDropdownVisible ||
        trackingsActionsDropdownVisible,
);

export const tagsAddKwDropdownSelectedIdsSelector = state => state.ui.dropdowns.tagsAddKw.selectedIds;
export const tagsDropdownSelectedIdsSelector = state => state.ui.dropdowns.tags.selectedIds;
export const tagsBulkDropdownSelectedIdsSelector = state => state.ui.dropdowns.tagsBulk.selectedIds;

// Modals
export const serpModalVisibilitySelector = state => state.ui.modals.serpModal.visibility;
export const shareModalVisibilitySelector = state => state.ui.modals.shareModal.visibility;
export const createdTrackingVisibleTipModalSelector = state => state.ui.modals.createdTrackingTips.visibleTip;

// Panels
export const appPanelVisibilitySelector = state => state.ui.panels.app.visibility;
export const customizeReporVisibilitySelector = state => state.ui.panels.customizeReportPanel.visibility;
export const customizeReporFetchingSelector = state => state.ui.panels.customizeReportPanel.fetching;
export const customizeReportIsDirtySelector = state => state.ui.panels.customizeReportPanel.isDirty;
export const customizeResultsPanelVisibilitySelector = state => state.ui.panels.customizeResultsPanel.visibility;

// Add keywords panel
export const addKeywordsPanelFetchingSelector = state => state.ui.panels.addKeywords.fetching;
export const addKeywordsPanelFilteredCountSelector = state => state.ui.panels.addKeywords.filteredCount;
export const addKeywordsPanelKeywordsInLimitSelector = state => state.ui.panels.addKeywords.keywordsInLimit;
export const addKeywordsPanelKeywordsNotInLimitSelector = state => state.ui.panels.addKeywords.keywordsNotInLimit;
export const addKeywordsPanelKeywordsInTrackingSelector = state => state.ui.panels.addKeywords.keywordsInTracking;
export const addKeywordsPanelVisibilitySelector = state => state.ui.panels.addKeywords.visibility;
export const addKeywordsPanelAssignedTagIdsSelector = state => state.ui.panels.addKeywords.tagIds;

export const addKeywordsPanelAssignedTagsSelector = createSelector(
    [addKeywordsPanelAssignedTagIdsSelector, trackingDetailTagsSelector],
    (tagIds, tags) =>
        pipe(
            filter(tag => tagIds.includes(tag.id)),
            sortBy(prop('createdAt')),
        )(tags),
);

export const addKeywordsPanelKeywordsSelector = createSelector(
    [
        addKeywordsPanelKeywordsInLimitSelector,
        addKeywordsPanelKeywordsNotInLimitSelector,
        addKeywordsPanelKeywordsInTrackingSelector,
    ],
    (keywordsInLimit, keywordsNotInLimit, keywordsInTracking) =>
        concat(concat(keywordsInLimit, keywordsInTracking), keywordsNotInLimit),
);

export const addKeywordsPanelValidSelector = createSelector(
    [addKeywordsPanelKeywordsSelector],
    keywords => !isNil(keywords) && keywords.length > 0,
);

export const addKeywordsPanelKeywordCounterDataSelector = createSelector(
    [
        addKeywordsPanelKeywordsInLimitSelector,
        addKeywordsPanelKeywordsNotInLimitSelector,
        addKeywordsPanelKeywordsInTrackingSelector,
        remainingTrackedKeywordLimitSelector,
    ],
    (keywordsInLimit, keywordsNotInLimit, keywordsInTracking, remainingKeywordsCount) => {
        const notTrackedKeywords = keywordsInLimit.length + keywordsNotInLimit.length;
        return {
            keywordsEnteredCount: notTrackedKeywords + keywordsInTracking.length,
            keywordsTrackedCount: keywordsInTracking.length,
            keywordsLeftCount: remainingKeywordsCount - keywordsInLimit.length - keywordsNotInLimit.length,
        };
    },
);

export const addKeywordsPanelKeywordsMetaMapSelector = createSelector(
    [
        addKeywordsPanelKeywordsInLimitSelector,
        addKeywordsPanelKeywordsNotInLimitSelector,
        addKeywordsPanelKeywordsInTrackingSelector,
    ],
    (keywordsInLimit, keywordsNotInLimit, keywordsInTracking) => {
        const metaMap = {};

        keywordsInLimit.reduce((map, keyword) => {
            // eslint-disable-next-line no-param-reassign
            map[keyword] = {
                isTracked: false,
                isOverLimit: false,
            };
            return map;
        }, metaMap);

        keywordsNotInLimit.reduce((map, keyword) => {
            // eslint-disable-next-line no-param-reassign
            map[keyword] = {
                isTracked: false,
                isOverLimit: true,
            };
            return map;
        }, metaMap);

        keywordsInTracking.reduce((map, keyword) => {
            // eslint-disable-next-line no-param-reassign
            map[keyword] = {
                isTracked: true,
                isOverLimit: false,
            };
            return map;
        }, metaMap);

        return metaMap;
    },
);

// Reports panel
export const reportsPanelVisibilitySelector = state => state.ui.panels.reports.visibility;

// Manage tags panel
export const manageTagsPanelVisibilitySelector = state => state.ui.panels.manageTags.visibility;

// New
const reportsPanelNewRawEmailsSelector = state => state.ui.panels.reports.new.emails;
export const reportsPanelNewAlertTriggersSelector = state => state.ui.panels.reports.new.alertTriggers;
export const reportsPanelNewNameSelector = state => state.ui.panels.reports.new.name;
export const reportsPanelNewTypeSelector = state => state.ui.panels.reports.new.type;

export const reportsPanelNewEmailsSelector = createSelector(
    [reportsPanelNewRawEmailsSelector, emailSelector],
    (emails, userEmail) => {
        if (isNil(emails)) {
            if (!isNil(userEmail)) {
                return [userEmail];
            } else {
                return ['me@example.com'];
            }
        } else {
            return emails;
        }
    },
);

export const reportsPanelNewGeneratedNameSelector = createSelector(
    [reportsPanelNewTypeSelector, reportsDataSelector],
    (type, reports) => {
        const reportTypeObject = find(propEq('id', type))(ReportTypes);
        let name = null;

        if (type === ALERT.id) {
            name = ALERT.label;
        } else {
            name = reportTypeObject.label;
        }

        const existingReports = reports.filter(report => report.name.toLowerCase().indexOf(name.toLowerCase()) > -1);
        const existingCount = existingReports.length;

        if (existingCount > 0) {
            return `${name} #${existingCount}`;
        } else {
            return name;
        }
    },
);

// Edit
export const reportsPanelEditAlertTriggersSelector = state => state.ui.panels.reports.edit.alertTriggers;
export const reportsPanelEditEmailsSelector = state => state.ui.panels.reports.edit.emails;
export const reportsPanelEditNameSelector = state => state.ui.panels.reports.edit.name;
export const reportsPanelEditTypeSelector = state => state.ui.panels.reports.edit.type;

// Misc
export const colorSchemeSelector = state => state.ui.misc.colorScheme;
export const currentColorSchemeSelector = createSelector(
    [colorSchemeSelector, isReportRouteSelector],
    (currentColorScheme, isReportRoute) => {
        if (isReportRoute) {
            return ColorSchemes.LIGHT;
        } else {
            return currentColorScheme;
        }
    },
);

export const navigatedInternallySelector = state => state.ui.misc.navigatedInternally;
export const newVersionNotificationShownSelector = state => state.ui.misc.newVersionNotificationShown;
export const onlineStatusSelector = state => state.ui.misc.onlineStatus;
export const scrollResultListToKwIdSelector = state => state.ui.misc.scrollResultListToKwId;
export const serpSnapshotFetchingSelector = state => state.ui.misc.serpSnapshot.fetching;

const anyPanelVisibilitySelector = createSelector(
    [
        addKeywordsPanelVisibilitySelector,
        appPanelVisibilitySelector,
        manageTagsPanelVisibilitySelector,
        reportsPanelVisibilitySelector,
        customizeReporVisibilitySelector,
    ],
    (
        addKeywordsPanelVisible,
        appPanelVisible,
        manageTagsPanelVisible,
        reportsPanelVisible,
        customizeReportPanelVisible,
    ) =>
        addKeywordsPanelVisible ||
        appPanelVisible ||
        manageTagsPanelVisible ||
        reportsPanelVisible ||
        customizeReportPanelVisible,
);

export const overlayVisibilitySelector = createSelector(
    [
        failureMessageVisibilitySelector,
        appPanelVisibilitySelector,
        addKeywordsPanelVisibilitySelector,
        manageTagsPanelVisibilitySelector,
        reportsPanelVisibilitySelector,
        tagsFilterDropdownVisibilitySelector,
        customizeReporVisibilitySelector,
    ],
    (
        failureMessageVisibility,
        appPanelVisibility,
        addKeywordsPanelVisibility,
        manageTagsPanelVisibility,
        reportsPanelVisibility,
        tagsFilterDropdownVisibility,
        customizeReportPanelVisible,
    ) =>
        !failureMessageVisibility &&
        (appPanelVisibility ||
            addKeywordsPanelVisibility ||
            manageTagsPanelVisibility ||
            reportsPanelVisibility ||
            tagsFilterDropdownVisibility ||
            customizeReportPanelVisible),
);

export const mobileOverlayVisibilitySelector = createSelector(
    [anyDropdownVisibleSelector],
    anyDropdownVisible => anyDropdownVisible,
);

export const areShortcutsDisabledSelector = createSelector(
    [anyDropdownVisibleSelector, anyMessageVisibilitySelector, anyPanelVisibilitySelector],
    (anyDropdownVisible, anyMessageVisibile, anyPanelVisible) =>
        anyDropdownVisible || anyMessageVisibile || anyPanelVisible,
);
