export const TOP_10_ENTER = {
    description: 'Keyword enters TOP 10',
    id: 0,
    label: 'Top 10 enter',
    name: 'TOP_10_ENTER',
};

export const TOP_100_ENTER = {
    description: 'Keyword enters TOP 100',
    id: 1,
    label: 'Top 100 enter',
    name: 'TOP_100_ENTER',
};

export const TOP_10_LEAVE = {
    description: 'Keyword leaves TOP 10',
    id: 2,
    label: 'Top 10 leave',
    name: 'TOP_10_LEAVE',
};

export const TOP_100_LEAVE = {
    description: 'Keyword leaves TOP 100',
    id: 3,
    label: 'Top 100 leave',
    name: 'TOP_100_LEAVE',
};

const AlertTriggers = [TOP_10_ENTER, TOP_100_ENTER, TOP_10_LEAVE, TOP_100_LEAVE];

export default AlertTriggers;
