export const DEFAULT = {
    id: 0,
    label: 'Default',
    value: 'DEFAULT',
};

export const ALL_FEAT_SNIPPET = {
    id: 1,
    label: `All`,
    value: 'ALL_FEAT_SNIPPET',
};

export const IS_RANKING = {
    id: 2,
    label: `Ranking`,
    value: 'IS_RANKING',
};

export const NOT_RANKING = {
    id: 3,
    label: `Not ranking`,
    value: 'NOT_RANKING',
};

export const FEAT_SNIPPET_RANKING_OPTIONS = [DEFAULT, ALL_FEAT_SNIPPET, IS_RANKING, NOT_RANKING];

export default {
    DEFAULT: DEFAULT.value,
    ALL_FEAT_SNIPPET: ALL_FEAT_SNIPPET.value,
    IS_RANKING: IS_RANKING.value,
    NOT_RANKING: NOT_RANKING.value,
};