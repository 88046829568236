import { all, any, includes, isEmpty, isNil, toLower, compose, trim } from 'ramda';

import { OR } from 'constants/LogicOperators';
import Defaults from 'mangools-commons/lib/constants/Defaults';
import MAP_PACK_RANKING_TYPES from 'constants/MapPackTypes';
import FEAT_SNIPPET_RANKING_TYPES from 'constants/FeaturedSnippetTypes';
import RANKING_URLS from 'constants/RankingUrls';

/**
 * Filter service for tracking keywords data
 */
class KeywordFilterService {
    /**
     * Main filter function.
     *
     * @param {data: Arrays} Array of data.
     * @param {settings: Object} Settings object.
     * @return {Array} Filtered data.
     */
    static filter(data, settings, quickSettings) {
        const filteredData = this.applyFilterSettings(data, settings);

        const filteredTags = this.filterByTags(filteredData, quickSettings.tagIds);

        const filteredSearch = this.filterBySearch(filteredTags, quickSettings.search);

        return filteredSearch;
    }

    static filterSuggestions(data, settings, quickSettings) {
        const filteredData = this.applyFilterSettings(data, settings);

        const filteredFrom = this.filterFromSuggestionValues(filteredData, settings);
        // Filtering to values
        const filteredTo = this.filterToSuggestionValues(filteredFrom, settings);
        // Filtering included/excluded

        const filteredSearch = this.filterBySearch(filteredTo, quickSettings.search);

        return filteredSearch;
    }

    static filterFromValues(data, settings) {
        let fromFiltered = data;

        // Handle rank value
        fromFiltered = fromFiltered.filter(item => {
            if (settings.rankFrom === 0) {
                return true; // Pass all (also no rank)
            } else {
                return item.rank >= settings.rankFrom;
            }
        });

        // Handle searchVolume value
        fromFiltered = fromFiltered.filter(item => {
            if (settings.searchVolumeFrom === 0) {
                return true; // Pass all (also no searchVolume)
            } else {
                return item.searchVolume >= settings.searchVolumeFrom;
            }
        });

        // Handle estimatedVisits value
        fromFiltered = fromFiltered.filter(item => {
            if (settings.estimatedVisitsFrom === 0) {
                return true; // Pass all (also no estimatedVisitsFrom)
            } else {
                return item.estimatedVisits >= settings.estimatedVisitsFrom;
            }
        });

        return fromFiltered;
    }

    // Filtering min values
    static filterFromSuggestionValues(data, settings) {
        let fromFiltered = data;
        const { serpPositionFrom, searchCountFrom } = settings;

        fromFiltered = fromFiltered.filter(item => {
            if (settings.rankFrom === 0) {
                return true;
            } else {
                return item.serpPosition >= serpPositionFrom;
            }
        });

        // Handle searchCount value
        fromFiltered = fromFiltered.filter(item => item.searchCount >= searchCountFrom);

        return fromFiltered;
    }

    // Filtering max values
    static filterToValues(data, settings) {
        let filteredTo = data;
        const { rankTo, searchVolumeTo, estimatedVisitsTo } = settings;

        if (!isNil(rankTo)) {
            filteredTo = filteredTo.filter(item => item.rank <= rankTo && item.rank !== Defaults.NO_RANK);
        }

        if (!isNil(searchVolumeTo)) {
            filteredTo = filteredTo.filter(item => item.searchVolume <= searchVolumeTo);
        }

        if (!isNil(estimatedVisitsTo)) {
            filteredTo = filteredTo.filter(item => item.estimatedVisits <= estimatedVisitsTo);
        }

        return filteredTo;
    }

    // Filtering max values
    static filterToSuggestionValues(data, settings) {
        if (!data) {
            return data;
        }

        let filteredTo = data;
        const { serpPositionTo, searchCountTo } = settings;

        if (!isNil(serpPositionTo)) {
            filteredTo = filteredTo.filter(item => item.serpPosition <= serpPositionTo);
        }

        if (!isNil(searchCountTo)) {
            filteredTo = filteredTo.filter(item => item.searchCount <= searchCountTo);
        }

        return filteredTo;
    }

    static applyFilterSettings(data, settings) {
        if (settings.active === true && data.length > 0) {
            // Filtering URL
            const filteredUrl = this.filterUrl(data, settings.urlFilter);

            const filteredMapPacks = this.filterMapPackRankings(filteredUrl, settings.mapPackRankFilter);
            const filteredFeatSnippets = this.filterFeatSnippetsRankings(
                filteredMapPacks,
                settings.featSnippetRankFilter,
            );
            const filteredMoreUrls = this.filterRankingUrlsFilter(filteredFeatSnippets, settings.rankingUrlsFilter);

            // Filtering min values
            const filteredFrom = this.filterFromValues(filteredMoreUrls, settings);
            // Filtering to values
            const filteredTo = this.filterToValues(filteredFrom, settings);
            // Filtering included/excluded
            const filteredInEx = this.filterInEx(filteredTo, settings);

            return filteredInEx;
        } else {
            return data;
        }
    }

    static filterBySearch(data, searchValue) {
        if (searchValue.length) {
            const formattedSearchValue = compose(toLower, trim)(searchValue);
            return data.filter(({ keyword }) => keyword.includes(formattedSearchValue));
        } else {
            return data;
        }
    }

    // Filtering by included tag ids
    static filterByTags(data, tagIds) {
        if (tagIds.length > 0) {
            return data.filter(kw => any(tag => includes(tag.id, tagIds))(kw.tags));
        } else {
            return data;
        }
    }

    // Filtering included/excluded keywords
    static filterInEx(data, settings) {
        let filteredInEx = data;

        const included = settings.includedKW;
        const excluded = settings.excludedKW;
        const { includedOperator, excludedOperator } = settings;

        if (included.length > 0) {
            filteredInEx = filteredInEx.filter(item =>
                this.includes({
                    keyword: item.keyword,
                    list: included,
                    operator: includedOperator,
                }),
            );
        }

        if (excluded.length > 0) {
            filteredInEx = filteredInEx.filter(
                item =>
                    !this.includes({
                        keyword: item.keyword,
                        list: excluded,
                        operator: excludedOperator,
                    }),
            );
        }

        return filteredInEx;
    }

    static filterMapPackRankings(data, mapPackRankFilter) {
        switch (mapPackRankFilter) {
            case MAP_PACK_RANKING_TYPES.ALL_MAPPACKS: {
                return data.filter(({ mapPack }) => mapPack.rank > 0 || mapPack.rank === -1);
            }
            case MAP_PACK_RANKING_TYPES.IS_RANKING: {
                return data.filter(({ mapPack }) => mapPack.rank > 0);
            }
            case MAP_PACK_RANKING_TYPES.NOT_RANKING: {
                return data.filter(({ mapPack }) => mapPack.rank === -1);
            }

            default: {
                return data;
            }
        }
    }

    static filterFeatSnippetsRankings(data, featSnippetRankFilter) {
        switch (featSnippetRankFilter) {
            case FEAT_SNIPPET_RANKING_TYPES.ALL_FEAT_SNIPPET: {
                return data.filter(({ featuredSnippet }) => featuredSnippet.serpRank !== null);
            }
            case FEAT_SNIPPET_RANKING_TYPES.IS_RANKING: {
                return data.filter(({ featuredSnippet }) => featuredSnippet.isRanking);
            }
            case FEAT_SNIPPET_RANKING_TYPES.NOT_RANKING: {
                return data.filter(
                    ({ featuredSnippet }) => featuredSnippet.serpRank !== null && !featuredSnippet.isRanking,
                );
            }

            default: {
                return data;
            }
        }
    }

    static filterRankingUrlsFilter(data, rankingUrlsFilter) {
        switch (rankingUrlsFilter) {
            case RANKING_URLS.ONE_URL_ONLY: {
                return data.filter(({ isMoreUrl }) => isMoreUrl === false);
            }
            case RANKING_URLS.MULTIPLE_URLS_ONLY: {
                return data.filter(({ isMoreUrl }) => isMoreUrl);
            }

            default: {
                return data;
            }
        }
    }

    static filterUrl(data, urlFilter) {
        if (!isEmpty(urlFilter)) {
            return data.filter(item => {
                const { url } = item;

                if (!isEmpty(url)) {
                    return includes(toLower(urlFilter), toLower(url));
                } else {
                    return false;
                }
            });
        } else {
            return data;
        }
    }

    // Returns true if keyword is present in list of included keywords
    static includes({ keyword, list, operator }) {
        if (operator.label === OR.label) {
            return any(item => keyword.indexOf(toLower(item)) !== -1)(list);
        } else {
            return all(item => keyword.indexOf(toLower(item)) !== -1)(list);
        }
    }

    // Returns number of words in keyword
    static wordCount(keyword) {
        return keyword.split(/\s+/).length;
    }
}

export default KeywordFilterService;
