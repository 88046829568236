export const DEFAULT = {
    id: 0,
    label: 'Default',
    value: 'DEFAULT',
};

export const ALL_MAPPACKS = {
    id: 1,
    label: `All`,
    value: 'ALL_MAPPACKS',
};

export const IS_RANKING = {
    id: 2,
    label: `Ranking`,
    value: 'IS_RANKING',
};

export const NOT_RANKING = {
    id: 3,
    label: `Not ranking`,
    value: 'NOT_RANKING',
};

export const MAP_PACK_RANKING_OPTIONS = [DEFAULT, ALL_MAPPACKS, IS_RANKING, NOT_RANKING];

export default {
    DEFAULT: DEFAULT.value,
    ALL_MAPPACKS: ALL_MAPPACKS.value,
    IS_RANKING: IS_RANKING.value,
    NOT_RANKING: NOT_RANKING.value,
};