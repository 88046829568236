import React from 'react';
import { DropdownList } from 'react-widgets';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Protocols, { HTTP, HTTPS } from 'constants/Protocols';

function ProtocolDropdownItem(props) {
    const protocol = props.item;

    return (
        <span className="uk-flex">
            <span className="mg-margin-r-5 uk-flex-item-none">
                <FontAwesomeIcon
                    icon={protocol.iconName}
                    className={classnames('font-10', {
                        'color-green': protocol.id === HTTPS.id,
                        'color-grey': protocol.id === HTTP.id,
                    })}
                />
            </span>
            <span className="uk-flex-item-auto">{protocol.label}</span>
        </span>
    );
}

function ProtocolDropdownValue(props) {
    const protocol = props.item;

    return (
        <span className="uk-flex">
            <span className="mg-margin-r-5 uk-flex-item-none">
                <FontAwesomeIcon
                    icon={protocol.iconName}
                    className={classnames('font-10', {
                        'color-green': protocol.id === HTTPS.id,
                        'color-grey': protocol.id === HTTP.id,
                    })}
                />
            </span>
            <span className="uk-flex-item-auto">{protocol.label}</span>
        </span>
    );
}

interface ProtocolDropDownProps {
    onChange: (data: typeof HTTP | typeof HTTPS) => void;
    value: typeof HTTP | typeof HTTPS;
}

export const ProtocolDropdown: React.FC<ProtocolDropDownProps> = (props: ProtocolDropDownProps) => {
    return (
        <DropdownList
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className="uk-position-absolute is-small is-transparent is-protocol font-12"
            data={Protocols}
            dropUp
            duration={50}
            filter={false}
            itemComponent={ProtocolDropdownItem}
            onChange={props.onChange}
            textField="label"
            value={props.value}
            valueComponent={ProtocolDropdownValue}
            valueField="value"
            tabIndex="-1"
        />
    );
};
