import update from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/constants';
import ColorSchemes from 'mangools-commons/lib/constants/ColorSchemes';
import hasPath from 'ramda/src/hasPath';

import ActionTypes from 'constants/ActionTypes';

const initialState = {
    colorScheme: ColorSchemes.LIGHT,
    navigatedInternally: false,
    newVersionNotificationShown: false,
    onlineStatus: true,
    scrollResultListToKwId: null,
    serpSnapshot: {
        fetching: false,
        error: {
            status: null,
            text: null,
        },
    },
};

const miscReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET: {
            return update(state, {
                newVersionNotificationShown: {
                    $set: true,
                },
            });
        }
        case ActionTypes.UI_MISC_NAVIGATED_INTERNALLY_SET: {
            return update(state, {
                navigatedInternally: {
                    $set: true,
                },
            });
        }
        case ActionTypes.UI_MISC_ONLINE_STATUS_SET: {
            return update(state, {
                onlineStatus: {
                    $set: action.payload.onlineStatus,
                },
            });
        }
        case ActionTypes.UI_MISC_SCROLL_RESULT_LIST_KW_ID_SET: {
            return update(state, {
                scrollResultListToKwId: { $set: action.payload },
            });
        }
        case ActionTypes.UI_MISC_COLOR_SCHEME_SET: {
            return update(state, {
                colorScheme: { $set: action.payload },
            });
        }
        case ActionTypes.DATA_SNAPSHOT_IMAGE_REQUESTED: {
            return update(state, {
                serpSnapshot: {
                    fetching: { $set: true },
                },
            });
        }
        case ActionTypes.DATA_SNAPSHOT_IMAGE_FINISHED: {
            return update(state, {
                serpSnapshot: {
                    fetching: { $set: false },
                },
            });
        }
        case ActionTypes.DATA_SNAPSHOT_IMAGE_ERROR: {
            return update(state, {
                serpSnapshot: {
                    fetching: { $set: false },
                    error: {
                        status: { $set: action.payload.status },
                        text: { $set: action.payload.text },
                    },
                },
            });
        }
        case REHYDRATE: {
            if (hasPath(['payload', 'ui', 'misc', 'colorScheme'], action)) {
                return update(state, {
                    colorScheme: { $set: action.payload.ui.misc.colorScheme },
                });
            } else {
                return state;
            }
        }
        default: {
            return state;
        }
    }
};

export default miscReducer;
