import { arrayOf, number, shape, string } from 'prop-types';

import KeywordTagType from 'types/KeywordTagType';
import NumberOrNVType from 'types/commons/NumberOrNVType';
import NumberOrNAType from 'types/commons/NumberOrNAType';
import StringOrNAType from 'types/commons/StringOrNAType';
import { TrackingKeywordRankHistoryPropType } from 'types/TrackingKeywordRankHistory';
import { MapPackPropType } from './MapPack';
import { FeaturedSnippetPropType } from './FeaturedSnippet';

export default shape({
    createdAt: number.isRequired,
    estimatedVisits: NumberOrNAType.isRequired,
    id: string.isRequired,
    keyword: string.isRequired,
    lastCheckedAt: number.isRequired,
    rank: NumberOrNAType.isRequired,
    rankAvg: NumberOrNAType.isRequired,
    rankBest: NumberOrNAType.isRequired,
    rankChange: NumberOrNAType.isRequired,
    rankHistory: TrackingKeywordRankHistoryPropType,
    featuredSnippet: FeaturedSnippetPropType,
    searchVolume: NumberOrNVType.isRequired,
    serpUrl: StringOrNAType.isRequired,
    tags: arrayOf(KeywordTagType).isRequired,
    url: StringOrNAType.isRequired,
    serpSnapshotId: string,
    mapPack: MapPackPropType,
});
