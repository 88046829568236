import { number, shape, string } from 'prop-types';

export default shape({
    code: string,
    id: number.isRequired,
    label: string.isRequired,
    type: string,
});

export interface LocationType {
    code: string | null;
    id: number;
    label: string;
    type: string;
}