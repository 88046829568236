import reverse from 'ramda/src/reverse';
import sort from 'ramda/src/sort';
import isNil from 'ramda/src/isNil';

import Defaults from 'mangools-commons/lib/constants/Defaults';
import SortingTypes from 'constants/SortingTypes';
import SortingColumns from 'constants/SortingColumns';

/**
 * Sorting service for keywords data.
 */
class KeywordSorterService {
    /**
     * Main sort function.
     *
     * @param {data: Array} Array of data.
     * @param {settings: Object} Sorting column and type.
     * @return {Array} Sorted data.
     */
    static sort(data, settings, attributesList) {
        const attribute = settings.column;
        const reversed = settings.type === SortingTypes.DESC;
        const isAttributeAllowed = attributesList ? attributesList.includes(attribute) : true;

        if (data?.length === 0 || isNil(attribute) || !isAttributeAllowed) {
            return data;
        } else {
            let result = data;

            switch (attribute) {
                case SortingColumns.RANK_CHANGE: {
                    result = this.sortByRankChange(data);
                    break;
                }
                default: {
                    result = this.sortWithMissingValuesHandling(attribute, data);
                }
            }

            if (reversed === true) {
                return reverse(result);
            } else {
                return result;
            }
        }
    }

    static sortWithMissingValuesHandling(attribute, data) {
        return sort((a, b) => {
            const attributeA = a[attribute];
            const attributeB = b[attribute];

            if (attributeB === Defaults.NOT_AVAILABLE || attributeA < attributeB) {
                return -1;
            } else if (attributeA === Defaults.NOT_AVAILABLE || attributeB < attributeA) {
                return 1;
            } else {
                return 0;
            }
        }, data);
    }

    static sortByRankChange(data) {
        return sort((a, b) => {
            const rankChangeA = a.rankChange;
            const rankChangeB = b.rankChange;

            if (rankChangeB === Defaults.NOT_AVAILABLE || rankChangeA > rankChangeB) {
                return -1;
            } else if (rankChangeA === Defaults.NOT_AVAILABLE || rankChangeB > rankChangeA) {
                return 1;
            } else {
                return 0;
            }
        }, data);
    }
}

export default KeywordSorterService;
