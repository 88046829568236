import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';
import { REHYDRATE } from 'redux-persist/constants';
import { hasPath } from 'ramda';

const initialState = {
    isWithDeletedTrackings: false,
    isWithDeletedKeywords: false,
    isGroupedByDomain: false,
};

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_SETTINGS_IS_WITH_DELETED_TRACKINGS: {
            return update(state, {
                isWithDeletedTrackings: {
                    $set: action.payload || false,
                },
            });
        }
        case ActionTypes.UI_SETTINGS_IS_WITH_DELETED_KEYWORDS: {
            return update(state, {
                isWithDeletedKeywords: {
                    $set: action.payload || false,
                },
            });
        }
        case ActionTypes.UI_SETTINGS_IS_WITH_GROUPED_BY_DOMAIN: {
            return update(state, {
                isGroupedByDomain: {
                    $set: action.payload || initialState.isGroupedByDomain,
                },
            });
        }
        case REHYDRATE: {
            return update(state, {
                isGroupedByDomain: {
                    $set: hasPath(['ui', 'settings', 'isGroupedByDomain'], action.payload)
                        ? action.payload.ui.settings.isGroupedByDomain
                        : false,
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default settingsReducer;
