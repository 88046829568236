import CSV from 'comma-separated-values';
import { isEmpty } from 'ramda';

import CSVExportService from 'mangools-commons/lib/services/CSVExportService';

import DateFormatService from 'services/DateFormatService';

import { REFERENCE_POINT, POINT_1, POINT_2, POINT_3 } from 'constants/TimeframePoints';
import Values from 'constants/Values';

class KeywordExportService {
    static export({ keywords, timeframePointsObject }) {
        let columns = [];
        let data = [];

        columns = [
            'Keyword',
            'Added on',
            `Position (${KeywordExportService.formatDate(timeframePointsObject[REFERENCE_POINT])})`,
            `Position Change (${KeywordExportService.formatDate(timeframePointsObject[REFERENCE_POINT])})`,
            'Search Volume',
            'Estimated Visits',
            `Position (${KeywordExportService.formatDate(timeframePointsObject[POINT_1])})`,
            `Position (${KeywordExportService.formatDate(timeframePointsObject[POINT_2])})`,
            `Position (${KeywordExportService.formatDate(timeframePointsObject[POINT_3])})`,
            'Avg. position',
            'Best position',
            'Tags',
            'Indexed URL',
        ];

        data = keywords.map(keyword => [
            keyword.keyword,
            keyword.nearestBeforeDay,
            KeywordExportService.formatRank(keyword.rank),
            keyword.rankChange,
            CSVExportService.formatEmptyValue(keyword.searchVolume),
            CSVExportService.formatEmptyValue(keyword.estimatedVisits),
            KeywordExportService.formatRank(keyword.rankHistory[POINT_1].value),
            KeywordExportService.formatRank(keyword.rankHistory[POINT_2].value),
            KeywordExportService.formatRank(keyword.rankHistory[POINT_3].value),
            KeywordExportService.formatRank(keyword.rankAvg),
            KeywordExportService.formatRank(keyword.rankBest),
            keyword.tags.map(tag => tag.name).join(', '),
            keyword.url,
        ]);

        return CSV.encode(data, { header: columns });
    }

    static formatRank(value) {
        /*
            first we format the value which will either result in the original value
            or in a case of "empty value" e.g. N/A, -1, etc. an empty string
        */
        const formattedValue = CSVExportService.formatEmptyValue(value);

        // if the string is empty i.e. `''` we simply return the empty string as value;
        if (isEmpty(formattedValue)) {
            return formattedValue;
        } else if (value === Values.RANK_MORE_THAN_100) {
            return '101+';
        } else {
            return value;
        }
    }

    static formatDate(value) {
        return DateFormatService.formatLong(value);
    }
}

export default KeywordExportService;
