import { useSelector } from 'react-redux';
import React from 'react';
import { Link } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RoutePaths from 'constants/RoutePaths';

import { newTrackingCreatedTrackingIdSelector } from 'selectors/uiSelectors';

import tagsVideoMp4 from 'videos/tags-feature.mp4';
import tagsVideoWebm from 'videos/tags-feature.webm';
import TipsVideo from 'components/newTracking/step/finish/TipsVideo';

const OrganizeTrackingsLayout = () => {
    const createdTrackingId = useSelector(newTrackingCreatedTrackingIdSelector);

    return (
        <div className="sw-overflow-visible uk-flex uk-flex-column">
            <div className="uk-flex mg-alert is-info is-big">
                <FontAwesomeIcon
                    icon={['far', 'lightbulb']}
                    aria-label="Tip"
                    className="mg-margin-r-15 mg-margin-t-5 uk-hidden-small uk-position-relative font-36"
                />
                <div className="font-14 uk-flex uk-flex-column">
                    <h3 className="mg-margin-b-5 uk-text-bold">Organize your tracked keywords</h3>
                    <p className="uk-margin-small">
                        Using tags is a great way to manage your tracked keywords. Keyword tagging gives you an option
                        to categorize keywords for different blog posts, e-commerce categories, or subpages under the
                        same domain.
                    </p>
                </div>
            </div>
            <div className="uk-flex uk-flex-wrap mg-margin-t-45 mg-margin-b-30">
                <div className="uk-width-medium-1-2 uk-wdith-1-1">
                    <TipsVideo VideoMp4={tagsVideoMp4} VideoWebm={tagsVideoWebm} />
                </div>
                <div className="uk-width-medium-1-2 mg-padding-15 uk-padding-bottom-remove uk-wdith-1-1 font-14">
                    <p>There are three ways to get to the tags menu:</p>
                    <p>
                        1. Click on the button&nbsp;
                        <strong className="color-black">&quot;Tags&quot; under the keywords table</strong>&nbsp; when at
                        least one keyword is selected. <br />
                        You can&nbsp;
                        <strong className="color-black">see all your tags above the table with keywords</strong>.
                    </p>
                    <p>2. Click on the button &quot;Add tag&quot; in the keyword detail.</p>
                    <p>3. Go to the three dost menu in the top right corner and click on &quot;Manage tags&quot;.</p>
                    <h4 className="uk-text-bold font-18">Add a tag</h4>
                    <p>
                        Once you click on &quot;Tags&quot; under the keywords table or &quot;Add tag&quot; in the
                        keyword detail, pick the tag(s) from the menu and click on&nbsp;
                        <strong className="color-black">&quot;Assign&quot;</strong>&nbsp;
                    </p>
                    <h4 className="uk-text-bold font-18">How to do it?</h4>
                    <ol>
                        <li>
                            Click on&nbsp;
                            <strong className="color-black">&quot;Unassign&quot;</strong>&nbsp; if you used the
                            under-the-keywords option.
                        </li>
                        <li>Click &quot;X&quot; in the tag area in the keyword detail.</li>
                    </ol>
                </div>
            </div>
            <hr className="mg-margin-b-30" />
            <p className="uk-width-1-1 uk-flex uk-flex-center">
                <Link
                    to={{ pathname: RoutePaths.TRACKING, query: { id: createdTrackingId } }}
                    className="mg-btn is-small is-green uk-width-2-3"
                >
                    Go to tracking
                </Link>
            </p>
        </div>
    );
};

export default OrganizeTrackingsLayout;
