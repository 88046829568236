import { library } from '@fortawesome/fontawesome-svg-core';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faMoonAuto } from 'mangools-commons/lib/constants/Icons';

import {
    faArrowDown,
    faArrowUp,
    faArrowsAlt,
    faBan,
    faBars,
    faCaretDown,
    faCaretUp,
    faCheck,
    faChevronCircleLeft,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faSearch,
    faCircle,
    faCloudUploadAlt,
    faCog,
    faComment,
    faCopy,
    faDownload,
    faEllipsisV,
    faExclamation,
    faExclamationTriangle,
    faExternalLinkAlt,
    faGift,
    faGlobeAmericas,
    faInfoCircle,
    faKeyboard,
    faLaptop,
    faList,
    faMap,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faMinus,
    faMinusSquare,
    faMobileAlt,
    faMoon,
    faMousePointer,
    faPaperPlane,
    faPencilAlt,
    faPercent,
    faPlug,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faPowerOff,
    faQuestion,
    faRocket,
    faShareAlt,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faSun,
    faSync,
    faTachometerAlt,
    faTag,
    faTags,
    faTimes,
    faUser,
    faUserCog,
    faWaveSquare,
    faCamera,
    faEdit,
    faLock,
    faUnlock,
    faLink,
    faKey,
    faFileUpload,
    faEye,
    faEyeSlash,
    faPaintBrush,
    faQuoteRight,
} from '@fortawesome/free-solid-svg-icons';

import {
    faBell,
    faCalendarAlt,
    faClone,
    faCreditCard,
    faDotCircle,
    faFileExcel,
    faHandPaper,
    faLightbulb,
    faQuestionCircle,
    faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faArrowDown,
    faArrowUp,
    faArrowsAlt,
    faBan,
    faBars,
    faBell,
    faCalendarAlt,
    faCaretDown,
    faCaretUp,
    faCheck,
    faChevronCircleLeft,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faClone,
    faCloudUploadAlt,
    faCog,
    faComment,
    faCopy,
    faCreditCard,
    faDotCircle,
    faDownload,
    faEllipsisV,
    faExclamation,
    faExclamationTriangle,
    faExternalLinkAlt,
    faFileExcel,
    faGift,
    faGlobeAmericas,
    faGoogle,
    faHandPaper,
    faInfoCircle,
    faKeyboard,
    faLaptop,
    faLightbulb,
    faList,
    faMapMarkerAlt,
    faQuoteRight,
    faMinus,
    faMinusSquare,
    faMobileAlt,
    faMoon,
    faMoonAuto,
    faMousePointer,
    faPaperPlane,
    faPencilAlt,
    faPercent,
    faPlug,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faPowerOff,
    faMap,
    faMapMarkedAlt,
    faQuestion,
    faQuestionCircle,
    faRocket,
    faShareAlt,
    faLink,
    faKey,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faSun,
    faSync,
    faTachometerAlt,
    faTag,
    faTags,
    faTimes,
    faTrashAlt,
    faUser,
    faUserCog,
    faWaveSquare,
    faSearch,
    faCamera,
    faEdit,
    faLock,
    faUnlock,
    faFileUpload,
    faEye,
    faEyeSlash,
    faPaintBrush,
);
