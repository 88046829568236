import React from 'react';

const TipsVideo = ({ VideoMp4, VideoWebm }: TipsVideoProps) => {
    return (
        <video style={{ margin: 'auto' }} width="100%" height="auto" autoPlay playsInline loop muted>
            <source src={VideoMp4} type="video/mp4" />
            <source src={VideoWebm} type="video/webm" />
        </video>
    );
};

interface TipsVideoProps {
    VideoMp4: string;
    VideoWebm: string;
}

export default TipsVideo;
