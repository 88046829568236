import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import { bool, func } from 'prop-types';
import trim from 'ramda/src/trim';

class AnnotationsMessageNewForm extends Component {
    constructor() {
        super();

        this.state = {
            annotationText: '',
            nonShareable: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderButtonBody = this.renderButtonBody.bind(this);
    }

    shouldComponentUpdate(newProps, newState) {
        return shallowCompare(this, newProps, newState);
    }

    handleSubmit(e) {
        e.preventDefault();

        // Submit only when not creating already
        if (!this.props.fetching) {
            this.props.onSubmit(trim(this.state.annotationText), this.state.nonShareable);

            // Reset input value
            this.setState({
                annotationText: '',
                nonShareable: false,
            });
        }
    }

    handleChange(e) {
        this.setState({
            annotationText: e.target.value,
        });
    }

    handleCheckboxChange(e) {
        this.setState({
            nonShareable: e.target.checked,
        });
    }

    renderButtonBody() {
        if (this.props.fetching) {
            return (
                <span className="mg-preloader-inline">
                    <span />
                    <span />
                    <span />
                </span>
            );
        } else {
            return <span>Add comment</span>;
        }
    }

    render() {
        return (
            <div className="mg-margin-t-15">
                <form onSubmit={this.handleSubmit}>
                    <textarea
                        className="mg-input font-16 ln-12"
                        disabled={this.props.fetching}
                        maxLength={160}
                        minLength={1}
                        onChange={this.handleChange}
                        placeholder="Did anything special happen on this day? Add it as a comment to the chart."
                        required
                        style={{ minHeight: 'unset' }}
                        type="text"
                        value={this.state.annotationText}
                    />
                    <div className="mg-truncate uk-width-2-3 uk-flex">
                        <label htmlFor="new-nonSharable" className="cursor-pointer">
                            <input
                                checked={this.state.nonShareable}
                                onChange={this.handleCheckboxChange}
                                type="checkbox"
                                className="mg-margin-r-10 cursor-pointer"
                                id="new-nonSharable"
                            />
                            Don&apos;t show in reports
                        </label>
                    </div>
                    <button
                        className="mg-btn is-green is-small font-12 mg-margin-t-10"
                        disabled={this.props.fetching}
                        type="submit"
                        style={{ minWidth: '120px' }}
                    >
                        {this.renderButtonBody()}
                    </button>
                </form>
            </div>
        );
    }
}

AnnotationsMessageNewForm.propTypes = {
    fetching: bool.isRequired,
    onSubmit: func.isRequired,
};

export default AnnotationsMessageNewForm;
