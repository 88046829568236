import React from 'react';
import { bool, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MessageHolder from 'components/messages/MessageHolder';

function NeedToSelectKeywordMessage(props) {
    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose}>
                <div className="mg-modal-header is-info">
                    <FontAwesomeIcon icon="info-circle" aria-label="Info" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold">Select some keywords</h3>
                    <p>
                        This action cannot be completed.&nbsp; Select <strong>at least one keyword</strong> and try
                        again, please.
                    </p>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}

NeedToSelectKeywordMessage.propTypes = {
    onClose: func.isRequired,
    visible: bool.isRequired,
};

export default NeedToSelectKeywordMessage;
