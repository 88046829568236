// const PARSED_REPORTS_EXAMPLE = [{
//      alertTriggers: [],
//      createdAt: 12042042042,
//      emails: ['a@b.com'],
//      id: 'abdsbads',
//      name: 'My report',
//      type: 1
// }];

export const parse = rawReports =>
    rawReports.map(item => ({
        alertTriggers: item.triggers.map(trigger => parseInt(trigger, 10)),
        createdAt: item.created_at,
        emails: item.emails,
        id: item._id, // eslint-disable-line no-underscore-dangle
        name: item.name,
        type: parseInt(item.type, 10),
    }));

export const parseCreate = rawReport => ({
    alertTriggers: rawReport.triggers.map(trigger => parseInt(trigger, 10)),
    createdAt: rawReport.created_at,
    emails: rawReport.emails,
    id: rawReport._id, // eslint-disable-line no-underscore-dangle
    name: rawReport.name,
    type: parseInt(rawReport.type, 10),
});
