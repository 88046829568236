import {
    setAddKeywordsPanelSelectedList,
    resetAddKeywordsPanelSelectedLists,
    setNewTrackingSelectedList,
    resetNewTrackingSelectedLists,
} from 'actions/uiActions';
import {
    requestedAddKeywordsPanelListsKeywordsAction,
    requestedNewTrackingListsKeywordsAction,
} from 'actions/dataActions';
import { addKeywordsPanelSelectedListIdsSelector, newTrackingSelectedListIdsSelector } from 'selectors/commonSelectors';

export const IMPORT_KWFLIST_NEWTRACKING_TARGET = 'IMPORT_KWFLIST_NEWTRACKING';
export const IMPORT_KWFLIST_ADDKEYWORDS_TARGET = 'IMPORT_KWFLIST_ADDKEYWORDS_TARGET';

export const ImportKwfListTargets = {
    [IMPORT_KWFLIST_NEWTRACKING_TARGET]: {
        resetSelectedListIdsActionCreator: resetNewTrackingSelectedLists,
        importActionCreator: requestedNewTrackingListsKeywordsAction,
        selectedListIdsSelector: newTrackingSelectedListIdsSelector,
        listSelectActionCreator: setNewTrackingSelectedList,
    },
    [IMPORT_KWFLIST_ADDKEYWORDS_TARGET]: {
        resetSelectedListIdsActionCreator: resetAddKeywordsPanelSelectedLists,
        importActionCreator: requestedAddKeywordsPanelListsKeywordsAction,
        selectedListIdsSelector: addKeywordsPanelSelectedListIdsSelector,
        listSelectActionCreator: setAddKeywordsPanelSelectedList,
    },
};
