import React from 'react';
import { connect } from 'react-redux';

import AppPanel from 'components/panels/AppPanel';
import { CustomizeReportPanel } from 'components/panels/CustomizeReportPanel';
import CustomizeResultsPanel from 'components/panels/CustomizeResultsPanel';

import { closeAppPanel } from 'actions/uiActions';

import {
    appPanelVisibilitySelector,
    customizeReporVisibilitySelector,
    customizeResultsPanelVisibilitySelector
} from 'selectors/uiSelectors';

interface PanelContainerProps {
    onCloseAppPanel: (e: React.MouseEvent<HTMLButtonElement>) => void;
    appPanelVisible: boolean;
    customizeReportPanelVisible: boolean;
    customizeResultsPanelVisible: boolean;
}

function PanelContainer(props: PanelContainerProps) {
    return (
        <>
            <AppPanel onClose={props.onCloseAppPanel} visible={props.appPanelVisible} />
            <CustomizeReportPanel visible={props.customizeReportPanelVisible} />
            <CustomizeResultsPanel visible={props.customizeResultsPanelVisible} />
        </>
    );
}

const mapStateToProps = (state: any) => ({
    appPanelVisible: appPanelVisibilitySelector(state),
    customizeReportPanelVisible: customizeReporVisibilitySelector(state),
    customizeResultsPanelVisible: customizeResultsPanelVisibilitySelector(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    onCloseAppPanel() {
        dispatch(closeAppPanel());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelContainer);
