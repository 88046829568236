// const PARSED_DATA_EXAMPLE = [{
//     code: 'sk',
//     id: 2703,
//     label: 'Country',
//     type: 'Country'
// }, {
//     code: 'us',
//     id: 1025290,
//     label: 'Slovan',
//     type: 'City'
// }];

import { parseLocationLabel } from 'sources/parsers/commons';

export const parse = rawData =>
    rawData.map(item => ({
        code: item.code,
        id: parseInt(item._id, 10), // eslint-disable-line no-underscore-dangle
        label: parseLocationLabel(item.label),
        type: item.target_type,
    }));
