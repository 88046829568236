import React, { Component } from 'react';
import { bool, func, number, string } from 'prop-types';
import { connect } from 'react-redux';

import RoutePaths from 'constants/RoutePaths';
import Preloader from 'components/other/Preloader';

import { requestedNavigationReplaceAction } from 'actions/routerActions';

import { firstTrackingIdSelector, trackingCountSelector, trackingsFetchedSelector } from 'selectors/dataSelectors';

import {
    fetchedSelector,
    limitsFetchedSelector,
    loggedInSelector,
    userPlanTypeSelector,
} from 'selectors/userSelectors';

class IndexContainer extends Component {
    constructor() {
        super();

        this.handleRedirect = this.handleRedirect.bind(this);
        this.isUserFetched = this.isUserFetched.bind(this);
    }

    componentDidMount() {
        this.handleRedirect();
    }

    componentDidUpdate() {
        this.handleRedirect();
    }

    isUserFetched() {
        return this.props.userFetched && this.props.userLimitsFetched;
    }

    /* eslint-disable max-len */
    handleRedirect() {
        if (this.isUserFetched()) {
            if (this.props.trackingsFetched) {
                if (this.props.trackingCount === 0) {
                    this.props.requestReplaceNavigation(RoutePaths.NEW, {});
                } else if (this.props.trackingCount === 1) {
                    this.props.requestReplaceNavigation(RoutePaths.TRACKING, { id: this.props.firstTrackingId });
                } else {
                    this.props.requestReplaceNavigation(RoutePaths.TRACKINGS, {});
                }
            }
        }
    }
    /* eslint-enable max-len */

    render() {
        if (this.isUserFetched() && this.props.trackingsFetched) {
            return null;
        } else {
            // NOTE: Only logged in user will ever get trackings loaded, unlogged user will see need to sign up message
            // and therefor there would be 2 preloaders behind the message and that does not work very well on Safari :(

            // eslint-disable-next-line no-lonely-if
            if (this.props.userLoggedIn) {
                return (
                    <div className="uk-position-cover uk-flex uk-flex-middle uk-flex-center">
                        <Preloader />
                    </div>
                );
            } else {
                return null;
            }
        }
    }
}

const mapStateToProps = state => ({
    firstTrackingId: firstTrackingIdSelector(state),
    trackingCount: trackingCountSelector(state),
    trackingsFetched: trackingsFetchedSelector(state),
    userFetched: fetchedSelector(state),
    userLimitsFetched: limitsFetchedSelector(state),
    userLoggedIn: loggedInSelector(state),
    userPlanType: userPlanTypeSelector(state),
});

const mapDispatchToProps = dispatch => ({
    requestReplaceNavigation(route, params) {
        return dispatch(requestedNavigationReplaceAction(route, params));
    },
});

IndexContainer.propTypes = {
    firstTrackingId: string,
    requestReplaceNavigation: func.isRequired,
    trackingCount: number.isRequired,
    trackingsFetched: bool.isRequired,
    userFetched: bool.isRequired,
    userLimitsFetched: bool.isRequired,
    userLoggedIn: bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexContainer);
