import moment from 'moment';
import isNil from 'ramda/src/isNil';
import Defaults from 'mangools-commons/lib/constants/Defaults';

const BASIC_FORMAT = 'Do MMM YY';
const BASIC_NO_YEAR_FORMAT = 'Do MMM';
const ISO_FORMAT = 'YYYY-MM-DD';
const LONG_FORMAT = 'Do MMM, YYYY';
const TZ_FORMAT = 'ZZ';
const VERY_LONG_FORMAT = 'dddd Do MMM, YYYY';

// Works with moment objects
class DateFormatService {
    static formatBasic(value) {
        if (isNil(value) || value === Defaults.NOT_AVAILABLE) {
            return Defaults.NOT_AVAILABLE;
        } else if (moment.isMoment(value)) {
            return value.format(BASIC_FORMAT);
        } else {
            return moment(value).format(BASIC_FORMAT);
        }
    }

    static formatBasicNoYear(value) {
        if (isNil(value) || value === Defaults.NOT_AVAILABLE) {
            return Defaults.NOT_AVAILABLE;
        } else if (moment.isMoment(value)) {
            return value.format(BASIC_NO_YEAR_FORMAT);
        } else {
            return moment(value).format(BASIC_NO_YEAR_FORMAT);
        }
    }

    // Return ISO string from moment date
    static formatISO(value) {
        if (isNil(value) || value === Defaults.NOT_AVAILABLE) {
            return Defaults.NOT_AVAILABLE;
        } else if (moment.isMoment(value)) {
            return value.format(ISO_FORMAT);
        } else {
            return moment(value).format(ISO_FORMAT);
        }
    }

    // Return ISO string with TZ from current moment date
    static formatISOWithCurrentTZ(value) {
        const withoutTZ = this.formatISO(value);

        if (withoutTZ === Defaults.NOT_AVAILABLE) {
            return Defaults.NOT_AVAILABLE;
        } else {
            const currentTZ = moment().format(TZ_FORMAT);
            return `${withoutTZ}Z${currentTZ}`;
        }
    }

    static formatLong(value) {
        if (isNil(value) || value === Defaults.NOT_AVAILABLE) {
            return Defaults.NOT_AVAILABLE;
        } else if (moment.isMoment(value)) {
            return value.format(LONG_FORMAT);
        } else {
            return moment(value).format(LONG_FORMAT);
        }
    }

    static formatVeryLong(value) {
        if (isNil(value) || value === Defaults.NOT_AVAILABLE) {
            return Defaults.NOT_AVAILABLE;
        } else if (moment.isMoment(value)) {
            return value.format(VERY_LONG_FORMAT);
        } else {
            return moment(value).format(VERY_LONG_FORMAT);
        }
    }

    static formatReference(value, long = true) {
        if (isNil(value) || value === Defaults.NOT_AVAILABLE) {
            return Defaults.NOT_AVAILABLE;
        } else {
            let val = value;

            if (!moment.isMoment(value)) {
                val = moment(value);
            }

            if (val.isSame(moment(), 'day')) {
                return 'Today';
            } else if (val.isSame(moment().subtract(1, 'day'), 'day')) {
                return 'Yesterday';
            } else if (long === true) {
                return this.formatLong(val);
            } else {
                return this.formatBasic(val);
            }
        }
    }

    static formatFrom({ reference, value, long = true }) {
        if (
            isNil(value) ||
            isNil(reference) ||
            value === Defaults.NOT_AVAILABLE ||
            reference === Defaults.NOT_AVAILABLE
        ) {
            return Defaults.NOT_AVAILABLE;
        } else {
            const valueObj = moment.isMoment(value) ? value : moment(value);
            const referenceObj = moment.isMoment(reference) ? reference : moment(reference);

            let fromDateString = valueObj.from(referenceObj);

            if (long) {
                return fromDateString;
            } else {
                fromDateString = fromDateString.replace(/ago/, 'before');
                fromDateString = fromDateString.replace(/ days/, 'd');
                fromDateString = fromDateString.replace(/a day/, '1d');
                fromDateString = fromDateString.replace(/ months/, 'm');
                fromDateString = fromDateString.replace(/a month/, '1m');
                fromDateString = fromDateString.replace(/ years/, 'y');
                fromDateString = fromDateString.replace(/a year/, '1y');

                return fromDateString;
            }
        }
    }
}

export default DateFormatService;
