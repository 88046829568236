import NumberOrNAType from 'types/commons/NumberOrNAType';

import { bool, number, string, oneOfType, shape } from 'prop-types';

export interface MapPack {
    rank: number | null;
    hasUrl: boolean | null;
    estimatedVisits: number | string;
}

export const MapPackPropType = shape({
    rank: NumberOrNAType.isRequired,
    hasUrl: bool.isRequired,
    estimatedVisits: oneOfType([string, number]).isRequired,
});
