import config from 'appConfig';
import queryString from 'query-string';

import { parse, parseParams } from 'sources/parsers/SuggestedKwsResultDataParser';

import ErrorCodes from 'mangools-commons/lib/constants/ErrorCodes';
import HumanService from 'mangools-commons/lib/services/HumanService';

// Constants
const ENDPOINT = '/suggested-keywords';

/**
 * Data source class handling communication with API for result list data.
 */
class SuggestedKwsResultSource {
    static getData({ accessToken, params }) {
        const query = queryString.stringify({
            debug: 0,
            location_id: params.locationId,
            url: params.query,
        });
        const requestURL = `${config.API_HOST}${config.KWFINDER_API_PATH}${ENDPOINT}?${query}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
                'X-Human-Token': HumanService.gen(),
                'X-Mangools-Client': 'app',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => {
                            const parsedParams = parseParams(data);
                            parsedParams.query = params.query;

                            return {
                                error: false,
                                payload: {
                                    data: {
                                        keywords: parse(data),
                                    },
                                    params: parsedParams,
                                },
                            };
                        })
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default SuggestedKwsResultSource;
