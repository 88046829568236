import ActionTypes from 'constants/ActionTypes';
import update from 'immutability-helper';
import concat from 'ramda/src/concat';
import includes from 'ramda/src/includes';
import isEmail from 'validator/lib/isEmail';
import uniq from 'ramda/src/uniq';

import { WEEKLY } from 'constants/ReportTypes';
import { TOP_10_ENTER } from 'constants/AlertTriggers';
import { NO_REPORT_ID } from 'constants/Other';

const initialState = {
    addKeywords: {
        error: {
            status: null,
            text: null,
        },
        fetching: false,
        keywordsInLimit: [],
        keywordsInTracking: [],
        keywordsNotInLimit: [],
        filteredCount: {
            duplicates: 0,
            tooShort: 0,
        },
        tagIds: [],
        selectedListIds: [],
        visibility: false,
    },
    app: {
        visibility: false,
    },
    manageTags: {
        visibility: false,
    },
    reports: {
        currentReportId: null,
        edit: {
            alertTriggers: [],
            emails: [],
            name: null,
            open: false,
            type: WEEKLY.id,
        },
        new: {
            alertTriggers: [TOP_10_ENTER.id],
            emails: null,
            name: null,
            open: true,
            type: WEEKLY.id,
        },
        visibility: false,
    },
    customizeReportPanel: {
        visibility: false,
        fetching: false,
        isDirty: false,
    },
    customizeResultsPanel: {
        visibility: false,
    }
};

const addKeywordPanelReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_PANELS_APP_CLOSE: {
            return update(state, {
                app: {
                    visibility: { $set: false },
                },
            });
        }
        case ActionTypes.UI_PANELS_APP_SHOW: {
            return update(state, {
                app: {
                    visibility: { $set: true },
                },
            });
        }
        case ActionTypes.UI_PANELS_ADD_KEYWORDS_SELECTED_LIST_SET: {
            const { listId, selected } = action.payload;

            if (selected === true) {
                return update(state, {
                    addKeywords: {
                        selectedListIds: {
                            $apply: ids => {
                                if (includes(ids, listId)) {
                                    return ids;
                                } else {
                                    return concat(ids, [listId]);
                                }
                            },
                        },
                    },
                });
            } else {
                return update(state, {
                    addKeywords: {
                        selectedListIds: {
                            $apply: ids => ids.filter(id => id !== listId),
                        },
                    },
                });
            }
        }
        case ActionTypes.UI_PANELS_ADD_KEYWORDS_FILTERED_COUNT_SET: {
            return update(state, {
                addKeywords: {
                    filteredCount: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_PANELS_ADD_KEYWORDS_SELECTED_LISTS_RESET: {
            return update(state, {
                addKeywords: {
                    selectedListIds: { $set: [] },
                },
            });
        }
        case ActionTypes.UI_PANELS_ADD_KEYWORDS_KEYWORDS_SET: {
            return update(state, {
                addKeywords: {
                    keywordsInLimit: { $set: action.payload.keywordsInLimit },
                    keywordsInTracking: { $set: action.payload.keywordsInTracking },
                    keywordsNotInLimit: { $set: action.payload.keywordsNotInLimit },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_ADD_KEYWORDS_FETCHING: {
            return update(state, {
                addKeywords: {
                    fetching: { $set: true },
                },
            });
        }
        case ActionTypes.DATA_KWFINDER_LIST_URL_IMPORT_ERROR:
        case ActionTypes.DATA_TRACKING_ADD_KEYWORDS_ERROR: {
            return update(state, {
                addKeywords: {
                    fetching: { $set: false },
                    error: {
                        status: { $set: action.payload.status },
                        text: { $set: action.payload.text },
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_ADD_KEYWORDS_KEYWORDS_RESET: {
            return update(state, {
                addKeywords: {
                    keywordsInLimit: { $set: initialState.addKeywords.keywordsInLimit },
                    keywordsInTracking: { $set: initialState.addKeywords.keywordsInTracking },
                    keywordsNotInLimit: { $set: initialState.addKeywords.keywordsNotInLimit },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_ADD_KEYWORDS_RECEIVED: {
            return update(state, {
                addKeywords: { $set: initialState.addKeywords },
            });
        }
        case ActionTypes.UI_PANELS_REPORTS_NEW_TYPE_SET: {
            return update(state, {
                reports: {
                    new: {
                        type: {
                            $set: action.payload,
                        },
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_REPORTS_NEW_NAME_SET: {
            return update(state, {
                reports: {
                    new: {
                        name: {
                            $set: action.payload,
                        },
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_REPORTS_NEW_EMAILS_SET: {
            const emails = action.payload;
            const validEmails = emails.filter(email => isEmail(email));

            return update(state, {
                reports: {
                    new: {
                        emails: {
                            $set: uniq(validEmails),
                        },
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_REPORTS_NEW_ALERT_TRIGGERS_SELECTED_SET: {
            const { id, selected } = action.payload;

            if (selected === true) {
                return update(state, {
                    reports: {
                        new: {
                            alertTriggers: {
                                $apply: ids => {
                                    if (includes(ids, id)) {
                                        return ids;
                                    } else {
                                        return concat(ids, [id]);
                                    }
                                },
                            },
                        },
                    },
                });
            } else {
                return update(state, {
                    reports: {
                        new: {
                            alertTriggers: {
                                $apply: triggerIds => triggerIds.filter(triggerId => triggerId !== id),
                            },
                        },
                    },
                });
            }
        }
        case ActionTypes.UI_PANELS_REPORTS_EDIT_REQUESTED: {
            return update(state, {
                reports: {
                    edit: {
                        type: { $set: action.payload.type },
                        alertTriggers: { $set: action.payload.alertTriggers },
                        emails: { $set: action.payload.emails },
                        name: { $set: action.payload.name },
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_REPORTS_EDIT_TYPE_SET: {
            return update(state, {
                reports: {
                    edit: {
                        type: {
                            $set: action.payload,
                        },
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_REPORTS_EDIT_NAME_SET: {
            return update(state, {
                reports: {
                    edit: {
                        name: {
                            $set: action.payload,
                        },
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_REPORTS_EDIT_EMAILS_SET: {
            const emails = action.payload;
            const validEmails = emails.filter(email => isEmail(email));

            return update(state, {
                reports: {
                    edit: {
                        emails: {
                            $set: uniq(validEmails),
                        },
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_REPORTS_EDIT_ALERT_TRIGGERS_SELECTED_SET: {
            const { id, selected } = action.payload;

            if (selected === true) {
                return update(state, {
                    reports: {
                        edit: {
                            alertTriggers: {
                                $apply: ids => {
                                    if (includes(ids, id)) {
                                        return ids;
                                    } else {
                                        return concat(ids, [id]);
                                    }
                                },
                            },
                        },
                    },
                });
            } else {
                return update(state, {
                    reports: {
                        edit: {
                            alertTriggers: {
                                $apply: triggerIds => triggerIds.filter(triggerId => triggerId !== id),
                            },
                        },
                    },
                });
            }
        }
        case ActionTypes.DATA_DELETE_REPORT_RECEIVED:
        case ActionTypes.DATA_UPDATE_REPORT_RECEIVED: {
            return update(state, {
                reports: {
                    currentReportId: { $set: NO_REPORT_ID },
                },
            });
        }
        case ActionTypes.DATA_NEW_REPORT_RECEIVED: {
            return update(state, {
                reports: {
                    currentReportId: { $set: NO_REPORT_ID },
                    new: { $set: initialState.reports.new },
                },
            });
        }
        case ActionTypes.UI_PANELS_REPORTS_CURRENT_REPORT_ID_SET: {
            return update(state, {
                reports: {
                    currentReportId: {
                        $set: action.payload,
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_ADD_KEYWORDS_CLOSE: {
            return update(state, {
                addKeywords: {
                    visibility: { $set: false },
                },
            });
        }
        case ActionTypes.UI_PANELS_ADD_KEYWORDS_SHOW: {
            return update(state, {
                addKeywords: {
                    visibility: { $set: true },
                },
            });
        }
        case ActionTypes.UI_PANELS_REPORTS_CLOSE: {
            return update(state, {
                reports: {
                    visibility: { $set: false },
                },
            });
        }
        case ActionTypes.UI_PANELS_REPORTS_SHOW: {
            return update(state, {
                reports: {
                    visibility: { $set: true },
                },
            });
        }
        case ActionTypes.UI_PANELS_MANAGE_TAGS_CLOSE: {
            return update(state, {
                manageTags: {
                    visibility: { $set: false },
                },
            });
        }
        case ActionTypes.UI_PANELS_MANAGE_TAGS_SHOW: {
            return update(state, {
                manageTags: {
                    visibility: { $set: true },
                },
            });
        }
        case ActionTypes.UI_PANELS_SET_ADDKW_TAGS: {
            return update(state, {
                addKeywords: {
                    tagIds: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_PANELS_UNASSIGN_ADDKW_TAG: {
            const tagId = action.payload;
            return update(state, {
                addKeywords: {
                    tagIds: {
                        $apply: ids => ids.filter(id => id !== tagId),
                    },
                },
            });
        }
        case ActionTypes.UI_PANELS_CUSTOMIZE_REPORTS_SHOW: {
            return update(state, {
                customizeReportPanel: {
                    visibility: { $set: true },
                },
            });
        }
        case ActionTypes.UI_PANELS_CUSTOMIZE_REPORTS_CLOSE: {
            return update(state, {
                customizeReportPanel: {
                    visibility: { $set: false },
                    isDirty: { $set: false },
                },
            });
        }
        case ActionTypes.UI_PANELS_CUSTOMIZE_REPORTS_DIRTY_SET: {
            return update(state, {
                customizeReportPanel: {
                    isDirty: { $set: true },
                },
            });
        }
        case ActionTypes.DATA_USER_SETTINGS_UPDATE_FETCHING: {
            return update(state, {
                customizeReportPanel: {
                    fetching: { $set: true },
                    isDirty: { $set: false },
                },
            });
        }
        case ActionTypes.DATA_USER_SETTINGS_UPDATE_ERROR:
        case ActionTypes.DATA_USER_DATA_SETTINGS_UPDATE_RECEIVED: {
            return update(state, {
                customizeReportPanel: {
                    fetching: { $set: false },
                },
            });
        }
        case ActionTypes.UI_PANELS_CUSTOMIZE_RESULTS_SHOW: {
            return update(state, {
                customizeResultsPanel: {
                    visibility: { $set: true },
                },
            });
        }
        case ActionTypes.UI_PANELS_CUSTOMIZE_RESULTS_CLOSE: {
            return update(state, {
                customizeResultsPanel: {
                    visibility: { $set: false },
                },
            });
        }

        case ActionTypes.UI_ALL_CLOSE: {
            return update(state, {
                app: {
                    visibility: { $set: false },
                },
                addKeywords: {
                    visibility: { $set: false },
                },
                manageTags: {
                    visibility: { $set: false },
                },
                reports: {
                    visibility: { $set: false },
                },
            });
        }
        case ActionTypes.UI_TRACKING_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default addKeywordPanelReducer;
