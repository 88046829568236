import ErrorCodes from 'mangools-commons/lib/constants/ErrorCodes';
import config from 'appConfig';
import isNil from 'ramda/src/isNil';

import { parse, parseCreate } from 'sources/parsers/TrackingTagDataParser';

// Constants
const TRACKINGS_ENDPOINT = '/trackings';
const ENDPOINT = '/tags';

/**
 * Data source class handling communication with API for tracking data.
 */
class TrackingTagSource {
    static getData({ accessToken }, { trackingId }) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${TRACKINGS_ENDPOINT}/${trackingId}${ENDPOINT}`;
        const headers = {
            'X-Access-Token': accessToken,
        };

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers,
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parse(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static assign({ accessToken }, { trackingId, selectedTagIds, selectedKeywordIds }) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${TRACKINGS_ENDPOINT}/${trackingId}${ENDPOINT}/assign`;
        const requestBody = {
            keyword_ids: selectedKeywordIds,
            tag_ids: selectedTagIds,
        };

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken,
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (response.ok) {
                    return {
                        error: false,
                        payload: null,
                    };
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static unassign({ accessToken }, { trackingId, selectedTagIds, selectedKeywordIds }) {
        /* eslint-disable max-len */
        const requestURL = `${config.API_HOST}${config.API_PATH}${TRACKINGS_ENDPOINT}/${trackingId}${ENDPOINT}/unassign`;
        /* eslint-enable max-len */
        const requestBody = {
            keyword_ids: selectedKeywordIds,
            tag_ids: selectedTagIds,
        };

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken,
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (response.ok) {
                    return {
                        error: false,
                        payload: null,
                    };
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static create({ accessToken }, { trackingId, name, color }) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${TRACKINGS_ENDPOINT}/${trackingId}${ENDPOINT}`;
        const requestBody = { name, color };

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken,
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseCreate(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static update({ accessToken }, { trackingId, tagId, name, color }) {
        /* eslint-disable max-len */
        const requestURL = `${config.API_HOST}${config.API_PATH}${TRACKINGS_ENDPOINT}/${trackingId}${ENDPOINT}/${tagId}`;
        /* eslint-enable max-len */
        const requestBody = {};

        if (!isNil(name)) {
            requestBody.name = name;
        }
        if (!isNil(color)) {
            requestBody.color = color;
        }

        return fetch(requestURL, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken,
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (response.ok) {
                    return {
                        error: false,
                        payload: null,
                    };
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static delete({ accessToken }, { trackingId, tagId }) {
        /* eslint-disable max-len */
        const requestURL = `${config.API_HOST}${config.API_PATH}${TRACKINGS_ENDPOINT}/${trackingId}${ENDPOINT}/${tagId}`;
        /* eslint-enable max-len */

        return fetch(requestURL, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken,
            },
        })
            .then(response => {
                if (response.ok) {
                    return {
                        error: false,
                        payload: null,
                    };
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default TrackingTagSource;
