import React from 'react';
import { bool, func, number } from 'prop-types';
import { connect } from 'react-redux';

import AnnouncementMessage from 'components/messages/topLevel/AnnouncementMessage';
import NewUserMessage from 'components/messages/topLevel/NewUserMessage';

import { requestedNavigationAction } from 'actions/routerActions';
import { closeTopLevelAnnouncementMessage } from 'actions/uiActions';

import { announcementMesageVisibilitySelector, announcementsMessageDataSelector } from 'selectors/dataSelectors';
import { newUserDiscountActiveSelector, newUserDiscountTimerLimitSelector } from 'selectors/userSelectors';
import { isReportRouteSelector } from 'selectors/commonSelectors';

import RoutePaths from 'constants/RoutePaths';

import AnnouncementType from 'types/AnnouncementType';

/**
 * Top Level messages and notifications wrapper.
 * Can be used for cookie info, notifications, etc.
 */
function TopLevelMessageContainer(props) {
    if (props.isReportRoute) {
        return null;
    } else {
        return (
            <>
                <AnnouncementMessage
                    btnText={props.announcementMesageData.btnText}
                    icon={props.announcementMesageData.icon}
                    messageText={props.announcementMesageData.messageText}
                    newTab={props.announcementMesageData.newTab}
                    onClose={props.onCloseAnnouncementMessage}
                    url={props.announcementMesageData.url}
                    visible={props.announcementMesageVisible}
                />
                <NewUserMessage visible={props.newUserVisible} timerLimitSeconds={props.timerLimitSeconds} />
            </>
        );
    }
}

const mapStateToProps = state => ({
    announcementMesageData: announcementsMessageDataSelector(state),
    announcementMesageVisible: announcementMesageVisibilitySelector(state),
    newUserVisible: newUserDiscountActiveSelector(state),
    timerLimitSeconds: newUserDiscountTimerLimitSelector(state),
    isReportRoute: isReportRouteSelector(state),
});

const mapDispatchToProps = dispatch => ({
    onCloseAnnouncementMessage() {
        dispatch(closeTopLevelAnnouncementMessage());
    },
    requestedNavigationToNewTracking() {
        dispatch(requestedNavigationAction(RoutePaths.NEW, {}));
    },
    requestedNavigationToTrackings() {
        dispatch(requestedNavigationAction(RoutePaths.TRACKINGS, {}));
    },
});

TopLevelMessageContainer.propTypes = {
    announcementMesageData: AnnouncementType.isRequired,
    announcementMesageVisible: bool.isRequired,
    newUserVisible: bool.isRequired,
    onCloseAnnouncementMessage: func.isRequired,
    timerLimitSeconds: number.isRequired,
    isReportRoute: bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopLevelMessageContainer);
