const PATH = process.env.APP_SUBPATH || '';

const RoutePaths = {
    NEW: `${PATH}/new`,
    REPORT: `${PATH}/report`,
    ROOT: `${PATH}/`,
    TRACKING: `${PATH}/tracking`,
    TRACKINGS: `${PATH}/trackings`,
};

export default RoutePaths;
