// const PARSED_DATA_EXAMPLE = [{
//     cpc: 2.82,
//     estimatedTraffic: 24600,
//     id: '2s3a1hbjd123asd31',
//     keyword: 'hotels',
//     ppc: 71,
//     type: 0,
//     rank: Defaults.NO_VALUE,
//     rankUpdatedAt: 154232000,
//     searchCount: 1500000,
//     serpPosition: 3,
//     language: {
//         id: 1100,
//         code: 'sk',
//         label: 'Slovak'
//     },
//     location: {
//         id: 2703,
//         code: 'sk',
//         label: 'Slovakia'
//     }
//     monthlySearchVolumes: [{
//         count: 2400,
//         month: 11,
//         year: 2015
//     }]
// }];

// const PARSED_PARAMS_DATA_EXAMPLE = {
//     location: {
//         id: 2703,
//         code: 'sk',
//         label: 'Slovakia'
//     },
//     urlType: 'RootDomain'
// }

import { isNil } from 'ramda';
import { ANY_LANGUAGE } from 'mangools-commons/lib/constants/Languages';
import UrlTypes from 'mangools-commons/lib/constants/UrlTypes';

import { parseFloatWithNVFallback, parseIntWithNVFallback } from 'sources/parsers/commons';
import { SEMRUSH_LOCATIONS } from 'mangools-commons/lib/constants/Locations';

const parseMonthlySearchVolumes = rawVolumes =>
    rawVolumes.map(item => ({
        count: parseIntWithNVFallback(item[2]),
        month: parseInt(item[1], 10),
        year: parseInt(item[0], 10),
    }));

const parseLocation = locationId => SEMRUSH_LOCATIONS.find(({ id }) => id === locationId) || { id: null, label: null };

const parseTimestamp = rawTimestamp => (!isNil(rawTimestamp) ? new Date(rawTimestamp * 1000).getTime() : null);

const parseUrlType = rawUrlType => {
    switch (rawUrlType) {
        case 'URL': {
            return UrlTypes.EXACT_URL;
        }
        case 'SubDomain': {
            return UrlTypes.SUBDOMAIN;
        }
        case 'RootDomain': {
            return UrlTypes.ROOT_DOMAIN;
        }
        default: {
            return UrlTypes.EXACT_URL;
        }
    }
};

export const parseParams = rawData => ({
    location: parseLocation(rawData.location),
    urlType: parseUrlType(rawData.url_type),
});

export const parse = rawData =>
    rawData.keywords.map(item => ({
        cpc: parseFloatWithNVFallback(item.cpc),
        estimatedTraffic: parseIntWithNVFallback(item.et),
        id: item._id, // eslint-disable-line no-underscore-dangle
        keyword: item.kw,
        keywordType: parseInt(item.type, 10),
        language: ANY_LANGUAGE,
        location: parseLocation(item.lid),
        monthlySearchVolumes: !isNil(item.msv) ? parseMonthlySearchVolumes(item.msv) : [],
        ppc: parseIntWithNVFallback(item.ppc),
        rank: parseIntWithNVFallback(item.seo),
        rankUpdatedAt: parseIntWithNVFallback(item.seo_ts),
        searchCount: parseIntWithNVFallback(item.sv || 0),
        searchCountLatest: parseIntWithNVFallback(item.sv_current),
        serpPosition: parseIntWithNVFallback(item.position),
        serpPositionUpdatedAt: parseTimestamp(item.serp_updated_at),
    }));
