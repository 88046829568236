import React from 'react';
import { isEmpty } from 'ramda';
import classnames from 'classnames';
import Colors from 'mangools-commons/lib/constants/Colors';

import { HTTP } from 'constants/Protocols';

interface ILogo {
    logo: string;
    website: string;
    protocol: typeof HTTP;
}

const Logo: React.FC<ILogo> = props => {
    if (!isEmpty(props.logo)) {
        return (
            <div className="sw-whitelabel-logo-wrapper mg-margin-r-15">
                {!isEmpty(props.website) ? (
                    <a href={`${props.protocol.raw}${props.website}`} target="_blank">
                        <img src={props.logo} alt="logo preview" />
                    </a>
                ) : (
                    <img src={props.logo} alt="logo preview" />
                )}
            </div>
        );
    } else {
        return null;
    }
};

interface IContact {
    name: string;
    isSingleColumn: boolean;
    protocol: typeof HTTP;
    website: string;
    email: string;
    logo: string;
}

const Contact: React.FC<IContact> = props => {
    return (
        <div
            className={classnames('font-12 uk-text-right ln-11', {
                'uk-width-1-1': isEmpty(props.logo) && props.isSingleColumn,
                'uk-text-center': isEmpty(props.logo) && props.isSingleColumn,
            })}
        >
            {props.isSingleColumn && !isEmpty(props.name) && isEmpty(props.logo) ? (
                <h1 className="sw-customize-report-email-preview-header-company-name">{props.name}</h1>
            ) : null}
            {!isEmpty(props.website) ? (
                <span>
                    <a href={`${props.protocol.raw}${props.website}`} target="_blank" rel="noopener">{props.website}</a>
                    <br />
                </span>
            ) : null}
            {!isEmpty(props.website) ? <br /> : null}
            {!isEmpty(props.email) ? (
                <span>
                    Need help? <a href={`mailto:${props.email}`} target="_blank" rel="noopener">Contact us</a>
                </span>
            ) : null}
        </div>
    );
};

interface ICustomizeReportPanelEmailPreviewHeader {
    logo: string;
    website: string;
    email: string;
    name: string;
    protocol: typeof HTTP;
    accentColor: string;
}

const CustomizeReportPanelEmailPreviewHeader: React.FC<ICustomizeReportPanelEmailPreviewHeader> = props => {
    const hasLogo = !isEmpty(props.logo);
    const hasWebsite = !isEmpty(props.website);
    const hasEmail = !isEmpty(props.email);

    const isSingleColumn = hasLogo && !hasWebsite && !hasEmail;

    const hasCustomizedData = hasLogo || hasWebsite || hasEmail;

    if (hasCustomizedData) {
        return (
            <div
                // eslint-disable-next-line max-len
                className={classnames(
                    'sw-customize-report-email-preview-header uk-flex uk-flex-middle mg-margin-b-30 mg-margin-t-30',
                    {
                        'uk-position-relative': true,
                        'uk-flex-center': isSingleColumn,
                        'uk-flex-space-between': !isSingleColumn,
                    },
                )}
            >
                <div
                    className="sw-customize-report-email-preview-header-top-border"
                    style={{
                        backgroundColor: !isEmpty(props.accentColor) ? props.accentColor : Colors.SERPWATCHER,
                    }}
                />
                <Logo website={props.website} protocol={props.protocol} logo={props.logo} />
                <Contact
                    website={props.website}
                    protocol={props.protocol}
                    name={props.name}
                    email={props.email}
                    logo={props.logo}
                    isSingleColumn={!isSingleColumn}
                />
            </div>
        );
    } else {
        return null;
    }
};

export { CustomizeReportPanelEmailPreviewHeader };
