const SortingColumns = {
    ESTIMATED_VISITS: 'estimatedVisits',
    ESTIMATED_VISITS_TOTAL: 'estimatedVisitsTotal',
    KEYWORD: 'keyword',
    RANK: 'rank',
    SERP_POSITION: 'serpPosition',
    RANK_AVG: 'rankAvg',
    RANK_BEST: 'rankBest',
    RANK_CHANGE: 'rankChange',
    SEARCH_VOLUME: 'searchVolume',
    SEARCH_COUNT: 'searchCount',
};

export default SortingColumns;
