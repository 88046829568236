// const PARSED_TRACKING_KEYWORD_EXAMPLE = {
//     id: '5899973f55169f4709858221',
//     keyword: 'keyword research tool',
//     rank: 8,
//     searchVolume: 6573,
//     estimatedVisits: 179,
//     url: 'https://kwfinder.com/',
//     rankHistory: [{
//         date: "2017-01-31",
//         rank: 12
//     }, {
//         date: "2017-02-01",
//         rank: 10
//     }]
// };

import {
    parseFloatWithNAFallback,
    parseIntWithNAFallback,
    parseIntWithNVFallback,
    parseStringWithNAFallback,
} from 'sources/parsers/commons';
import { propOr } from 'ramda';
/*
const parseRank = value => {
    if (value === -1) {
        return Values.RANK_MORE_THAN_100;
    } else {
        return parseIntWithNAFallback(value);
    }
};

const parseAvgRank = value => {
    if (value === -1) {
        return Values.RANK_MORE_THAN_100;
    } else {
        return parseFloatWithNAFallback(value);
    }
};
*/

const parseTags = rawTags =>
    rawTags.map(rawTag => ({
        color: rawTag.color,
        createdAt: rawTag.created_at,
        id: rawTag._id, // eslint-disable-line no-underscore-dangle
        name: rawTag.name,
    }));

const parseMSV = rawMVS =>
    rawMVS.map(item => ({
        count: parseInt(item[2], 10),
        month: parseInt(item[1], 10),
        year: parseInt(item[0], 10),
    }));

const parseVisualMetrics = visualMetrics => ({
    status: visualMetrics.status,
    serpCoverage: visualMetrics.serp_coverage,
    serpCoverageChange: parseFloatWithNAFallback(visualMetrics.serp_coverage_change),
    aboveTheFold: parseIntWithNAFallback(visualMetrics.above_the_fold),
    aboveTheFoldChange: parseFloatWithNAFallback(visualMetrics.above_the_fold_change),
    fromTopEdge: parseIntWithNAFallback(visualMetrics.from_top_edge),
    fromTopEdgeChange: parseFloatWithNAFallback(visualMetrics.from_top_edge_change),
});

export const parseDetail = rawKeyword => ({
    createdAt: rawKeyword.created_at,
    // eslint-disable-next-line no-underscore-dangle
    id: rawKeyword._id,
    keyword: rawKeyword.kw,
    lastCheckedAt: rawKeyword.last_checked_at,
    searchVolume: parseIntWithNVFallback(rawKeyword.search_volume),
    serpUrl: parseStringWithNAFallback(rawKeyword.serp_snapshot?.source_url),
    tags: parseTags(rawKeyword.tags),
    monthlySearchVolumes: parseMSV(propOr([], 'msv', rawKeyword)),
    serpSnapshotId: rawKeyword.serp_snapshot.id || '',
    items: rawKeyword.items.map(item => ({
        type: item.type,
        url: item.url,
        rank: {
            avg: parseIntWithNAFallback(item?.rank?.avg),
            last: parseFloatWithNAFallback(item?.rank?.last),
            best: parseIntWithNAFallback(item?.rank?.best),
            change: parseIntWithNAFallback(item?.rank?.change),
        },
        estimatedVisits: parseIntWithNAFallback(item?.estimated_visits),
        estimatedVisitsChange: parseIntWithNAFallback(item?.estimated_visits_change),
        rankHistory: item.rank_history,
        visualMetrics: parseVisualMetrics(item.visual_metrics),
    })),
});

export const parseAddKeywords = rawResponse => ({
    postponedProcessing: rawResponse.postponed_processing,
    estimatedTimeProcessing: rawResponse.estimatedTimeProcessing,
    data: {
        id: rawResponse.tracking._id, // eslint-disable-line no-underscore-dangle
        keywordIds: rawResponse.tracking.tracked_keyword_ids,
        keywords: rawResponse.keywords.map(keyword => ({
            id: keyword._id, // eslint-disable-line no-underscore-dangle
            keyword: keyword.kw,
        })),
    },
});

export const parseKeywords = rawKeywords => rawKeywords.map(rawKeyword => rawKeyword.kw);
