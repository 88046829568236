import update from 'immutability-helper';
import mergeRight from 'ramda/src/mergeRight';
import isNil from 'ramda/src/isNil';
import { REHYDRATE } from 'redux-persist/constants';

import ActionTypes from 'constants/ActionTypes';
import { OR } from 'constants/LogicOperators';
import MAP_PACK_RANKING_TYPES from 'constants/MapPackTypes';
import FEAT_SNIPPET_RANKING_TYPES from 'constants/FeaturedSnippetTypes';
import { DEFAULT } from 'constants/RankingUrls';

export const initialState = {
    settings: {
        active: false,
        excludedKW: [],
        excludedOperator: OR,
        includedKW: [],
        includedOperator: OR,
        rankFrom: 0,
        rankTo: null,
        searchVolumeFrom: 0,
        searchVolumeTo: null,
        estimatedVisitsFrom: 0,
        estimatedVisitsTo: null,
        urlFilter: '',
        mapPackRankFilter: MAP_PACK_RANKING_TYPES.DEFAULT,
        featSnippetRankFilter: FEAT_SNIPPET_RANKING_TYPES.DEFAULT,
        rankingUrlsFilter: DEFAULT,
    },
    quickSettings: {
        search: '',
        tagIds: [],
    },
    visibility: false,
};

const trackingFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_ALL_CLOSE: {
            return update(state, {
                visibility: {
                    $set: false,
                },
            });
        }
        case ActionTypes.UI_TRACKING_FILTER_VISIBILITY_TOGGLE: {
            return update(state, {
                visibility: {
                    $apply: visibility => !visibility,
                },
            });
        }
        case ActionTypes.UI_TRACKING_FILTER_ACTIVE_SET: {
            return update(state, {
                settings: {
                    active: {
                        $set: action.payload,
                    },
                },
            });
        }
        case ActionTypes.UI_TRACKING_FILTER_SETTINGS_SET: {
            return update(state, {
                settings: {
                    $set: mergeRight(action.payload, { active: true }),
                },
                visibility: {
                    $set: false,
                },
            });
        }
        case ActionTypes.UI_TRACKING_QUICK_FILTER_SETTINGS_SEARCH_SET: {
            return update(state, {
                quickSettings: {
                    search: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_TRACKING_FILTER_RESET: {
            return update(state, {
                settings: {
                    $set: initialState.settings,
                },
                visibility: {
                    $set: false,
                },
            });
        }
        case ActionTypes.UI_TRACKING_RESET: {
            return update(state, {
                settings: {
                    active: { $set: false },
                },
                quickSettings: {
                    search: { $set: '' },
                    tagIds: { $set: [] },
                },
                visibility: {
                    $set: false,
                },
            });
        }
        case ActionTypes.UI_TRACKING_QUICK_FILTER_SETTINGS_TAG_IDS_SET: {
            return update(state, {
                quickSettings: {
                    tagIds: { $set: action.payload },
                },
            });
        }
        case REHYDRATE: {
            const savedState = action.payload.ui;

            /* eslint-disable max-len */
            if (!isNil(savedState) && !isNil(savedState.trackingFilter)) {
                return update(state, {
                    settings: {
                        active: {
                            $set: false, // Filter is disabled by default
                        },
                        excludedKW: {
                            $set: savedState.trackingFilter.settings.excludedKW || initialState.settings.excludedKW,
                        },
                        excludedOperator: {
                            $set:
                                savedState.trackingFilter.settings.excludedOperator ||
                                initialState.settings.excludedOperator,
                        },
                        includedKW: {
                            $set: savedState.trackingFilter.settings.includedKW || initialState.settings.includedKW,
                        },
                        includedOperator: {
                            $set:
                                savedState.trackingFilter.settings.includedOperator ||
                                initialState.settings.includedOperator,
                        },
                        rankFrom: {
                            $set: savedState.trackingFilter.settings.rankFrom || initialState.settings.rankFrom,
                        },
                        rankTo: {
                            $set: savedState.trackingFilter.settings.rankTo || initialState.settings.rankTo,
                        },
                        searchVolumeFrom: {
                            $set:
                                savedState.trackingFilter.settings.searchVolumeFrom ||
                                initialState.settings.searchVolumeFrom,
                        },
                        searchVolumeTo: {
                            $set:
                                savedState.trackingFilter.settings.searchVolumeTo ||
                                initialState.settings.searchVolumeTo,
                        },
                        estimatedVisitsFrom: {
                            $set:
                                savedState.trackingFilter.settings.estimatedVisitsFrom ||
                                initialState.settings.estimatedVisitsFrom,
                        },
                        estimatedVisitsTo: {
                            $set:
                                savedState.trackingFilter.settings.estimatedVisitsTo ||
                                initialState.settings.estimatedVisitsTo,
                        },
                        urlFilter: {
                            $set: savedState.trackingFilter.settings.urlFilter || initialState.settings.urlFilter,
                        },
                        mapPackRankFilter: {
                            $set:
                                savedState.trackingFilter.settings.mapPackRankFilter ||
                                initialState.settings.mapPackRankFilter,
                        },
                        featSnippetRankFilter: {
                            $set:
                                savedState.trackingFilter.settings.featSnippetRankFilter ||
                                initialState.settings.featSnippetRankFilter,
                        },
                        rankingUrlsFilter: {
                            $set:
                                savedState.trackingFilter.settings.rankingUrlsFilter ||
                                initialState.settings.rankingUrlsFilter,
                        },
                    },
                });
            } else {
                return state;
            }
            /* eslint-enable max-len */
        }
        default: {
            return state;
        }
    }
};

export default trackingFilterReducer;
