import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import announcementsReducer from 'reducers/data/announcementsReducer';
import defaultsReducer from 'reducers/ui/defaultsReducer';
import dropdownsReducer from 'reducers/ui/dropdownsReducer';
import listsReducer from 'reducers/data/listsReducer';
import locationsReducer from 'reducers/data/locationsReducer';
import messagesReducer from 'reducers/ui/messagesReducer';
import miscReducer from 'reducers/ui/miscReducer';
import modalsReducer from 'reducers/ui/modalsReducer';
import newTrackingReducer from 'reducers/ui/newTrackingReducer';
import panelsReducer from 'reducers/ui/panelsReducer';
import reportsReducer from 'reducers/data/reportsReducer';
import trackingDetailReducer from 'reducers/data/trackingDetailReducer';
import trackingFilterReducer from 'reducers/ui/trackingFilterReducer';
import trackingKeywordDetailReducer from 'reducers/data/trackingKeywordDetailReducer';
import trackingListReducer from 'reducers/ui/trackingListReducer';
import trackingReducer from 'reducers/ui/trackingReducer';
import trackingsReducer from 'reducers/data/trackingsReducer';
import userReducer from 'reducers/userReducer';
import settingsReducer from './ui/settingsReducer';

const rootReducer = combineReducers({
    data: combineReducers({
        announcements: announcementsReducer,
        lists: listsReducer,
        locations: locationsReducer,
        reports: reportsReducer,
        trackingDetail: trackingDetailReducer,
        trackingKeywordDetail: trackingKeywordDetailReducer,
        trackings: trackingsReducer,
    }),
    routing: routerReducer,
    ui: combineReducers({
        defaults: defaultsReducer,
        dropdowns: dropdownsReducer,
        messages: messagesReducer,
        misc: miscReducer,
        modals: modalsReducer,
        newTracking: newTrackingReducer,
        panels: panelsReducer,
        tracking: trackingReducer,
        trackingFilter: trackingFilterReducer,
        trackingList: trackingListReducer,
        settings: settingsReducer,
    }),
    user: userReducer,
});

export default rootReducer;
