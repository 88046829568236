import AnnotationTypes from 'constants/AnnotationTypes';

import { parseFloatWithNAFallback, parseIntWithNAFallback, parseStringWithNAFallback } from 'sources/parsers/commons';

const parseType = rawType => {
    switch (rawType) {
        case 0: {
            return AnnotationTypes.USER;
        }
        case 1: {
            return AnnotationTypes.VOLATILITY;
        }
        case 2: {
            return AnnotationTypes.KW_CHANGE_ADD;
        }
        case 3: {
            return AnnotationTypes.KW_CHANGE_REMOVE;
        }
        case 4: {
            return AnnotationTypes.GOOGLE;
        }
        case 5: {
            return AnnotationTypes.SYSTEM;
        }
        default: {
            return AnnotationTypes.USER;
        }
    }
};

export const parseCreate = rawAnnotation => ({
    id: rawAnnotation._id, // eslint-disable-line no-underscore-dangle
    date: rawAnnotation.date,
    type: parseType(rawAnnotation.type),
    nonShareable: rawAnnotation.nonShareable,
    content: {
        avg: parseFloatWithNAFallback(rawAnnotation.content.avg),
        keywordsCount: parseIntWithNAFallback(rawAnnotation.content.keywords_count),
        text: parseStringWithNAFallback(rawAnnotation.content.text),
    },
});
