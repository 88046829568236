import React from 'react';
import { bool, func, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MessageHolder from 'components/messages/MessageHolder';

const InvalidShareTokenMessage = props => {
    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose}>
                <div className="mg-modal-header is-error">
                    <FontAwesomeIcon icon="ban" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold">{props.header}</h3>
                    <p>
                        The share token has probably expired. If you want to see the report, ask for a new link from
                        person who provides you this report.
                    </p>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
};

InvalidShareTokenMessage.propTypes = {
    header: string,
    onClose: func.isRequired,
    visible: bool.isRequired,
};

InvalidShareTokenMessage.defaultProps = {
    details: null,
    header: 'Sorry, your share token is invalid',
};

export default InvalidShareTokenMessage;
