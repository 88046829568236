import { createSelector } from 'reselect';
import concat from 'ramda/src/concat';

export const newTrackingKeywordsInLimitSelector = state => state.ui.newTracking.keywordsInLimit;
export const newTrackingKeywordsNotInLimitSelector = state => state.ui.newTracking.keywordsNotInLimit;
export const newTrackingConfigDataSelector = state => state.ui.newTracking.trackingConfig;

export const newTrackingKeywordsSelector = createSelector(
    [newTrackingKeywordsInLimitSelector, newTrackingKeywordsNotInLimitSelector],
    (keywordsInLimit, keywordsNotInLimit) => concat(keywordsInLimit, keywordsNotInLimit),
);
