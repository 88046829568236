// const PARSED_TRACKING_TAGS_EXAMPLE = [{
//      color: 'blue',
//      id: 'an2q3n312n312n13n312n',
//      name: 'branding'
// }];

export const parse = rawTags =>
    rawTags.map(item => ({
        color: item.color,
        createdAt: item.created_at,
        id: item._id, // eslint-disable-line no-underscore-dangle
        name: item.name,
    }));

export const parseCreate = rawTag => ({
    color: rawTag.color,
    createdAt: rawTag.created_at,
    id: rawTag._id, // eslint-disable-line no-underscore-dangle
    name: rawTag.name,
});
