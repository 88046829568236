import React, { Component } from 'react';
import { bool, element, func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Alert from 'react-s-alert';
import isNil from 'ramda/src/isNil';
import { GlobalHotKeys } from 'react-hotkeys';
import ShortcutService from 'mangools-commons/lib/services/ShortcutService';

import Overlay from 'components/layout/Overlay';
import MessageContainer from 'components/messages/MessageContainer';
import ModalContainer from 'components/modals/ModalContainer';
import NavbarContainer from 'components/layout/navbar/NavbarContainer';
import PanelContainer from 'components/panels/PanelContainer';
import TopLevelMessageContainer from 'components/messages/topLevel/TopLevelMessageContainer';

import { closeAll, resetTrackingKeywordDetail, showShortcutsMessage, closeShortcutsMessage } from 'actions/uiActions';

import RouterService from 'services/RouterService';

import RoutePaths from 'constants/RoutePaths';
import shortcuts, { CLOSE_ALL, SHOW_SHORTCUTS_TOGGLE } from 'constants/Shortcuts';

import {
    mobileOverlayVisibilitySelector,
    onlineStatusSelector,
    overlayVisibilitySelector,
    shortcutsMessageVisibilitySelector,
    trackingCurrentKeywordDetailSelector,
    areShortcutsDisabledSelector,
} from 'selectors/uiSelectors';

import {
    announcementMesageVisibilitySelector,
    isTrackingDetailWhitelabelLogoActiveSelector,
} from 'selectors/dataSelectors';

import { newUserDiscountActiveSelector } from 'selectors/userSelectors';

/**
 * Application layout component.
 */
class LayoutContainer extends Component {
    constructor(props) {
        super(props);
        this.isReport = this.isReport.bind(this);
        this.handleToggleShortucts = this.handleToggleShortucts.bind(this);
    }

    handleToggleShortucts() {
        const { shortcutsMessageVisible, onShowShortcutsMessage, onCloseShortcutsMessage } = this.props;

        if (shortcutsMessageVisible) {
            onCloseShortcutsMessage();
        } else {
            onShowShortcutsMessage();
        }
    }

    isReport() {
        return RouterService.isSame(this.props.location.pathname, RoutePaths.REPORT);
    }

    render() {
        const appClasses = classnames({
            'is-offline': this.props.onlineStatus === false,
            'is-report': this.isReport(),
            'is-report-with-logo': this.isReport() && this.props.isWhiteLabelLogoActive,
            'serpwatcher-app': true,
            'is-messaged': this.props.topLevelMessageVisible,
        });

        const { areShortcutsDisabled } = this.props;
        const { keyMap, handlers } = ShortcutService.getShortcutMapAndHandlers({
            handlers: {
                [CLOSE_ALL]: { func: this.props.onCloseAll, preventDisable: true },
                [SHOW_SHORTCUTS_TOGGLE]: { func: this.handleToggleShortucts },
            },
            areShortcutsDisabled,
            shortcuts,
        });

        return (
            <GlobalHotKeys allowChanges keyMap={keyMap} handlers={handlers}>
                <div className={appClasses}>
                    <TopLevelMessageContainer />
                    <NavbarContainer currentRoute={this.props.location.pathname} />
                    {this.props.children}
                    <PanelContainer />
                    <ModalContainer />
                    <MessageContainer currentRoute={this.props.location.pathname} />
                    <Overlay mobile={false} onClick={this.props.onCloseAll} visibility={this.props.overlayVisibility} />
                    <Overlay mobile onClick={this.props.onCloseAll} visibility={this.props.mobileOverlayVisibility} />
                    <Overlay
                        mobile
                        onClick={this.props.onResetKeywordDetail}
                        visibility={!isNil(this.props.currentTrackingKeywordDetailId)}
                    />
                    <div className="uk-hidden-small">
                        <Alert effect="slide" offset={50} position="top-right" stack={{ limit: 3 }} timeout={3500} />
                    </div>
                </div>
            </GlobalHotKeys>
        );
    }
}

LayoutContainer.propTypes = {
    children: element.isRequired,
    currentTrackingKeywordDetailId: string,
    location: shape({ pathname: string }).isRequired,
    mobileOverlayVisibility: bool.isRequired,
    onCloseAll: func.isRequired,
    onResetKeywordDetail: func.isRequired,
    onlineStatus: bool.isRequired,
    overlayVisibility: bool.isRequired,
    onCloseShortcutsMessage: func.isRequired,
    onShowShortcutsMessage: func.isRequired,
    shortcutsMessageVisible: bool.isRequired,
    topLevelMessageVisible: bool.isRequired,
    areShortcutsDisabled: bool.isRequired,
    isWhiteLabelLogoActive: bool.isRequired,
};

const mapStateToProps = state => ({
    currentTrackingKeywordDetailId: trackingCurrentKeywordDetailSelector(state),
    mobileOverlayVisibility: mobileOverlayVisibilitySelector(state),
    onlineStatus: onlineStatusSelector(state),
    overlayVisibility: overlayVisibilitySelector(state),
    shortcutsMessageVisible: shortcutsMessageVisibilitySelector(state),
    topLevelMessageVisible: newUserDiscountActiveSelector(state) || announcementMesageVisibilitySelector(state),
    areShortcutsDisabled: areShortcutsDisabledSelector(state),
    isWhiteLabelLogoActive: isTrackingDetailWhitelabelLogoActiveSelector(state),
});

const mapDispatchToProps = dispatch => ({
    onCloseAll() {
        dispatch(closeAll());
    },
    onCloseShortcutsMessage() {
        dispatch(closeShortcutsMessage());
    },
    onResetKeywordDetail() {
        dispatch(resetTrackingKeywordDetail());
    },
    onShowShortcutsMessage() {
        dispatch(showShortcutsMessage());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);
