export const ALERT = {
    id: 0,
    label: 'Alert',
    name: 'ALERT',
};

export const WEEKLY = {
    id: 1,
    label: 'Weekly',
    name: 'WEEKLY',
};

export const MONTHLY = {
    id: 2,
    label: 'Monthly',
    name: 'MONTHLY',
};

const ReportTypes = [ALERT, MONTHLY, WEEKLY];
export default ReportTypes;
