import update from 'immutability-helper';
import concat from 'ramda/src/concat';
import includes from 'ramda/src/includes';

import TimeframeService from 'services/TimeframeService';
import ActionTypes from 'constants/ActionTypes';

const defaultTimeframe = TimeframeService.getDefault();

const initialState = {
    annotationDeletingId: null,
    annotationUpdatingId: null,
    currentKeywordDetailId: null,
    modifyingKeywords: false,
    selectedKeywordIds: [],
    timeframe: {
        from: defaultTimeframe.from,
        to: defaultTimeframe.to,
        type: defaultTimeframe.type,
    },
    unassigningTagId: null,
};

const trackingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_FETCHING:
        case ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_SELECTED_FETCHING: {
            return update(state, {
                modifyingKeywords: { $set: true },
            });
        }
        case ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_ERROR:
        case ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_SELECTED_ERROR: {
            return update(state, {
                modifyingKeywords: { $set: false },
            });
        }
        case ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_RECEIVED: {
            if (state.currentKeywordDetailId === action.payload.keywordId) {
                return update(state, {
                    currentKeywordDetailId: { $set: null },
                    modifyingKeywords: { $set: false },
                    selectedKeywordIds: {
                        $apply: ids => ids.filter(id => id !== action.payload.keywordId),
                    },
                });
            } else {
                return update(state, {
                    modifyingKeywords: { $set: false },
                    selectedKeywordIds: {
                        $apply: ids => ids.filter(id => id !== action.payload.keywordId),
                    },
                });
            }
        }
        case ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_SELECTED_RECEIVED: {
            if (includes(state.currentKeywordDetailId, action.payload.keywordIds)) {
                return update(state, {
                    currentKeywordDetailId: { $set: null },
                    modifyingKeywords: { $set: false },
                });
            } else {
                return update(state, {
                    modifyingKeywords: { $set: false },
                });
            }
        }
        case ActionTypes.UI_TRACKING_TIMEFRAME_SET: {
            return update(state, {
                timeframe: {
                    from: { $set: action.payload.from },
                    to: { $set: action.payload.to },
                    type: { $set: action.payload.type },
                },
            });
        }
        case ActionTypes.UI_TRACKING_KEYWORDS_SET: {
            return update(state, {
                selectedKeywordIds: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.UI_TRACKING_KEYWORDS_SELECT: {
            return update(state, {
                selectedKeywordIds: {
                    $apply: ids => {
                        const newIds = action.payload.filter(keywordId => !ids.includes(keywordId));

                        if (newIds.length === 0) {
                            return ids;
                        } else {
                            return concat(ids, action.payload);
                        }
                    },
                },
            });
        }
        case ActionTypes.UI_TRACKING_KEYWORDS_UNSELECT: {
            return update(state, {
                selectedKeywordIds: {
                    $apply: ids => ids.filter(id => !action.payload.includes(id)),
                },
            });
        }
        case ActionTypes.UI_TRACKING_KEYWORDS_UNSELECT_ALL: {
            return update(state, {
                selectedKeywordIds: {
                    $set: [],
                },
            });
        }
        case ActionTypes.UI_TRACKING_KEYWORD_DETAIL_SHOW: {
            return update(state, {
                currentKeywordDetailId: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.UI_TRACKING_KEYWORD_DETAIL_RESET: {
            return update(state, {
                currentKeywordDetailId: { $set: null },
            });
        }
        case ActionTypes.UI_TRACKING_KEYWORD_DETAIL_CLOSE: {
            if (state.currentKeywordDetailId === action.payload) {
                return update(state, {
                    currentKeywordDetailId: {
                        $set: null,
                    },
                });
            } else {
                return state;
            }
        }
        case ActionTypes.UI_TRACKING_RESET: {
            return initialState;
        }
        case ActionTypes.DATA_TRACKING_TAGS_UNASSIGN_FETCHING: {
            return update(state, {
                unassigningTagId: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_UNASSIGN_RECEIVED: {
            return update(state, {
                unassigningTagId: {
                    $set: null,
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_UNASSIGN_ERROR: {
            return update(state, {
                unassigningTagId: {
                    $set: null,
                },
            });
        }
        case ActionTypes.UI_TRACKING_ANNOTATION_DELETING_ID_SET: {
            return update(state, {
                annotationDeletingId: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.UI_TRACKING_REPORT_ACTIVE_SET: {
            return update(state, {
                reportActive: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.UI_TRACKING_ANNOTATION_UPDATING_ID_SET: {
            return update(state, {
                annotationUpdatingId: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.DATA_TRACKING_ANNOTATIONS_DELETE_RECEIVED:
        case ActionTypes.DATA_TRACKING_ANNOTATIONS_DELETE_ERROR: {
            return update(state, {
                annotationDeletingId: {
                    $set: null,
                },
            });
        }
        case ActionTypes.DATA_TRACKING_ANNOTATIONS_UPDATE_RECEIVED:
        case ActionTypes.DATA_TRACKING_ANNOTATIONS_UPDATE_ERROR: {
            return update(state, {
                annotationUpdatingId: {
                    $set: null,
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default trackingReducer;
