export const userEventNames = {
    SHOWN_SUGGESTED_KEYWORDS_BANNER: 'sw_new_tracking_suggested_keywords_banner_shown',
    CLICK_SUGGESTED_KEYWORDS_BANNER: 'sw_new_tracking_suggested_keywords_banner_click',
    IMPORT_KWF_LIST: 'sw_new_tracking_import_kwf_list',
    ADD_SUGGESTED_KWS: 'sw_new_tracking_add_suggested_kws',
    SW_SHARE_TRACKING: 'sw_share_tracking',
    SW_SERP_PREVIEW: 'sw_serp_preview',
    SW_NEED_MORE_KEYWORDS: 'sw_need_more_keywords',
    SW_NEED_MORE_KEYWORDS_MSG_RECEIVED: 'sw_need_more_keywords_msg_received'
};
