import { getDetails } from "use-places-autocomplete";
import { useDispatch } from 'react-redux';
import { KeyedLiteral } from 'types/KeyedLiteral';
import { errorGMBDataAction } from '../actions/dataActions';

export const useGPlaceDetail = () => {
    const dispatch = useDispatch();

    const getGPlaceDetails = (currentPlaceId: string, onReceived: (details: GPlaceDetails) => void) => {
        getDetails({ placeId: currentPlaceId })
            .then(onReceived)
            .catch((error) => {
                dispatch(errorGMBDataAction({ error }));
            }
            );
    }

    return { getGPlaceDetails };
}

export interface GPlaceDetails {
    geometry: KeyedLiteral;
    name: string;
    address: string;
    formatted_address: string;
    url: string;
}