import NumberOrNAType from 'types/commons/NumberOrNAType';

import { string, oneOfType, shape, bool, number } from 'prop-types';

export interface FeaturedSnippet {
    serpRank: number | null;
    url: string | null;
    isRanking: boolean;
    estimatedVisits: number | null;
}

export const FeaturedSnippetPropType = shape({
    serpRank: NumberOrNAType.isRequired,
    url: oneOfType([string, null]).isRequired,
    isRanking: bool.isRequired,
    estimatedVisits: oneOfType([number, null]).isRequired,
});
