import React from 'react';
import classNames from 'classnames';

const Emoji = ({ label, symbol, className }: EmojiProps) => {
    return (
        <span
            className={classNames("emoji", className)}
            role="img"
            aria-label={label ? label : ''}
            aria-hidden={label ? 'false' : 'true'}
        >
            {symbol}
        </span>
    );
}

interface EmojiProps {
    label: string;
    symbol: string;
    className?: string;
}

export default Emoji;
