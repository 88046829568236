import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    shareModal: {
        visibility: false,
    },
    serpModal: {
        visibility: false,
    },
    createdTrackingTips: {
        visibleTip: null,
    },
};

const modalsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_MODALS_SERP_MODAL_SHOW: {
            return update(state, {
                serpModal: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MODALS_SERP_MODAL_CLOSE: {
            return update(state, {
                serpModal: {
                    visibility: {
                        $set: false,
                    },
                    keyword: {
                        $set: '',
                    },
                    url: {
                        $set: null,
                    },
                },
            });
        }
        case ActionTypes.UI_MODALS_SHARE_MODAL_SHOW: {
            return update(state, {
                shareModal: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MODALS_SHARE_MODAL_CLOSE: {
            return update(state, {
                shareModal: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MODALS_CREATED_TRACKING_TIPS_MODAL_SHOW: {
            return update(state, {
                createdTrackingTips: {
                    visibleTip: {
                        $set: action.payload || null,
                    },
                },
            });
        }
        case ActionTypes.UI_MODALS_CREATED_TRACKING_TIPS_MODAL_CLOSE: {
            return update(state, {
                createdTrackingTips: {
                    visibleTip: {
                        $set: null,
                    },
                },
            });
        }
        case ActionTypes.UI_ALL_CLOSE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default modalsReducer;
