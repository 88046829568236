import React from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { showCustomizeReportPanel } from 'actions/uiActions';

import labelVideoMp4 from 'videos/white-label.mp4';
import labelVideoWebm from 'videos/white-label.webm';
import TipsVideo from 'components/newTracking/step/finish/TipsVideo';

const WhiteLabelLayout = () => {
    const dispatch = useDispatch();

    const handleShowCustPanel = () => dispatch(showCustomizeReportPanel());

    return (
        <div className="uk-text-left uk-flex uk-flex-column">
            <div className="uk-flex uk-flex-column uk-flex-center">
                <div className="uk-width-1-1 uk-flex uk-flex-column">
                    <div className=" uk-margin-auto">
                        <div className="uk-flex mg-alert is-info is-big">
                            <FontAwesomeIcon
                                icon={['far', 'lightbulb']}
                                aria-label="Tip"
                                className="mg-margin-r-15 mg-margin-t-5 uk-hidden-small uk-position-relative font-36"
                            />
                            <div className="font-14 uk-flex uk-flex-column">
                                <h3 className="mg-margin-b-5 uk-text-bold">Set up a customized report</h3>
                                <p className="uk-margin-small">
                                    White label is a great feature that helps you boost your brand&apos;s visibility and
                                    strengthen the loyalty of clients. This can be done by adding the company logo,
                                    color, website, or email to your reports.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-flex uk-flex-wrap mg-margin-t-45 mg-margin-b-30">
                    <div className="uk-width-medium-1-2 uk-wdith">
                        <TipsVideo VideoMp4={labelVideoMp4} VideoWebm={labelVideoWebm} />
                    </div>
                    <div className="uk-width-medium-1-2 mg-padding-15 uk-padding-bottom-remove uk-wdith font-14">
                        <p className="uk-margin-small">1.Open the Whitelabel settings.</p>
                        <p className="uk-margin-small">2.Configure the following settings:</p>
                        <ul className="mg-margin-b-10 mg-margin-t-10">
                            <li>Company logo</li>
                            <li>Accent color</li>
                            <li>Company name</li>
                            <li>Company website</li>
                            <li>Company email</li>
                        </ul>
                        <p className="uk-margin-small">
                            You can also preview both the online report and the email report before you submit the
                            changes.
                        </p>
                    </div>
                </div>
            </div>
            <hr className="mg-margin-b-30" />
            <p className="uk-width-1-1 uk-flex uk-flex-center">
                <button type="button" onClick={handleShowCustPanel} className="mg-btn is-small is-green uk-width-2-3">
                    Customize White label settings
                </button>
            </p>
        </div>
    );
};

export default WhiteLabelLayout;
