// const PARSED_TRACKINGS_EXAMPLE = [{
//     createdAt: DATE,
//     domain: 'kwfinder.com',
//     location: {
//         id: 0,
//         code: null,
//         label: 'Anywhere'
//     },
//     id: '2s3a1hbjd123asd31',
//     platformId: 1,
//     timeframes: {
//         '2017-02-06': {
//             estimatedVisits: 205,
//             performanceIndex: 10.2312
//         },
//         '2017-02-05': {
//             estimatedVisits: 150,
//             performanceIndex: 8.2101
//         }
//     },
// }];

import map from 'ramda/src/map';
import RankDistributionScales from 'constants/RankDistributionScales';
import AnnotationTypes from 'constants/AnnotationTypes';
import Values from 'constants/Values';

import { isEmpty, isNil, propOr } from 'ramda';
import {
    parseFloatWithNAFallback,
    parseFloatWithNVFallback,
    parseIntWithNAFallback,
    parseStringWithNAFallback,
    parseIntWithNVFallback,
} from 'sources/parsers/commons';

const parseStats = rawStats => {
    if (!isNil(rawStats) && !isEmpty(rawStats)) {
        return {
            timeframes: map(
                tf => ({
                    estimatedVisits: parseIntWithNVFallback(tf.estimated_visits),
                    performanceIndex: parseFloatWithNAFallback(tf.performance_index),
                    visibilityIndex: parseFloatWithNAFallback(tf.visibility_index),
                    rankDistribution: {
                        [RankDistributionScales.TOP]: parseIntWithNAFallback(tf.rank_distribution['1']),
                        [RankDistributionScales.TOP_3]: parseIntWithNAFallback(tf.rank_distribution['3']),
                        [RankDistributionScales.TOP_10]: parseIntWithNAFallback(tf.rank_distribution['10']),
                        [RankDistributionScales.TOP_20]: parseIntWithNAFallback(tf.rank_distribution['20']),
                        [RankDistributionScales.TOP_100]: parseIntWithNAFallback(tf.rank_distribution['100']),
                        [RankDistributionScales.REST]: parseIntWithNAFallback(tf.rank_distribution.rest),
                    },
                }),
                rawStats.timeframes,
            ),
        };
    } else {
        return null;
    }
};

const parseAnnotationType = rawType => {
    switch (rawType) {
        case 0: {
            return AnnotationTypes.USER;
        }
        case 1: {
            return AnnotationTypes.VOLATILITY;
        }
        case 2: {
            return AnnotationTypes.KW_CHANGE_ADD;
        }
        case 3: {
            return AnnotationTypes.KW_CHANGE_REMOVE;
        }
        case 4: {
            return AnnotationTypes.GOOGLE;
        }
        case 5: {
            return AnnotationTypes.SYSTEM;
        }
        case 6: {
            return AnnotationTypes.KW_CHANGE_RESTORED;
        }
        default: {
            return AnnotationTypes.USER;
        }
    }
};

const parseAvgRank = value => {
    if (value === -1) {
        return Values.RANK_MORE_THAN_100;
    } else {
        return parseFloatWithNAFallback(value);
    }
};

const parseRank = value => {
    if (value === -1) {
        return Values.RANK_MORE_THAN_100;
    } else {
        return parseIntWithNAFallback(value);
    }
};

const parseMapPack = mapPack => ({
    rank: mapPack?.rank || null,
    hasUrl: !!mapPack?.hasUrl,
});

const parseFeaturedSnippet = featuredSnippet => ({
    serpRank: featuredSnippet?.serpRank || null,
    url: featuredSnippet?.url,
    isRanking: featuredSnippet?.isRanking,
});

const parseVisualMetrics = visualMetrics => ({
    status: visualMetrics.status,
    serpCoverage: visualMetrics.serp_coverage,
    serpCoverageChange: parseFloatWithNAFallback(visualMetrics.serp_coverage_change),
    aboveTheFold: parseIntWithNAFallback(visualMetrics.above_the_fold),
    aboveTheFoldChange: parseFloatWithNAFallback(visualMetrics.above_the_fold_change),
    fromTopEdge: parseIntWithNAFallback(visualMetrics.from_top_edge),
    fromTopEdgeChange: parseFloatWithNAFallback(visualMetrics.from_top_edge_change),
});

const parsekeywordsStatsMap = rawKeywords => {
    if (rawKeywords) {
        return rawKeywords.reduce(
            (acc, keyword) => ({
                ...acc,
                // eslint-disable-next-line no-underscore-dangle
                [keyword._id]: {
                    performanceIndexChange: parseFloatWithNVFallback(keyword.performanceIndexChange),
                    estimatedVisitsChange: parseIntWithNVFallback(keyword.estimated_visits_change),
                    estimatedVisits: parseIntWithNVFallback(keyword.estimated_visits),
                    rankChange: parseIntWithNAFallback(keyword.rank_change),
                    rankHistory: keyword.rank_history.map(value => parseRank(value)),
                    rank: parseRank(keyword.rank.last),
                    rankAvg: parseAvgRank(keyword.rank.avg),
                    rankBest: parseRank(keyword.rank.best),
                    mapPack: parseMapPack(keyword.map_pack),
                    estimatedVisitsTotal: keyword.estimated_visits,
                    featuredSnippet: parseFeaturedSnippet(keyword.feat_snippet),
                    searchVolume: parseIntWithNVFallback(keyword.search_volume),
                    isMoreUrl: keyword.is_more_url,
                    estimatedVisitsTotalChange: keyword.estimated_visits_total_change,
                    visualMetrics: parseVisualMetrics(keyword.visual_metrics),
                    isEmptyMainArray: keyword.is_empty_main_array,
                    nearestBeforeDay: keyword.nearest_before_day,
                    serpFeatures: propOr([], 'serp_features', keyword),
                },
            }),
            {},
        );
    }

    return {};
};

const parseAnnotations = rawAnnotations =>
    rawAnnotations.map(annotation => ({
        id: annotation._id, // eslint-disable-line no-underscore-dangle
        date: annotation.date,
        type: parseAnnotationType(annotation.type),
        nonShareable: annotation.nonShareable,
        content: {
            avg: parseFloatWithNAFallback(annotation.content.avg),
            keywordsCount: parseIntWithNAFallback(annotation.content.keywords_count),
            text: parseStringWithNAFallback(annotation.content.text),
        },
    }));

export const parseDetail = rawTracking => ({
    annotations: parseAnnotations(rawTracking.annotations),
    keywordsStatsMap: parsekeywordsStatsMap(rawTracking.keywords),
    stats: parseStats(rawTracking.stats),
    timeframePoints: rawTracking.history_dates,
});

export const parseUpdate = rawResponse => ({
    tracking: {
        domain: rawResponse.tracking.domain,
        id: rawResponse.tracking._id, // eslint-disable-line no-underscore-dangle
    },
    annotation: {
        id: rawResponse.annotation._id, // eslint-disable-line no-underscore-dangle
        date: rawResponse.annotation.date,
        type: parseAnnotationType(rawResponse.annotation.type),
        nonShareable: rawResponse.annotation.nonShareable,
        content: {
            avg: parseFloatWithNAFallback(rawResponse.annotation.content.avg),
            keywordsCount: parseIntWithNAFallback(rawResponse.annotation.content.keywords_count),
            text: parseStringWithNAFallback(rawResponse.annotation.content.text),
        },
    },
});

export const parseShareTokenUpdate = rawResponse => ({
    shareToken: rawResponse.share_token,
});
