// String.xml like to have all UI texts in one place.
// Will help when I18n comes to mind, or just to ensure same jargon in whole app.

// TODO: migrate all string from APP here!
const Strings = {
    components: {
        common: {
            loading: 'Preparing your drink...',
        },
        other: {
            preloader_notification: {
                body: 'Looks like SERP results are taking more time to load, please wait...',
            },
        },
    },
    messages: {
        failure: {
            add_keywords_error: 'There was an error while adding keywords to tracking.',
            assign_tags: 'There was an error while assigning tags to keywords.',
            create_annotation: 'There was an error while creating an annotation.',
            create_list_error: 'There was an error while creating new list.',
            create_report_error: 'There was an error while creating new report.',
            create_tag: 'There was an error while creating tag.',
            create_tracking_error: 'There was an error while creating new tracking.',
            delete_annotation: 'There was an error while deleting an annotation.',
            delete_report_error: 'There was an error while deleting a report.',
            delete_selected_tracking_keywords_error: 'There was an error while deleting keywords from tracking.',
            delete_tag: 'There was an error while deleting a tag.',
            delete_tracking_error: 'There was an error while deleting a tracking.',
            delete_tracking_keyword_error: 'There was an error while deleting a keyword from tracking.',
            fetch_suggestions_error: 'There was an error while fetching suggestions data.',
            download_error: 'Sorry, download is not working in Your browser. Try Chrome or Firefox.',
            export_error: 'There was an error while exporting Your data.',
            fetch_google_results_error: 'There was an error while fetching google autocomplete data.',
            fetch_history_error: 'There was an error while fetching requests history data.',
            fetch_limits_error: 'There was an error while fetching your account data.',
            fetch_list_detail_error: 'There was an error while fetching list detail data.',
            fetch_lists_error: 'There was and error while fetching your KWFinder lists.',
            fetch_lists_keywords_error: 'There was an error whiel fetching keywords from selected KWFinder lists.',
            fetch_locations_error: 'There was an error while fetching locations data.',
            fetch_reports: 'There was an error while fetching reports data.',
            fetch_results_error: 'There was an error while fetching related keywords data.',
            fetch_serp_results_error: 'There was an error while fetching SERP data.',
            fetch_tags: 'There was an error while fetching this tracking tags data.',
            fetch_tracking: 'There was an error while fetching this tracking data.',
            fetch_tracking_keyword: 'There was an error while fetching keyword detail data.',
            fetch_trackings: 'There was an error while fetching your trackings.',
            fetch_trackings_invalid_share_token: 'Your share token is broken or expired',
            fetch_gmb_data_error: 'There was an error while fetching Google My Business data.',
            fill_new_tracking_keywords: 'There was an error while fetching keywords from the cloned tracking.',
            fetch_suggested_keywords_error: 'There was an error while fetching the suggested keywords.',
            footer_sorry: 'We are so sorry, try again later, please.',
            import_error: 'There was an error while importing Your keywords.',
            logout_error: 'There was an error while trying to log You out.',
            maintenance: 'Sorry, our services are undergoing a maintenance.',
            new_tracking_clone: 'There was an error while cloning your tracking.',
            show_list_data_error: 'There was an error while fetching keywords from Your list.',
            show_list_data_not_found_error: 'Requested list does not exists.',
            too_many_requests_error: 'Sorry, you exceeded maximum allowed API requests per second.',
            tracking_not_found_error: 'Tracking was not found. It was probably deleted.',
            unassign_tags: 'There was an error while removing tags from keywords.',
            update_annotation: 'There was an error while updating Your annotation.',
            update_list_error: 'There was an error while updating Your list.',
            update_tag_color: 'There was an error while updating your tag color.',
            update_tag_name: 'There was an error while updating your tag name.',
            download_snapshot: 'There was an error while downloading your SERP snapshot.',
            update_tracking_domain: 'There was an error while updating the domain of your tracking.',
            update_tracking_share_token: 'There was an error while creating your new share token.',
            fetch_kwfinder_list_detail: 'There was an error while fetching list data',
            update_whitelabel_settings_error: 'There was an error while trying to update Your settings.',
            toggle_whitelabel_settings_error: 'There was an error while trying to toggle whitelabel.',
            restore_tracking_error: 'There was an error while restoring a tracking.',
            update_customize_results_settings_error: 'There was an error while trying to update your settings.',
        },
    },
};

export default Strings;
