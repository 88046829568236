export const DEFAULT = {
    id: 0,
    label: 'No preference',
    value: 'DEFAULT',
};

export const ONE_URL_ONLY = {
    id: 1,
    label: `One URL only`,
    value: 'ONE_URL_ONLY',
};

export const MULTIPLE_URLS_ONLY = {
    id: 1,
    label: `Multiple URLs only`,
    value: 'MULTIPLE_URLS_ONLY',
};

export const RANKING_URLS_OPTIONS = [DEFAULT, ONE_URL_ONLY, MULTIPLE_URLS_ONLY];

export default {
    DEFAULT: DEFAULT.value,
    ONE_URL_ONLY: ONE_URL_ONLY.value,
    MULTIPLE_URLS_ONLY: MULTIPLE_URLS_ONLY.value,
};
