import React, { Component } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import isNil from 'ramda/src/isNil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MessageHolder from 'components/messages/MessageHolder';
import AnnotationsMessageItem from 'components/messages/annotationsMessage/AnnotationsMessageItem';
import AnnotationsMessageNewForm from 'components/messages/annotationsMessage/AnnotationsMessageNewForm';

import DateFormatService from 'services/DateFormatService';
import AnnotationType from 'types/AnnotationType';

class AnnotationsMessage extends Component {
    constructor() {
        super();

        this.handleAnnotationDelete = this.handleAnnotationDelete.bind(this);
        this.handleAnnotationEdit = this.handleAnnotationEdit.bind(this);
        this.handleNewAnnotationFormSubmit = this.handleNewAnnotationFormSubmit.bind(this);
        this.renderAnnotations = this.renderAnnotations.bind(this);
    }

    handleNewAnnotationFormSubmit(text, nonShareable) {
        this.props.onCreate({ annotationText: text, date: this.props.visibleDate, nonShareable });
    }

    handleAnnotationDelete(id) {
        this.props.onDelete(id);
    }

    handleAnnotationEdit({ id, text, nonShareable }) {
        this.props.onUpdate({ annotationId: id, annotationText: text, nonShareable });
    }

    renderAnnotations() {
        if (this.props.data.length > 0) {
            return this.props.data.map(annotation => (
                <AnnotationsMessageItem
                    content={annotation.content}
                    deleting={this.props.deletingId === annotation.id}
                    id={annotation.id}
                    key={annotation.id}
                    nonShareable={annotation.nonShareable}
                    onDelete={this.handleAnnotationDelete}
                    onEdit={this.handleAnnotationEdit}
                    report={this.props.report}
                    type={annotation.type}
                    updating={this.props.updatingId === annotation.id}
                />
            ));
        } else {
            return null;
        }
    }

    render() {
        if (!isNil(this.props.visibleDate)) {
            return (
                <MessageHolder onClose={this.props.onClose}>
                    <div className="mg-modal-header is-info">
                        <FontAwesomeIcon icon="comment" aria-label="Comments" />
                    </div>
                    <div className="mg-modal-content">
                        <h3 className="font-30 uk-text-bold">
                            {DateFormatService.formatVeryLong(this.props.visibleDate)}
                        </h3>
                        {this.renderAnnotations()}
                        {this.props.report === false ? (
                            <AnnotationsMessageNewForm
                                fetching={this.props.fetchingCreate}
                                onSubmit={this.handleNewAnnotationFormSubmit}
                            />
                        ) : null}
                    </div>
                </MessageHolder>
            );
        } else {
            return null;
        }
    }
}

AnnotationsMessage.propTypes = {
    data: arrayOf(AnnotationType).isRequired,
    deletingId: string,
    fetchingCreate: bool.isRequired,
    onClose: func.isRequired,
    onCreate: func.isRequired,
    onDelete: func.isRequired,
    onUpdate: func.isRequired,
    report: bool.isRequired,
    updatingId: string,
    visibleDate: string,
};

export default AnnotationsMessage;
