import isFQDN from 'validator/lib/isFQDN';
import queryString from 'query-string';

const GOOGLE_FAVICON_API_BASE_URL = 'https://plus.google.com/_/favicon';

class TrackingDomainService {
    static validate(domain) {
        return isFQDN(domain);
    }

    static generateFaviconUrl({ domain }) {
        const query = queryString.stringify({ domain });
        return `${GOOGLE_FAVICON_API_BASE_URL}?${query}`;
    }
}

export default TrackingDomainService;
