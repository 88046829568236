import ErrorCodes from 'mangools-commons/lib/constants/ErrorCodes';
import { SourceService } from 'mangools-commons/lib/services/SourceService';

import config from 'appConfig';
import { parseIndex, parseDetail } from 'sources/parsers/ListDataParser';

// Constants
const ENDPOINT = '/lists';

/**
 * Data source class handling communication with API for keyword lists data.
 */
class ListSource extends SourceService {
    static getData(accessToken) {
        const requestURL = `${config.API_HOST}${config.KWFINDER_API_PATH}${ENDPOINT}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseIndex(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static getDetail({ listId, accessToken }) {
        const request = ListSource.createRequest({
            method: 'GET',
            parse: parseDetail,
        });

        return request({
            requestURL: `${config.API_HOST}${config.KWFINDER_API_PATH}${ENDPOINT}/${listId}`,
            headers: {
                'X-Access-Token': accessToken,
            },
        });
    }
}

export default ListSource;
