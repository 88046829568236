import React from 'react';
import classnames from 'classnames';
import { number, string, func, bool } from 'prop-types';

function ImportKwfListMessageListItem(props) {
    function handleCheckboxChange() {
        props.onListSelect(props.listId, !props.isChecked);
    }

    const isDisabled = props.keywordCount === 0;
    const liClasses = classnames({
        'is-disabled': isDisabled,
        'is-preselected': props.isPreselected,
        'is-active': props.isChecked,
    });

    return (
        <li className={liClasses}>
            <label htmlFor={props.listId} title={props.listName} className="uk-grid uk-grid-collapse">
                <span className="mg-truncate uk-width-2-3">
                    <input
                        checked={props.isChecked}
                        id={props.listId}
                        onChange={handleCheckboxChange}
                        type="checkbox"
                        disabled={isDisabled}
                    />
                    {props.listName}
                </span>
                <span className="uk-width-1-3 uk-text-right">
                    <span className="color-grey font-10">
                        {props.keywordCount}&nbsp;{props.keywordCount === 1 ? 'kw' : 'kws'}
                    </span>
                </span>
            </label>
        </li>
    );
}

ImportKwfListMessageListItem.propTypes = {
    listName: string.isRequired,
    listId: string.isRequired,
    isChecked: bool.isRequired,
    onListSelect: func.isRequired,
    keywordCount: number.isRequired,
    isPreselected: bool.isRequired,
};

export { ImportKwfListMessageListItem };
