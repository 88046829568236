import React from 'react';
import { isEmpty } from 'ramda';

import customizeReportEmailPreviewImage from 'images/customize-report-email-preview.png';
import { CustomizeReportPanelPreviewProps } from 'components/panels/CustomizeReportPanel/CustomizeReportPanelPreview';
import { CustomizeReportPanelEmailPreviewHeader } from './CustomizeReportPanelEmailPreviewHeader';

type ICustomizeReportPanelEmailPreviewProps = CustomizeReportPanelPreviewProps;
type IFooterTextProps = Pick<CustomizeReportPanelPreviewProps, 'email' | 'name' | 'enabled'>;

const FooterText: React.FC<IFooterTextProps> = props => {
    if (!isEmpty(props.name)) {
        return (
            <>
                <span>You&apos;re receiving this email as a client of {props.name}</span>
                {!isEmpty(props.email) ? (
                    <span>
                        &nbsp;<a href={`mailto:${props.email}`}>({props.email})</a>
                    </span>
                ) : null}
                <span>.</span>
            </>
        );
    } else if (!props.enabled) {
        return <span>This report was generated by serpwatcher.com.</span>;
    } else {
        return null;
    }
};

interface IReplyToTextProps {
    replyToEmail: string;
    replyToName: string;
}

const ReplyToText: React.FC<IReplyToTextProps> = props => {
    if (!isEmpty(props.replyToEmail)) {
        return (
            <span>
                {props.replyToName} ({props.replyToEmail})
            </span>
        );
    } else {
        return null;
    }
};

export const CustomizeReportPanelEmailPreview: React.FC<ICustomizeReportPanelEmailPreviewProps> = ({
    logo,
    email,
    website,
    name,
    enabled,
    protocol,
    accentColor,
}) => {
    const replyToName = !isEmpty(name) ? name : '';
    const replyToEmail = !isEmpty(email) ? email : '';

    return (
        <div className="sw-customize-report-email-preview">
            {/* eslint-disable-next-line max-len */}
            <table className="sw-customize-report-email-header uk-width-1-1 mg-card mg-padding-15-0 uk-position-relative mg-margin-b-30 font-12 ln-12">
                <tbody>
                    <tr>
                        <td className="color-grey">Subject:</td>
                        <td>
                            <strong>
                                {isEmpty(replyToName) ? '' : <>{replyToName}&nbsp;|&nbsp;</>}Weekly report for
                                example.com
                            </strong>
                        </td>
                    </tr>
                    <tr>
                        <td className="color-grey">From:</td>
                        <td>Your Ranking Report (reports@mail.mangools.com)</td>
                    </tr>
                    <tr>
                        <td className="color-grey">Reply-to:</td>
                        <td>
                            <ReplyToText replyToName={replyToName} replyToEmail={replyToEmail} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <CustomizeReportPanelEmailPreviewHeader
                email={email}
                name={name}
                protocol={protocol}
                website={website}
                logo={logo}
                accentColor={accentColor}
            />
            <img className="uk-dispaly-block" alt="email report preview" src={customizeReportEmailPreviewImage} />
            <div className="sw-customize-report-email-preview-footer font-12 uk-text-center ln-12">
                <FooterText email={email} name={name} enabled={enabled} />
                <br />
                Unsubscribe from these emails
            </div>
        </div>
    );
};
