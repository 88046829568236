import ActionTypes from 'constants/ActionTypes';

/**
 * Redux action creators for data (trackings, locations, kwf lists)
 * fetching and creating.
 */

export const requestedTrackingsAction = () => ({
    type: ActionTypes.DATA_TRACKINGS_REQUESTED,
});

export const fetchingTrackingsAction = () => ({
    type: ActionTypes.DATA_TRACKINGS_FETCHING,
});

export const receivedTrackingsAction = payload => ({
    type: ActionTypes.DATA_TRACKINGS_RECEIVED,
    error: false,
    payload,
});

export const errorTrackingsAction = payload => ({
    type: ActionTypes.DATA_TRACKINGS_ERROR,
    error: true,
    payload,
});

export const skippedTrackingsAction = () => ({
    type: ActionTypes.DATA_TRACKINGS_SKIPPED,
});

export const requestedTrackingDetailAction = ({ report }, { id, from, to, shareToken }) => ({
    type: ActionTypes.DATA_TRACKING_DETAIL_REQUESTED,
    error: false,
    payload: {
        options: { id, from, to, shareToken },
        report,
    },
});

export const requestedTrackingStatsDetailAction = ({ report, isTimeframeChanged }, { id, from, to, shareToken }) => ({
    type: ActionTypes.DATA_TRACKING_STATS_DETAIL_REQUESTED,
    error: false,
    payload: {
        options: { id, from, to, shareToken },
        report,
        isTimeframeChanged,
    },
});

export const fetchingTrackingDetailAction = () => ({
    type: ActionTypes.DATA_TRACKING_DETAIL_FETCHING,
});

export const fetchingTrackingStatsDetailAction = () => ({
    type: ActionTypes.DATA_TRACKING_STATS_DETAIL_FETCHING,
});

export const fetchingTrackingTimeframeDataDetailAction = () => ({
    type: ActionTypes.DATA_TRACKING_TIMEFRAME_DATA_DETAIL_FETCHING,
});

export const receivedTrackingDetailAction = payload => ({
    type: ActionTypes.DATA_TRACKING_DETAIL_RECEIVED,
    error: false,
    payload,
});

export const setTrackingPanelAction = payload => ({
    type: ActionTypes.DATA_TRACKING_PANEL_RECEIVED,
    error: false,
    payload,
});

export const receivedTrackingAllStatsDetailAction = payload => ({
    type: ActionTypes.DATA_TRACKING_ALL_STATS_DETAIL_RECEIVED,
    error: false,
    payload,
});

export const receivedTrackingSelectedStatsDetailAction = payload => ({
    type: ActionTypes.DATA_TRACKING_SELECTED_STATS_DETAIL_RECEIVED,
    error: false,
    payload,
});

export const errorTrackingDetailAction = payload => ({
    type: ActionTypes.DATA_TRACKING_DETAIL_ERROR,
    error: true,
    payload,
});

export const requestedLocationsAction = query => ({
    type: ActionTypes.DATA_LOCATIONS_REQUESTED,
    error: false,
    payload: query,
});

export const fetchingLocationsAction = () => ({
    type: ActionTypes.DATA_LOCATIONS_FETCHING,
});

export const receivedLocationsAction = payload => ({
    type: ActionTypes.DATA_LOCATIONS_RECEIVED,
    error: false,
    payload,
});

export const errorLocationsAction = payload => ({
    type: ActionTypes.DATA_LOCATIONS_ERROR,
    error: true,
    payload,
});

export const errorGMBDataAction = payload => ({
    type: ActionTypes.UI_GMB_DATA_ERROR,
    error: true,
    payload,
});

export const requestedNewTrackingAction = () => ({
    type: ActionTypes.DATA_NEW_TRACKING_REQUESTED,
});

export const requestedNewTrackingFillAction = ({ trackingId }) => ({
    type: ActionTypes.DATA_NEW_TRACKING_FILL_REQUESTED,
    error: false,
    payload: { trackingId },
});

export const skippedNewTrackingFillAction = () => ({
    type: ActionTypes.DATA_NEW_TRACKING_FILL_SKIPPED,
});

export const fetchingNewTrackingAction = () => ({
    type: ActionTypes.DATA_NEW_TRACKING_FETCHING,
});

export const receivedNewTrackingAction = newTrackings => ({
    type: ActionTypes.DATA_NEW_TRACKING_RECEIVED,
    error: false,
    payload: newTrackings,
});

export const errorNewTrackingAction = payload => ({
    type: ActionTypes.DATA_NEW_TRACKING_ERROR,
    error: true,
    payload,
});

export const skippedNewTrackingAction = () => ({
    type: ActionTypes.DATA_NEW_TRACKING_SKIPPED,
});

export const requestedListsAction = () => ({
    type: ActionTypes.DATA_LISTS_REQUESTED,
});

export const fetchingListsAction = () => ({
    type: ActionTypes.DATA_LISTS_FETCHING,
});

export const receivedListsAction = payload => ({
    type: ActionTypes.DATA_LISTS_RECEIVED,
    error: false,
    payload,
});

export const errorListsAction = payload => ({
    type: ActionTypes.DATA_LISTS_ERROR,
    error: true,
    payload,
});

export const skippedListsAction = () => ({
    type: ActionTypes.DATA_LISTS_SKIPPED,
});

export const requestedNewTrackingListsKeywordsAction = () => ({
    type: ActionTypes.DATA_NEW_TRACKING_LISTS_KEYWORDS_REQUESTED,
});

export const requestedAddKeywordsPanelListsKeywordsAction = () => ({
    type: ActionTypes.DATA_LISTS_PANELS_ADD_KEYWORDS_KEYWORDS_REQUESTED,
});

export const fetchingListsKeywordsAction = () => ({
    type: ActionTypes.DATA_LISTS_KEYWORDS_FETCHING,
});

export const receivedListsKeywordsAction = payload => ({
    type: ActionTypes.DATA_LISTS_KEYWORDS_RECEIVED,
    error: false,
    payload,
});

export const errorListsKeywordsAction = payload => ({
    type: ActionTypes.DATA_LISTS_KEYWORDS_ERROR,
    error: true,
    payload,
});

export const requestedTrackingsDeleteAction = (id, url) => ({
    type: ActionTypes.DATA_TRACKINGS_DELETE_REQUESTED,
    error: false,
    payload: { id, url },
});

export const cancelledTrackingsDeleteAction = payload => ({
    type: ActionTypes.DATA_TRACKINGS_DELETE_CANCELLED,
    error: false,
    payload,
});

export const fetchingTrackingsDeleteAction = () => ({
    type: ActionTypes.DATA_TRACKINGS_DELETE_FETCHING,
});

export const receivedTrackingsDeleteAction = payload => ({
    type: ActionTypes.DATA_TRACKINGS_DELETE_RECEIVED,
    error: false,
    payload,
});

export const errorTrackingsDeleteAction = payload => ({
    type: ActionTypes.DATA_TRACKINGS_DELETE_ERROR,
    error: true,
    payload,
});

export const requestedTrackingsRestoreAction = (id, url) => ({
    type: ActionTypes.DATA_TRACKINGS_RESTORE_REQUESTED,
    error: false,
    payload: { id, url },
});

export const cancelledTrackingsRestoreAction = payload => ({
    type: ActionTypes.DATA_TRACKINGS_RESTORE_CANCELLED,
    error: false,
    payload,
});

export const fetchingTrackingsRestoreAction = () => ({
    type: ActionTypes.DATA_TRACKINGS_RESTORE_FETCHING,
});

export const receivedTrackingsRestoreAction = payload => ({
    type: ActionTypes.DATA_TRACKINGS_RESTORE_RECEIVED,
    error: false,
    payload,
});

export const errorTrackingsRestoreAction = payload => ({
    type: ActionTypes.DATA_TRACKINGS_RESTORE_ERROR,
    error: true,
    payload,
});

export const rehydrationComplete = () => ({
    type: ActionTypes.DATA_REHYDRATATION_COMPLETE,
});

export const requestedSuggestedKwsResultsAction = subSource => ({
    type: ActionTypes.DATA_SUGGESTED_KWS_RESULTS_REQUESTED,
    error: false,
    payload: subSource,
});

export const fetchingSuggestedKwsResultsAction = () => ({
    type: ActionTypes.DATA_SUGGESTED_KWS_RESULTS_FETCHING,
});

export const receivedSuggestedKwsResultsAction = payload => ({
    type: ActionTypes.DATA_SUGGESTED_KWS_RESULTS_RECEIVED,
    error: false,
    payload,
});

export const errorSuggestedKwsResultsAction = payload => ({
    type: ActionTypes.DATA_SUGGESTED_KWS_RESULTS_ERROR,
    error: true,
    payload,
});

export const emptySuggestedKwsResultsAction = () => ({
    type: ActionTypes.DATA_SUGGESTED_KWS_RESULTS_EMPTY,
});

export const skippedSuggestedKwsResultsAction = () => ({
    type: ActionTypes.DATA_SUGGESTED_KWS_RESULTS_SKIPPED,
});

export const requestedSuggestedKwsCountAction = subSource => ({
    type: ActionTypes.DATA_SUGGESTED_KWS_COUNT_REQUESTED,
    error: false,
    payload: subSource,
});

export const fetchingSuggestedKwsCountAction = () => ({
    type: ActionTypes.DATA_SUGGESTED_KWS_COUNT_FETCHING,
});

export const receivedSuggestedKwsCountAction = payload => ({
    type: ActionTypes.DATA_SUGGESTED_KWS_COUNT_RECEIVED,
    error: false,
    payload,
});

export const errorSuggestedKwsCountAction = payload => ({
    type: ActionTypes.DATA_SUGGESTED_KWS_COUNT_ERROR,
    error: true,
    payload,
});

export const emptySuggestedKwsCountAction = () => ({
    type: ActionTypes.DATA_SUGGESTED_KWS_COUNT_EMPTY,
});

export const skippedSuggestedKwsCountAction = () => ({
    type: ActionTypes.DATA_SUGGESTED_KWS_COUNT_SKIPPED,
});

export const requestedTrackingKeywordDetailAction = ({ id, report, shareToken }) => ({
    type: ActionTypes.DATA_TRACKING_KEYWORD_DETAIL_REQUESTED,
    error: false,
    payload: {
        id,
        report,
        shareToken,
    },
});

export const fetchingTrackingKeywordDetailAction = () => ({
    type: ActionTypes.DATA_TRACKING_KEYWORD_DETAIL_FETCHING,
});

export const receivedTrackingKeywordDetailAction = payload => ({
    type: ActionTypes.DATA_TRACKING_KEYWORD_DETAIL_RECEIVED,
    error: false,
    payload,
});

export const errorTrackingKeywordDetailAction = payload => ({
    type: ActionTypes.DATA_TRACKING_KEYWORD_DETAIL_ERROR,
    error: true,
    payload,
});

export const requestedTrackingKeywordDetailResetAction = () => ({
    type: ActionTypes.DATA_TRACKING_KEYWORD_DETAIL_RESET,
});

export const requestedSelectedTrackingKeywordsDeleteAction = () => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_SELECTED_REQUESTED,
});

export const fetchingSelectedTrackingKeywordsDeleteAction = () => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_SELECTED_FETCHING,
});

export const errorSelectedTrackingKeywordsDeleteAction = payload => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_SELECTED_ERROR,
    error: true,
    payload,
});

export const receivedSelectedTrackingKeywordsDeleteAction = payload => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_SELECTED_RECEIVED,
    error: false,
    payload,
});

export const cancelledSelectedTrackingKeywordsDeleteAction = payload => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_SELECTED_CANCELLED,
    error: false,
    payload,
});

export const requestedSelectedTrackingKeywordsRestoreAction = () => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_REQUESTED,
});

export const fetchingSelectedTrackingKeywordsRestoreAction = () => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_FETCHING,
});

export const errorSelectedTrackingKeywordsRestoreAction = payload => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_ERROR,
    error: true,
    payload,
});

export const receivedSelectedTrackingKeywordsRestoreAction = payload => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_RECEIVED,
    error: false,
    payload,
});

export const cancelledSelectedTrackingKeywordsRestoreAction = payload => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_CANCELLED,
    error: false,
    payload,
});

export const requestedAddKeywordsToTrackingAction = () => ({
    type: ActionTypes.DATA_TRACKING_ADD_KEYWORDS_REQUESTED,
    error: false,
});

export const fetchingAddKeywordsToTrackingAction = () => ({
    type: ActionTypes.DATA_TRACKING_ADD_KEYWORDS_FETCHING,
});

export const receivedAddKeywordsToTrackingAction = payload => ({
    type: ActionTypes.DATA_TRACKING_ADD_KEYWORDS_RECEIVED,
    error: false,
    payload,
});

export const errorAddKeywordsToTrackingAction = payload => ({
    type: ActionTypes.DATA_TRACKING_ADD_KEYWORDS_ERROR,
    error: true,
    payload,
});

export const requestedReportsAction = () => ({
    type: ActionTypes.DATA_REPORTS_REQUESTED,
});

export const fetchingReportsAction = () => ({
    type: ActionTypes.DATA_REPORTS_FETCHING,
});

export const receivedReportsAction = payload => ({
    type: ActionTypes.DATA_REPORTS_RECEIVED,
    error: false,
    payload,
});

export const errorReportsAction = payload => ({
    type: ActionTypes.DATA_REPORTS_ERROR,
    error: true,
    payload,
});

export const requestedNewReportAction = () => ({
    type: ActionTypes.DATA_NEW_REPORT_REQUESTED,
});

export const fetchingNewReportAction = () => ({
    type: ActionTypes.DATA_NEW_REPORT_FETCHING,
});

export const receivedNewReportAction = (trackingId, reports) => ({
    type: ActionTypes.DATA_NEW_REPORT_RECEIVED,
    error: false,
    payload: {
        trackingId,
        reports,
    },
});

export const errorNewReportAction = payload => ({
    type: ActionTypes.DATA_NEW_REPORT_ERROR,
    error: true,
    payload,
});

export const requestedUpdateReportAction = () => ({
    type: ActionTypes.DATA_UPDATE_REPORT_REQUESTED,
});

export const fetchingUpdateReportAction = () => ({
    type: ActionTypes.DATA_UPDATE_REPORT_FETCHING,
});

export const receivedUpdateReportAction = updatedReport => ({
    type: ActionTypes.DATA_UPDATE_REPORT_RECEIVED,
    error: false,
    payload: updatedReport,
});

export const errorUpdateReportAction = payload => ({
    type: ActionTypes.DATA_UPDATE_REPORT_ERROR,
    error: true,
    payload,
});

export const requestedDeleteReportAction = () => ({
    type: ActionTypes.DATA_DELETE_REPORT_REQUESTED,
});

export const fetchingDeleteReportAction = () => ({
    type: ActionTypes.DATA_DELETE_REPORT_FETCHING,
});

export const receivedDeleteReportAction = (trackingId, reports) => ({
    type: ActionTypes.DATA_DELETE_REPORT_RECEIVED,
    error: false,
    payload: {
        trackingId,
        reports,
    },
});

export const errorDeleteReportAction = payload => ({
    type: ActionTypes.DATA_DELETE_REPORT_ERROR,
    error: true,
    payload,
});

export const cancelledReportDeleteAction = payload => ({
    type: ActionTypes.DATA_DELETE_REPORT_CANCELLED,
    error: false,
    payload,
});

export const requestedTrackingTagsAction = () => ({
    type: ActionTypes.DATA_TRACKING_TAGS_REQUESTED,
});

export const fetchingTrackingTagsAction = () => ({
    type: ActionTypes.DATA_TRACKING_TAGS_FETCHING,
});

export const receivedTrackingTagsAction = payload => ({
    type: ActionTypes.DATA_TRACKING_TAGS_RECEIVED,
    error: false,
    payload,
});

export const errorTrackingTagsAction = payload => ({
    type: ActionTypes.DATA_TRACKING_TAGS_ERROR,
    error: true,
    payload,
});

export const requestedBulkAssignSelectedTagsAction = () => ({
    type: ActionTypes.DATA_TRACKING_TAGS_BULK_ASSIGN_REQUESTED,
});

export const receivedBulkAssignSelectedTagsAction = ({ trackingTags, tagIds, keywordIds }) => ({
    type: ActionTypes.DATA_TRACKING_TAGS_BULK_ASSIGN_RECEIVED,
    error: false,
    payload: { trackingTags, tagIds, keywordIds },
});

export const errorBulkAssignSelectedTagsAction = payload => ({
    type: ActionTypes.DATA_TRACKING_TAGS_BULK_ASSIGN_ERROR,
    error: true,
    payload,
});

export const requestedBulkUnassignSelectedTagsAction = () => ({
    type: ActionTypes.DATA_TRACKING_TAGS_BULK_UNASSIGN_REQUESTED,
});

export const receivedBulkUnassignSelectedTagsAction = ({ tagIds, keywordIds }) => ({
    type: ActionTypes.DATA_TRACKING_TAGS_BULK_UNASSIGN_RECEIVED,
    error: false,
    payload: { tagIds, keywordIds },
});

export const errorBulkUnassignSelectedTagsAction = payload => ({
    type: ActionTypes.DATA_TRACKING_TAGS_BULK_UNASSIGN_ERROR,
    error: true,
    payload,
});

export const requestedAssignSelectedTagsAction = () => ({
    type: ActionTypes.DATA_TRACKING_TAGS_ASSIGN_REQUESTED,
});

export const receivedAssignSelectedTagsAction = ({ trackingTags, tagIds, keywordId }) => ({
    type: ActionTypes.DATA_TRACKING_TAGS_ASSIGN_RECEIVED,
    error: false,
    payload: { trackingTags, tagIds, keywordId },
});

export const errorAssignSelectedTagsAction = payload => ({
    type: ActionTypes.DATA_TRACKING_TAGS_ASSIGN_ERROR,
    error: true,
    payload,
});

export const requestedUnassignTagAction = ({ tagId, tagName }) => ({
    type: ActionTypes.DATA_TRACKING_TAGS_UNASSIGN_REQUESTED,
    error: false,
    payload: { tagId, tagName },
});

export const fetchingUnassignTagAction = tagId => ({
    type: ActionTypes.DATA_TRACKING_TAGS_UNASSIGN_FETCHING,
    error: false,
    payload: tagId,
});

export const receivedUnassignTagAction = ({ tagId, keywordId }) => ({
    type: ActionTypes.DATA_TRACKING_TAGS_UNASSIGN_RECEIVED,
    error: false,
    payload: { tagId, keywordId },
});

export const errorUnassignTagAction = payload => ({
    type: ActionTypes.DATA_TRACKING_TAGS_UNASSIGN_ERROR,
    error: true,
    payload,
});

export const requestedCreateTagAction = ({ name, color, createdFrom }) => ({
    type: ActionTypes.DATA_TRACKING_TAGS_CREATE_REQUESTED,
    error: false,
    payload: { name, color, createdFrom },
});

export const receivedCreateTagAction = ({ createdFrom, tag }) => ({
    type: ActionTypes.DATA_TRACKING_TAGS_CREATE_RECEIVED,
    error: false,
    payload: { createdFrom, tag },
});

export const errorCreateTagAction = payload => ({
    type: ActionTypes.DATA_TRACKING_TAGS_CREATE_ERROR,
    error: true,
    payload,
});

export const requestedUpdateTagNameAction = ({ tagId, tagName }) => ({
    type: ActionTypes.DATA_TRACKING_TAGS_UPDATE_NAME_REQUESTED,
    error: false,
    payload: { tagId, tagName },
});

export const receivedUpdateTagNameAction = ({ tagId, tagName }) => ({
    type: ActionTypes.DATA_TRACKING_TAGS_UPDATE_NAME_RECEIVED,
    error: false,
    payload: { tagId, tagName },
});

export const errorUpdateTagNameAction = payload => ({
    type: ActionTypes.DATA_TRACKING_TAGS_UPDATE_NAME_ERROR,
    error: true,
    payload,
});

export const requestedUpdateTagColorAction = ({ tagId, tagColor }) => ({
    type: ActionTypes.DATA_TRACKING_TAGS_UPDATE_COLOR_REQUESTED,
    error: false,
    payload: { tagId, tagColor },
});

export const receivedUpdateTagColorAction = ({ tagId, tagColor }) => ({
    type: ActionTypes.DATA_TRACKING_TAGS_UPDATE_COLOR_RECEIVED,
    error: false,
    payload: { tagId, tagColor },
});

export const errorUpdateTagColorAction = payload => ({
    type: ActionTypes.DATA_TRACKING_TAGS_UPDATE_COLOR_ERROR,
    error: true,
    payload,
});

export const requestedDeleteTagAction = ({ tagId, tagName }) => ({
    type: ActionTypes.DATA_TRACKING_TAGS_DELETE_REQUESTED,
    error: false,
    payload: { tagId, tagName },
});

export const receivedDeleteTagAction = tagId => ({
    type: ActionTypes.DATA_TRACKING_TAGS_DELETE_RECEIVED,
    error: false,
    payload: tagId,
});

export const errorDeleteTagAction = payload => ({
    type: ActionTypes.DATA_TRACKING_TAGS_DELETE_ERROR,
    error: true,
    payload,
});

export const requestedDeleteKeywordAction = keywordId => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_REQUESTED,
    error: false,
    payload: keywordId,
});

export const fetchingDeleteKeywordAction = () => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_FETCHING,
});

export const receivedDeleteKeywordAction = ({ keywordId, trackingId }) => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_RECEIVED,
    error: false,
    payload: { keywordId, trackingId },
});

export const errorDeleteKeywordAction = payload => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_ERROR,
    error: true,
    payload,
});

export const cancelledDeleteKeywordAction = ({ keywordId, trackingId }) => ({
    type: ActionTypes.DATA_TRACKINGS_DELETE_CANCELLED,
    error: false,
    payload: { keywordId, trackingId },
});

export const requestedSelectedTrackingKeywordsExportAction = () => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_EXPORT_SELECTED_REQUESTED,
    error: false,
});

export const fetchingSelectedTrackingKeywordsExportAction = () => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_EXPORT_SELECTED_FETCHING,
});

export const receivedSelectedTrackingKeywordsExportAction = () => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_EXPORT_SELECTED_RECEIVED,
});

export const errorSelectedTrackingKeywordsExportAction = payload => ({
    type: ActionTypes.DATA_TRACKING_KEYWORDS_EXPORT_SELECTED_ERROR,
    error: true,
    payload,
});

export const requestedTrackingDetailReloadAction = ({ report }) => ({
    type: ActionTypes.DATA_TRACKING_DETAIL_RELOAD_REQUESTED,
    error: false,
    payload: { report },
});

export const requestedNewTrackingCloneAction = ({ cloneType, templateTrackingId }) => ({
    type: ActionTypes.DATA_NEW_TRACKING_CLONE_REQUESTED,
    error: false,
    payload: {
        templateTrackingId,
        cloneType,
    },
});

export const fetchingAnnouncementsAction = () => ({
    type: ActionTypes.DATA_ANNOUNCEMENTS_FETCHING,
});

export const receivedAnnouncementsAction = payload => ({
    type: ActionTypes.DATA_ANNOUNCEMENTS_RECEIVED,
    error: false,
    payload,
});

export const errorAnnouncementsAction = payload => ({
    type: ActionTypes.DATA_ANNOUNCEMENTS_ERROR,
    error: true,
    payload,
});

export const requestedCreateAnnotationAction = ({ annotationText, date, nonShareable }) => ({
    type: ActionTypes.DATA_TRACKING_ANNOTATIONS_CREATE_REQUESTED,
    error: false,
    payload: {
        annotationText,
        date,
        nonShareable,
    },
});

export const fetchingCreateAnnotationAction = () => ({
    type: ActionTypes.DATA_TRACKING_ANNOTATIONS_CREATE_FETCHING,
});

export const receivedCreateAnnotationAction = payload => ({
    type: ActionTypes.DATA_TRACKING_ANNOTATIONS_CREATE_RECEIVED,
    error: false,
    payload,
});

export const errorCreateAnnotationAction = payload => ({
    type: ActionTypes.DATA_TRACKING_ANNOTATIONS_CREATE_ERROR,
    error: true,
    payload,
});

export const requestedDeleteAnnotationAction = annotationId => ({
    type: ActionTypes.DATA_TRACKING_ANNOTATIONS_DELETE_REQUESTED,
    error: false,
    payload: annotationId,
});

export const receivedDeleteAnnotationAction = annotationId => ({
    type: ActionTypes.DATA_TRACKING_ANNOTATIONS_DELETE_RECEIVED,
    error: false,
    payload: annotationId,
});

export const errorDeleteAnnotationAction = payload => ({
    type: ActionTypes.DATA_TRACKING_ANNOTATIONS_DELETE_ERROR,
    error: true,
    payload,
});

export const requestedUpdateAnnotationAction = ({ annotationId, annotationText, nonShareable }) => ({
    type: ActionTypes.DATA_TRACKING_ANNOTATIONS_UPDATE_REQUESTED,
    error: false,
    payload: {
        annotationId,
        annotationText,
        nonShareable,
    },
});

export const receivedUpdateAnnotationAction = ({ annotationId, annotationText, nonShareable }) => ({
    type: ActionTypes.DATA_TRACKING_ANNOTATIONS_UPDATE_RECEIVED,
    error: false,
    payload: {
        annotationId,
        annotationText,
        nonShareable,
    },
});

export const errorUpdateAnnotationAction = payload => ({
    type: ActionTypes.DATA_TRACKING_ANNOTATIONS_UPDATE_ERROR,
    error: true,
    payload,
});

export const requestedSnapshotImageAction = () => ({
    type: ActionTypes.DATA_SNAPSHOT_IMAGE_REQUESTED,
});

export const exportingSnapshotImageFinishedAction = () => ({
    type: ActionTypes.DATA_SNAPSHOT_IMAGE_FINISHED,
    error: false,
});

export const errorSnapshotImageAction = payload => ({
    type: ActionTypes.DATA_SNAPSHOT_IMAGE_ERROR,
    error: true,
    payload,
});

export const optimisticUpdateTrackingDomainAction = payload => ({
    type: ActionTypes.DATA_TRACKING_DOMAIN_OPTIMISTIC_UPDATE,
    payload,
});

export const revertOptimisticUpdateTrackingDomainAction = () => ({
    type: ActionTypes.DATA_TRACKING_DOMAIN_OPTIMISTIC_UPDATE_REVERT,
});

export const requestedTrackingDomainUpdateAction = payload => ({
    type: ActionTypes.DATA_TRACKING_DOMAIN_UPDATE_REQUESTED,
    payload,
});

export const receivedTrackingDomainUpdateAction = payload => ({
    type: ActionTypes.DATA_TRACKING_UPDATE_DOMAIN_RECEIVED,
    payload,
});

export const errorUpdateTrackingDomainAction = payload => ({
    type: ActionTypes.DATA_TRACKING_UPDATE_DOMAIN_ERROR,
    error: true,
    payload,
});

export const requestedTrackingShareTokenUpdateAction = () => ({
    type: ActionTypes.DATA_TRACKING_SHARE_TOKEN_UPDATE_REQUESTED,
});

export const receivedTrackingShareTokenUpdateAction = payload => ({
    type: ActionTypes.DATA_TRACKING_SHARE_TOKEN_UPDATE_RECEIVED,
    payload,
});

export const errorTrackingShareTokenUpdateAction = payload => ({
    type: ActionTypes.DATA_TRACKING_SHARE_TOKEN_UPDATE_ERROR,
    error: true,
    payload,
});

export const requestedKwfinderListUrlImport = ({ kwfinderListId, newTracking = false }) => ({
    type: ActionTypes.DATA_KWFINDER_LIST_URL_IMPORT_REQUESTED,
    payload: { kwfinderListId, newTracking },
});

export const errorFetchKwfinderListDetailAction = payload => ({
    type: ActionTypes.DATA_KWFINDER_LIST_URL_IMPORT_ERROR,
    payload,
});
