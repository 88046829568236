import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';
import concat from 'ramda/src/concat';

const initialState = {
    filter: '',
    openedTrackingCardIds: [],
};

const trackingListReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_TRACKING_LIST_FILTER_SET: {
            return update(state, {
                filter: { $set: action.payload },
            });
        }
        case ActionTypes.UI_TRACKING_LIST_OPENED_CARD_SET: {
            const { trackingId, selected } = action.payload;
            if (selected === true) {
                return update(state, {
                    openedTrackingCardIds: {
                        $apply: ids => {
                            if (ids.includes(trackingId)) {
                                return ids;
                            } else {
                                return concat(ids, [trackingId]);
                            }
                        },
                    },
                });
            } else {
                return update(state, {
                    openedTrackingCardIds: {
                        $apply: ids => ids.filter(id => id !== trackingId),
                    },
                });
            }
        }
        default: {
            return state;
        }
    }
};

export default trackingListReducer;
