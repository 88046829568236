import { arrayOf, number, shape, string } from 'prop-types';
import NumberOrNAType from 'types/commons/NumberOrNAType';
import LocationType from 'types/LocationType';

export default shape({
    createdAt: number,
    domain: string.isRequired,
    performanceIndexes: arrayOf(
        shape({
            date: string.isRequired,
            performanceIndex: NumberOrNAType.isRequired,
        }),
    ).isRequired,
    id: string.isRequired,
    trackedKeywordsCount: number.isRequired,
    location: LocationType.isRequired,
    platformId: number.isRequired,
});
