import { bool, number, shape, string } from 'prop-types';
import NumberOrNAType from 'types/commons/NumberOrNAType';
import StringOrNAType from 'types/commons/StringOrNAType';

export default shape({
    content: shape({
        avg: NumberOrNAType.isRequired,
        keywordsCount: NumberOrNAType.isRequired,
        text: StringOrNAType.isRequired,
    }).isRequired,
    nonShareable: bool.isRequired,
    date: string.isRequired,
    id: string.isRequired,
    type: number.isRequired,
});
