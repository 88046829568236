import { createSelector } from 'reselect';

export const defaultsSelector = state => state.ui.defaults;

export const defaultLocationSelector = state => state.ui.defaults.location;
export const defaultPlatformSelector = state => state.ui.defaults.platform;

export const validLocationsSelector = createSelector(defaultLocationSelector, rawLocaitons =>
    rawLocaitons.filter(item => item),
);
export const validLocationsIdsSelector = createSelector(validLocationsSelector, validLocations => {
    return validLocations.map(({ id }) => id);
});

export const defaultPlatformsIdsSelector = createSelector(defaultsSelector, defaults => {
    return defaults.platform.map(({ id }) => id);
});
