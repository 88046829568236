export const OR = {
    id: 0,
    label: 'OR',
};

export const AND = {
    id: 1,
    label: 'AND',
};

export default [OR, AND];
