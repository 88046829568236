import ActionTypes from 'constants/ActionTypes';
import length from 'ramda/src/length';
import update from 'immutability-helper';

const initialState = {
    count: 0,
    data: [],
    error: {
        status: null,
        text: null,
    },
    fetching: false,
    fetchingKeywords: false,
    suggestedKeywordsCount: null,
    suggestedKeywordsCountError: {
        status: null,
        text: null,
    },
    fetchingSuggestedKeywords: false,
    suggestedKeywords: [],
    keywords: [],
};

const listsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_LISTS_FETCHING: {
            return update(state, {
                fetching: {
                    $set: true,
                },
            });
        }
        case ActionTypes.DATA_LISTS_RECEIVED: {
            return update(state, {
                data: {
                    $set: action.payload,
                },
                count: {
                    $set: length(action.payload),
                },
                fetching: {
                    $set: false,
                },
                error: {
                    $set: initialState.error,
                },
            });
        }
        case ActionTypes.DATA_LISTS_ERROR: {
            return update(state, {
                count: {
                    $set: initialState.count,
                },
                data: {
                    $set: initialState.data,
                },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                fetching: {
                    $set: initialState.fetching,
                },
            });
        }
        case ActionTypes.DATA_LISTS_KEYWORDS_FETCHING: {
            return update(state, {
                fetchingKeywords: {
                    $set: true,
                },
            });
        }
        case ActionTypes.DATA_SUGGESTED_KWS_RESULTS_FETCHING:
        case ActionTypes.DATA_SUGGESTED_KWS_COUNT_FETCHING: {
            return update(state, {
                fetchingSuggestedKeywords: {
                    $set: true,
                },
            });
        }
        case ActionTypes.DATA_LISTS_KEYWORDS_RECEIVED: {
            return update(state, {
                fetchingKeywords: {
                    $set: false,
                },
                keywords: {
                    $set: action.payload,
                },
            });
        }
        // NOTE: When importing kws from list id in url
        case ActionTypes.DATA_KWFINDER_LIST_URL_IMPORT_ERROR:
        case ActionTypes.UI_NEW_TRACKING_KEYWORDS_SET_REQUESTED:
        case ActionTypes.UI_PANELS_ADD_KEYWORDS_KEYWORDS_SET_REQUESTED: {
            return update(state, {
                fetchingKeywords: {
                    $set: false,
                },
            });
        }
        case ActionTypes.DATA_LISTS_KEYWORDS_ERROR: {
            return update(state, {
                fetchingKeywords: {
                    $set: initialState.fetchingKeywords,
                },
                keywords: {
                    $set: initialState.keywords,
                },
            });
        }
        case ActionTypes.DATA_SUGGESTED_KWS_COUNT_ERROR: {
            return update(state, {
                suggestedKeywordsCountError: { $set: action.payload },
                fetchingSuggestedKeywords: {
                    $set: false,
                },
            });
        }
        case ActionTypes.DATA_SUGGESTED_KWS_RESULTS_RECEIVED: {
            return update(state, {
                fetchingSuggestedKeywords: {
                    $set: false,
                },
                suggestedKeywords: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.DATA_SUGGESTED_KWS_COUNT_RECEIVED: {
            return update(state, {
                fetchingSuggestedKeywords: {
                    $set: false,
                },
                suggestedKeywordsCount: {
                    $set: action.payload.data.keywordsCount || 0,
                },
            });
        }
        case ActionTypes.DATA_USER_LOGOUT_RECEIVED: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default listsReducer;
