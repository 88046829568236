import React from 'react';
import { Link } from 'react-router';
import { useSelector } from 'react-redux';
import { newTrackingCreatedTrackingIdSelector } from 'selectors/uiSelectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RoutePaths from 'constants/RoutePaths';

import reportsVideoMp4 from 'videos/monthly-reports.mp4';
import reportsVideoWebm from 'videos/monthly-reports.webm';
import TipsVideo from 'components/newTracking/step/finish/TipsVideo';

const ReportsLayout = () => {
    const createdTrackingId = useSelector(newTrackingCreatedTrackingIdSelector);

    return (
        <div className="sw-overflow-visible uk-flex uk-flex-column">
            <div className="uk-flex mg-alert is-info is-big">
                <FontAwesomeIcon
                    icon={['far', 'lightbulb']}
                    aria-label="Tip"
                    className="mg-margin-r-15 mg-margin-t-5 uk-hidden-small uk-position-relative font-36"
                />
                <div className="font-14 uk-flex uk-flex-column">
                    <h3 className="mg-margin-b-5 uk-text-bold">Schedule weekly or monthly reports</h3>
                    <p className="uk-margin-small">
                        Your clients will surely appreciate a regular report of their domain&apos;s progress. This is
                        especially helpful when you have many clients so you don&apos;t have to think about sending all
                        these reports manually.
                    </p>
                </div>
            </div>
            <div className="uk-flex uk-flex-wrap mg-margin-t-45 mg-margin-b-30">
                <div className="uk-width-medium-1-2 uk-width-1-1">
                    <TipsVideo VideoMp4={reportsVideoMp4} VideoWebm={reportsVideoWebm} />
                </div>
                <div className="uk-width-medium-1-2 mg-padding-15 uk-padding-bottom-remove uk-width-1-1 font-14">
                    <p className="uk-margin-small">
                        Click on the button&nbsp;
                        <strong className="color-black">Reports &amp; Alerts in the top right corner</strong>&nbsp; to
                        set up automatic emails based on different scenarios.
                    </p>
                    <ol>
                        <li>Choose “Weekly” or “Monthly” report</li>
                        <li>Add recipients.</li>
                        <li>Name the report.</li>
                    </ol>
                </div>
            </div>
            <hr className="mg-margin-b-30" />
            <p className="uk-width-1-1 uk-flex uk-flex-center">
                <Link
                    to={{ pathname: RoutePaths.TRACKING, query: { id: createdTrackingId } }}
                    className="mg-btn is-small is-green uk-width-2-3"
                >
                    Go to tracking &amp; set up
                </Link>
            </p>
        </div>
    );
};

export default ReportsLayout;
