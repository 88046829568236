import React, { Component } from 'react';
import { func } from 'prop-types';
import moment from 'moment';
import isNil from 'ramda/src/isNil';
import DayPicker from 'react-day-picker';

import { MAXIMUM_DAYS } from 'constants/TimeframePresets';
import MomentType from 'types/commons/MomentType';

class DateRangePicker extends Component {
    constructor(props) {
        super(props);

        // Need to be bound first
        this.getDefaultValues = this.getDefaultValues.bind(this);

        this.state = this.getDefaultValues();

        this.getModifiers = this.getModifiers.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            from: this.validateDate(newProps.from),
            to: this.validateDate(newProps.to),
        });
    }

    getDefaultValues() {
        return {
            from: this.validateDate(this.props.from),
            to: this.validateDate(this.props.to),
        };
    }

    validateDate(date) {
        if (moment.isMoment(date) && date.isValid()) {
            return date.toDate();
        } else {
            return null;
        }
    }

    getFirstAvailableMonth() {
        if (!isNil(this.props.start)) {
            return this.props.start.startOf('day').toDate();
        } else {
            return moment().subtract(MAXIMUM_DAYS, 'days').startOf('day').toDate();
        }
    }

    getTodayMonth() {
        return moment().startOf('month').toDate();
    }

    getModifiers() {
        let before = null;
        const start = !isNil(this.props.start) ? this.props.start.startOf('day') : null;

        if (!isNil(start)) {
            const maximumDay = moment().subtract(MAXIMUM_DAYS, 'days').startOf('day');

            if (start.isBefore(maximumDay)) {
                before = maximumDay.toDate();
            } else {
                before = start.toDate();
            }
        }

        return {
            disabled: [{ before }, { after: moment().endOf('day').toDate() }],
            from: this.state.from,
            selected: {
                from: this.state.from,
                to: this.state.to,
            },
            to: this.state.to,
        };
    }

    handleDayClick(day, { disabled = false }) {
        const { from, to } = this.state;

        if (!disabled) {
            if (!isNil(from) && day < from) {
                // Reset the from day if the clicked day is before the current from-day
                this.setState({
                    from: day,
                    to: null,
                });

                // Save from date if someone stop choosing after first click
                this.props.onChange({
                    from: moment(day),
                    to: null,
                });
            } else if (!isNil(from) && isNil(to)) {
                this.setState({
                    to: day,
                });

                this.props.onChange({
                    from: moment(from),
                    to: moment(day),
                });
            } else if (!isNil(from) && !isNil(to)) {
                // Changing already set timeframe (reparation)
                this.setState({
                    from: day,
                    to: null,
                });

                // Save from date if someone stop choosing after first click
                this.props.onChange({
                    from: moment(day),
                    to: null,
                });
            }
        }
    }

    render() {
        return (
            <DayPicker
                className="Range"
                firstDayOfWeek={1}
                fromMonth={this.getFirstAvailableMonth()}
                initialMonth={this.state.from}
                modifiers={this.getModifiers()}
                numberOfMonths={2}
                onDayClick={this.handleDayClick}
                toMonth={this.getTodayMonth()}
            />
        );
    }
}

DateRangePicker.propTypes = {
    from: MomentType.isRequired,
    onChange: func.isRequired,
    start: MomentType,
    to: MomentType,
};

export default DateRangePicker;
