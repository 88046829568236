import moment from 'moment';

export const formatEstimatedTime = (durationInSeconds: number | null): string => {
    if (!durationInSeconds) {
        // fallback in case of some problem. Better show something.
        return '30 minutes';
    }

    const duration = moment.duration(durationInSeconds, 'seconds');

    // Get the whole minutes and remaining seconds
    let minutes = duration.minutes();
    const remainingSeconds = duration.seconds();

    // Apply the rounding logic
    if (remainingSeconds >= 15) {
        minutes += 1;
    }

    // Ensure a minimum of 1 minute
    if (minutes < 1) {
        minutes = 1;
    }

    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
};
