import ActionTypes from 'constants/ActionTypes';

/**
 * Redux action creators for persistent UI changes
 */

export const setDefaultLocation = location => ({
    type: ActionTypes.UI_DEFAULTS_LOCATION_SET,
    error: false,
    payload: location,
});

export const setDefaultPlatform = platform => ({
    type: ActionTypes.UI_DEFAULTS_PLATFORM_SET,
    error: false,
    payload: platform,
});

export const setSortingSettings = ({ column, type }) => ({
    type: ActionTypes.UI_DEFAULTS_SORTING_SETTINGS_SET,
    error: false,
    payload: { column, type },
});

export const setTrackingGroupOrderedKeys = ({ trackingGroupKeys, oldIndex, newIndex }) => ({
    type: ActionTypes.UI_DEFAULTS_TRACKING_GROUP_ORDERED_KEYS_SET,
    error: false,
    payload: {
        trackingGroupKeys,
        oldIndex,
        newIndex,
    },
});
