import update from 'immutability-helper';
import includes from 'ramda/src/includes';
import concat from 'ramda/src/concat';
import uniq from 'ramda/src/uniq';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    data: {
        createdAt: 0,
        id: '',
        keyword: '',
        lastCheckedAt: 0,
        searchVolume: 0,
        serpUrl: '',
        tags: [],
        monthlySearchVolumes: [],
        serpSnapshotId: '',
        items: [],
    },
    error: {
        status: null,
        text: null,
    },
    fetching: false,
};

const trackingKeywordDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_TRACKING_KEYWORD_DETAIL_RESET: {
            return initialState;
        }
        case ActionTypes.DATA_TRACKING_KEYWORD_DETAIL_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_TRACKING_KEYWORD_DETAIL_RECEIVED: {
            return update(state, {
                data: {
                    createdAt: { $set: action.payload.createdAt },
                    id: { $set: action.payload.id },
                    keyword: { $set: action.payload.keyword },
                    lastCheckedAt: { $set: action.payload.lastCheckedAt },
                    searchVolume: { $set: action.payload.searchVolume },
                    serpUrl: { $set: action.payload.serpUrl },
                    tags: { $set: action.payload.tags },
                    monthlySearchVolumes: { $set: action.payload.monthlySearchVolumes },
                    serpSnapshotId: { $set: action.payload.serpSnapshotId },
                    items: { $set: action.payload.items },
                },
                fetching: { $set: false },
            });
        }
        case ActionTypes.DATA_TRACKING_KEYWORD_DETAIL_ERROR: {
            return update(state, {
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                fetching: { $set: false },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_BULK_ASSIGN_RECEIVED: {
            const allTags = action.payload.trackingTags;
            const { tagIds } = action.payload;
            const { keywordIds } = action.payload;

            if (includes(state.data.id, keywordIds)) {
                return update(state, {
                    data: {
                        tags: {
                            $apply: tags => {
                                const currentTagIds = tags.map(tag => tag.id);
                                const newTagIds = uniq(concat(currentTagIds, tagIds));

                                if (currentTagIds.length !== newTagIds.length) {
                                    return allTags.filter(tag => includes(tag.id, newTagIds));
                                } else {
                                    return tags;
                                }
                            },
                        },
                    },
                });
            } else {
                return state;
            }
        }
        case ActionTypes.DATA_TRACKING_TAGS_BULK_UNASSIGN_RECEIVED: {
            const { tagIds } = action.payload;
            const { keywordIds } = action.payload;

            if (includes(state.data.id, keywordIds)) {
                return update(state, {
                    data: {
                        tags: {
                            $apply: tags => tags.filter(tag => !includes(tag.id, tagIds)),
                        },
                    },
                });
            } else {
                return state;
            }
        }
        case ActionTypes.DATA_TRACKING_TAGS_ASSIGN_RECEIVED: {
            const allTags = action.payload.trackingTags;
            const { tagIds } = action.payload;
            const { keywordId } = action.payload;

            if (state.data.id === keywordId) {
                return update(state, {
                    data: {
                        tags: {
                            $apply: tags => {
                                const currentTagIds = tags.map(tag => tag.id);
                                const newTagIds = uniq(concat(currentTagIds, tagIds));

                                if (currentTagIds.length !== newTagIds.length) {
                                    return allTags.filter(tag => includes(tag.id, newTagIds));
                                } else {
                                    return tags;
                                }
                            },
                        },
                    },
                });
            } else {
                return state;
            }
        }
        case ActionTypes.DATA_TRACKING_TAGS_UNASSIGN_RECEIVED: {
            const { tagId } = action.payload;
            const { keywordId } = action.payload;

            if (state.data.id === keywordId) {
                return update(state, {
                    data: {
                        tags: {
                            $apply: tags => tags.filter(tag => tag.id !== tagId),
                        },
                    },
                });
            } else {
                return state;
            }
        }
        case ActionTypes.DATA_TRACKING_TAGS_UPDATE_NAME_RECEIVED: {
            return update(state, {
                data: {
                    tags: {
                        $apply: tags =>
                            tags.map(tag => {
                                if (tag.id === action.payload.tagId) {
                                    return update(tag, {
                                        name: {
                                            $set: action.payload.tagName,
                                        },
                                    });
                                } else {
                                    return tag;
                                }
                            }),
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_UPDATE_COLOR_RECEIVED: {
            return update(state, {
                data: {
                    tags: {
                        $apply: tags =>
                            tags.map(tag => {
                                if (tag.id === action.payload.tagId) {
                                    return update(tag, {
                                        color: {
                                            $set: action.payload.tagColor,
                                        },
                                    });
                                } else {
                                    return tag;
                                }
                            }),
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_DELETE_RECEIVED: {
            return update(state, {
                data: {
                    tags: {
                        $apply: tags => tags.filter(tag => tag.id !== action.payload),
                    },
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default trackingKeywordDetailReducer;
