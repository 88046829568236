import Defaults from 'mangools-commons/lib/constants/Defaults';
import isNil from 'ramda/src/isNil';
import isEmpty from 'ramda/src/isEmpty';

export const parseIntWithNVFallback = value => {
    if (isNil(value)) {
        return Defaults.NO_VALUE;
    } else {
        return parseInt(value, 10);
    }
};

export const parseFloatWithNVFallback = value => {
    if (isNil(value)) {
        return Defaults.NO_VALUE;
    } else {
        return parseFloat(value);
    }
};

export const parseIntWithNAFallback = value => {
    if (isNil(value)) {
        return Defaults.NOT_AVAILABLE;
    } else {
        return parseInt(value, 10);
    }
};

export const parseFloatWithNAFallback = value => {
    if (isNil(value)) {
        return Defaults.NOT_AVAILABLE;
    } else {
        return parseFloat(value);
    }
};

export const parseStringWithNAFallback = value => {
    if (isNil(value) || isEmpty(value)) {
        return Defaults.NOT_AVAILABLE;
    } else {
        return value;
    }
};

export const parseLocationLabel = value => {
    if (!isNil(value) && typeof value === 'string') {
        return value.replace(/,/g, ', ');
    } else {
        return null;
    }
};
