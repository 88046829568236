export const CREATED_FROM_ADDKW_DROPDOWN = 'CREATED_FROM_ADDKW_DROPDOWN';
export const CREATED_FROM_BULK_DROPDOWN = 'CREATED_FROM_BULK_DROPDOWN';
export const CREATED_FROM_DROPDOWN = 'CREATED_FROM_DROPDOWN';
export const ESTIMATED_VISITS_CHANGE_KEY = 'estimatedVisitsTotalChange';
export const MAX_KEYWORD_LENGTH = 100;
export const NEW_REPORT_ID = 'NEW_REPORT_ID';
export const NO_REPORT_ID = 'NO_REPORT_ID';
export const NUMBER_OF_RANKERS = 10;
export const PERFORMANCE_INDEX_CHANGE_KEY = 'performanceIndexChange';
export const PROJECT_RIGHT_ID = 'PROJECT_RIGHT_ID';
export const COPY_TO_CLIPBOARD_SEPARATOR = '\n';
export const DEFAULT_DEBOUNCE_MS = 50;
export const INPUT_VALIDATION_DEBOUNCE_MS = 500;
