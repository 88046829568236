import update from 'immutability-helper';
import concat from 'ramda/src/concat';

import ActionTypes from 'constants/ActionTypes';

const initialState = {
    data: [],
    error: {
        status: null,
        text: null,
    },
    errorNew: {
        status: null,
        text: null,
    },
    fetching: false,
    fetchingNew: false,
    fetched: false,
    patches: {},
};

const trackingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_TRACKINGS_FETCHING: {
            return update(state, {
                fetching: {
                    $set: true,
                },
            });
        }
        case ActionTypes.DATA_NEW_TRACKING_FETCHING: {
            return update(state, {
                fetchingNew: {
                    $set: true,
                },
            });
        }
        case ActionTypes.DATA_NEW_TRACKING_RECEIVED: {
            return update(state, {
                data: {
                    $apply: trackings => concat([...action.payload.data], trackings),
                },
                fetchingNew: {
                    $set: false,
                },
                errorNew: {
                    $set: initialState.errorNew,
                },
            });
        }
        case ActionTypes.DATA_TRACKINGS_RECEIVED: {
            return update(state, {
                data: {
                    $set: action.payload,
                },
                fetching: {
                    $set: false,
                },
                fetched: {
                    $set: true,
                },
                error: {
                    $set: initialState.error,
                },
            });
        }
        case ActionTypes.DATA_NEW_TRACKING_ERROR: {
            return update(state, {
                fetchingNew: {
                    $set: false,
                },
                errorNew: {
                    status: {
                        $set: action.payload.status,
                    },
                    text: {
                        $set: action.payload.text,
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKINGS_ERROR: {
            return update(state, {
                data: {
                    $set: initialState.data,
                },
                fetching: {
                    $set: false,
                },
                fetched: {
                    $set: true,
                },
                error: {
                    status: {
                        $set: action.payload.status,
                    },
                    text: {
                        $set: action.payload.text,
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKINGS_DELETE_FETCHING: {
            return update(state, {
                fetching: {
                    $set: true,
                },
            });
        }
        case ActionTypes.DATA_TRACKINGS_DELETE_RECEIVED: {
            const { id, isWithDeleted } = action.payload;
            return update(state, {
                data: {
                    ...(!isWithDeleted
                        ? {
                            $apply: trackings => trackings.filter(tracking => tracking.id !== action.payload.id),
                        }
                        : {}),
                    ...(isWithDeleted
                        ? {
                            $apply: trackings =>
                                trackings.map(tracking => {
                                    if (tracking.id === id) {
                                        return update(tracking, { isDeleted: { $set: true } });
                                    } else {
                                        return tracking;
                                    }
                                }),
                        }
                        : {}),
                },
                fetching: {
                    $set: false,
                },
            });
        }
        case ActionTypes.DATA_TRACKINGS_RESTORE_FETCHING: {
            return update(state, {
                fetching: {
                    $set: true,
                },
            });
        }
        case ActionTypes.DATA_TRACKINGS_RESTORE_RECEIVED: {
            return update(state, {
                data: {
                    $apply: trackings =>
                        trackings.map(tracking => {
                            if (tracking.id === action.payload.id) {
                                return update(tracking, { isDeleted: { $set: action.payload.isDeleted } });
                            } else {
                                return tracking;
                            }
                        }),
                },
                fetching: {
                    $set: false,
                },
            });
        }
        case ActionTypes.DATA_TRACKINGS_RESTORE_ERROR: {
            return update(state, {
                fetching: {
                    $set: false,
                },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_RECEIVED: {
            return update(state, {
                data: {
                    $apply: trackings =>
                        trackings.map(tracking => {
                            if (tracking.id === action.payload.trackingId) {
                                return update(tracking, {
                                    trackedKeywordsCount: {
                                        $apply: count => count + action.payload.keywordIds.length,
                                    },
                                });
                            } else {
                                return tracking;
                            }
                        }),
                },
            });
        }
        case ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_SELECTED_RECEIVED: {
            return update(state, {
                data: {
                    $apply: trackings =>
                        trackings.map(tracking => {
                            if (tracking.id === action.payload.trackingId) {
                                return update(tracking, {
                                    trackedKeywordsCount: {
                                        $apply: count => count - action.payload.keywordIds.length,
                                    },
                                });
                            } else {
                                return tracking;
                            }
                        }),
                },
            });
        }
        case ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_RECEIVED: {
            return update(state, {
                data: {
                    $apply: trackings =>
                        trackings.map(tracking => {
                            if (tracking.id === action.payload.trackingId) {
                                return update(tracking, {
                                    trackedKeywordsCount: {
                                        $apply: count => count - 1,
                                    },
                                });
                            } else {
                                return tracking;
                            }
                        }),
                },
            });
        }
        case ActionTypes.DATA_TRACKINGS_DELETE_ERROR: {
            return update(state, {
                fetching: {
                    $set: false,
                },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_ADD_KEYWORDS_RECEIVED: {
            return update(state, {
                data: {
                    $apply: trackings =>
                        trackings.map(tracking => {
                            if (tracking.id === action.payload.data.id) {
                                return update(tracking, {
                                    trackedKeywordsCount: { $set: action.payload.data.keywords.length },
                                });
                            } else {
                                return tracking;
                            }
                        }),
                },
            });
        }
        case ActionTypes.DATA_USER_LOGOUT_RECEIVED: {
            return update(state, {
                data: {
                    $set: initialState.data,
                },
                fetched: {
                    $set: false,
                },
            });
        }
        case ActionTypes.DATA_TRACKING_DOMAIN_OPTIMISTIC_UPDATE: {
            return update(state, {
                data: {
                    $apply: trackings =>
                        trackings.map(tracking => {
                            if (tracking.id === action.payload.id) {
                                return update(tracking, {
                                    domain: { $set: action.payload.domain },
                                    trackingConfig: { $set: action.payload.trackingConfig },
                                });
                            } else {
                                return tracking;
                            }
                        }),
                },
                patches: {
                    $apply: patches => {
                        const currentTracking = state.data.find(tracking => tracking.id === action.payload.id);

                        return update(patches, {
                            $merge: {
                                [ActionTypes.DATA_TRACKING_DOMAIN_OPTIMISTIC_UPDATE_REVERT]: {
                                    id: action.payload.id,
                                    domain: currentTracking.domain,
                                },
                            },
                        });
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_DOMAIN_OPTIMISTIC_UPDATE_REVERT: {
            return update(state, {
                data: {
                    $apply: trackings =>
                        trackings.map(tracking => {
                            if (tracking.id === state.patches[action.type].id) {
                                return update(tracking, { $merge: state.patches[action.type] });
                            } else {
                                return tracking;
                            }
                        }),
                },
                patches: { $clearPatchedAction: action.type },
            });
        }
        case ActionTypes.DATA_TRACKING_UPDATE_DOMAIN_RECEIVED: {
            return update(state, {
                data: {
                    $apply: trackings =>
                        trackings.map(tracking => {
                            if (tracking.id === action.payload.id) {
                                return update(tracking, { domain: { $set: action.payload.domain } });
                            } else {
                                return tracking;
                            }
                        }),
                },
                patches: { $clearPatchedAction: ActionTypes.DATA_TRACKING_DOMAIN_OPTIMISTIC_UPDATE_REVERT },
            });
        }

        case ActionTypes.DATA_TRACKING_UPDATE_DOMAIN_ERROR: {
            return update(state, {
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }

        case ActionTypes.DATA_NEW_REPORT_RECEIVED:
        case ActionTypes.DATA_DELETE_REPORT_RECEIVED: {
            return update(state, {
                data: {
                    $apply: trackings =>
                        trackings.map(tracking => {
                            if (tracking.id === action.payload.trackingId) {
                                const newType = [];
                                action.payload.reports.forEach(report => {
                                    if (report.type === 1 && !newType.includes(1)) {
                                        newType.push(1);
                                    } else if (report.type === 2 && !newType.includes(2)) {
                                        newType.push(2);
                                    }
                                });
                                return update(tracking, { reportsActive: { $set: newType } });
                            } else {
                                return tracking;
                            }
                        }),
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default trackingsReducer;
