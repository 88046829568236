import { createSelector } from 'reselect';
import isNil from 'ramda/src/isNil';
import sortWith from 'ramda/src/sortWith';
import descend from 'ramda/src/descend';
import ascend from 'ramda/src/ascend';
import prop from 'ramda/src/prop';
import omit from 'ramda/src/omit';
import queryString from 'query-string';

import config from 'appConfig';

import RouterService from 'services/RouterService';

import { NO_REPORT_ID, NEW_REPORT_ID } from 'constants/Other';
import RoutePaths from 'constants/RoutePaths';
import { equals } from 'ramda';
import { initialState as TRACKING_FILTER_INITIAL_STATE } from 'reducers/ui/trackingFilterReducer';

// ==============================================
// Shared router selectors
// ==============================================
export const currentQuerySelector = state => state.routing.locationBeforeTransitions.query;
export const currentRouteSelector = state => state.routing.locationBeforeTransitions.pathname;
export const isReportRouteSelector = createSelector(currentRouteSelector, currentRoute =>
    RouterService.isSame(currentRoute, RoutePaths.REPORT),
);
export const isNewTrackingRouteSelector = createSelector(currentRouteSelector, currentRoute =>
    RouterService.isSame(currentRoute, RoutePaths.NEW),
);

// ==============================================
// Share defaults selectors
// ==============================================
export const defaultSortingSettingsSelector = state => state.ui.defaults.sortingSettings;
export const defaultTrackingGroupOrderedKeysSelector = state => state.ui.defaults.trackingGroupOrderedKeys;

// ==============================================
// Shared user selectors
// ========================================= `o=====
export const emailSelector = state => state.user.email;
export const limitsFetchedSelector = state => state.user.limits.fetched;
export const fetchedSelector = state => state.user.fetched;

// ==============================================
// Shared data selectors
// ==============================================

// Reports
export const reportsDataSelector = state => state.data.reports.data;

// Tracked keywords
export const trackedKeywordsCountSelector = state =>
    state.data.trackings.data.reduce((count, tracking) => {
        if (tracking.isDeleted) {
            return count;
        }
        return count + tracking.trackedKeywordsCount;
    }, 0);

export const trackingsCountSelector = state => state.data.trackings.data.length;

// Tracking detail
const trackingDetailAnnotationsDataSelector = state => state.data.trackingDetail.data.annotations.data;
/* eslint-disable max-len */
export const trackingDetailAnnotationsFetchingCreateSelector = state =>
    state.data.trackingDetail.data.annotations.fetchingCreate;
/* eslint-enable max-len */

// ==============================================
// Shared UI selectors
// ==============================================

// Add keywords panel
export const addKeywordsPanelSelectedListIdsSelector = state => state.ui.panels.addKeywords.selectedListIds;

// Tracking
export const trackingFilterSettingsSelector = state => state.ui.trackingFilter.settings;
export const trackingFilterSettingsActiveSelector = state => state.ui.trackingFilter.settings.active;
export const trackingQuickFilterSettingsSelector = state => state.ui.trackingFilter.quickSettings;
export const trackingQuickFilterTagIdsSelector = state => state.ui.trackingFilter.quickSettings.tagIds;
export const trackingSelectedKeywordIdsSelector = state => state.ui.tracking.selectedKeywordIds;
export const trackingTimeframeSelector = state => state.ui.tracking.timeframe;

// Keywords
export const selectedSearchVolumeTimeframeTypeSelector = state => state.ui.dashboard.selectedSearchVolumeTimeframe;
export const resultsDataSelector = state => state.data.results.data;

// New tracking
export const newTrackingSelectedListIdsSelector = state => state.ui.newTracking.selectedListIds;
export const newTrackingFilterSettingsSelector = state => state.ui.newTracking.settings;

// Reports
const reportsPanelRawCurrentReportIdSelector = state => state.ui.panels.reports.currentReportId;
export const reportsPanelCurrentReportIdSelector = createSelector(
    [reportsPanelRawCurrentReportIdSelector, reportsDataSelector],
    (currentId, reports) => {
        if (isNil(currentId)) {
            if (reports.length > 0) {
                return NO_REPORT_ID;
            } else {
                return NEW_REPORT_ID;
            }
        } else {
            return currentId;
        }
    },
);

// Messages
/* eslint-disable max-len */
export const topLevelAnnouncementMessageVisibilitySelector = state =>
    state.ui.messages.topLevel.announcement.visibility;
/* eslint-enable max-len */

// Messages
export const annotationsMessageVisibleDateSelector = state => state.ui.messages.annotations.visibleDate;

// Tracking List
export const trackingListSelector = state => state.ui.trackingList;

export const trackingListFilterSelector = createSelector([trackingListSelector], trackingList => trackingList.filter);
export const trackingListOpenedCardsSelector = createSelector(
    [trackingListSelector],
    trackingList => trackingList.openedTrackingCardIds,
);

// Dropdowns

export const trackingListDropdownSearchSelector = state => state.ui.dropdowns.trackingList.search;
export const locationsgListDropdownSearchSelector = state => state.ui.dropdowns.locationsList.search;

// ==============================================
// Composed reselectors
// ==============================================

export const areTrackingFilterSettingsDirtySelector = createSelector(
    [trackingFilterSettingsSelector],
    filterSettings => !equals(filterSettings, TRACKING_FILTER_INITIAL_STATE.settings),
);

export const areTrackingKeywordsFilteredSelector = createSelector(
    [trackingFilterSettingsActiveSelector, areTrackingFilterSettingsDirtySelector, trackingQuickFilterSettingsSelector],
    (isFilterActive, isFilterDirty, quickFilter) => {
        if (!equals(quickFilter, TRACKING_FILTER_INITIAL_STATE.quickSettings) || (isFilterActive && isFilterDirty)) {
            return true;
        }

        return false;
    },
);

export const trackingDetailSortedAnnotationsDataSelector = createSelector(
    [trackingDetailAnnotationsDataSelector],
    annotations => sortWith([descend(prop('createdAt')), ascend(prop('type'))], annotations),
);

export const annotationsMesssageDataSelector = createSelector(
    [trackingDetailSortedAnnotationsDataSelector, annotationsMessageVisibleDateSelector],
    (annotations, date) => {
        if (!isNil(date)) {
            return annotations.filter(annotation => annotation.date === date);
        } else {
            return [];
        }
    },
);

export const signInRedirectSelector = createSelector([currentRouteSelector, currentQuerySelector], (route, query) =>
    queryString.stringifyUrl({
        url: `${config.APPS_ROOT_HOST}${route}`,
        query: omit(['sso_ticket', 'login_token'], query),
    }),
);
