import React from 'react';
import { bool, func } from 'prop-types';
import MessageHolder from 'components/messages/MessageHolder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function UnauthorizedMessage(props) {
    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose}>
                <div className="mg-modal-header is-error">
                    <FontAwesomeIcon icon={['far', 'hand-paper']} aria-hidden="true" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold">Unauthorized</h3>
                    <p>We&apos;re sorry, you are not authorized to see this tracking&apos;s data.</p>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}

UnauthorizedMessage.propTypes = {
    onClose: func.isRequired,
    visible: bool.isRequired,
};

export default UnauthorizedMessage;
