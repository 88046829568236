import config from 'appConfig';
import { parse } from 'sources/parsers/KeywordDataParser';

import HumanService from 'mangools-commons/lib/services/HumanService';
import ErrorCodes from 'mangools-commons/lib/constants/ErrorCodes';

// Constants
const ENDPOINT = '/keywords';

/**
 * Data source class handling communication with API for keywords data.
 */
class KeywordSource {
    static getData(accessToken, keywordIds) {
        const requestURL = `${config.API_HOST}${config.KWFINDER_API_PATH}${ENDPOINT}`;

        // Generate form data
        const formData = new FormData(); // eslint-disable-line no-undef
        formData.append('keywords_ids', keywordIds);

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken || '',
                'X-Human-Token': HumanService.gen(),
                'X-Mangools-Client': 'app',
            },
            body: JSON.stringify({
                fields: 'kw',
                keyword_ids: keywordIds,
            }),
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parse(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default KeywordSource;
