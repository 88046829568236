export const HTTP = {
    iconName: 'unlock',
    id: 1,
    label: 'http://',
    raw: 'http://',
};

export const HTTPS = {
    iconName: 'lock',
    id: 2,
    label: 'https://',
    raw: 'https://',
};

const PROTOCOLS = [HTTP, HTTPS];

export default PROTOCOLS;
