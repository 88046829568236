import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { connect } from 'react-redux';

import SerpModal from 'components/modals/tracking/SerpModal';
import ShareModal from 'components/modals/tracking/ShareModal';

import { closeCreatedTrackingTipModal, closeShareModal, closeTrackingResultSerpModal } from 'actions/uiActions';

import { requestedSnapshotImageAction, requestedTrackingShareTokenUpdateAction } from 'actions/dataActions';

import {
    trackingDetailIdSelector,
    trackingDetailLocationIdSelector,
    trackingDetailPlatformIdSelector,
    trackingDetailShareTokenSelector,
    trackingKeywordDetailDataSelector,
    trackingDetailFetchingShareTokenSelector,
} from 'selectors/dataSelectors';

import {
    serpModalVisibilitySelector,
    shareModalVisibilitySelector,
    serpSnapshotFetchingSelector,
    createdTrackingVisibleTipModalSelector,
} from 'selectors/uiSelectors';

import TrackingKeywordDetailType from 'types/TrackingKeywordDetailType';
import CreatedTrackingTipsModal from 'components/modals/tracking/CreatedTrackingTipsModal';

function ModalContainer(props) {
    return (
        <div className="modals">
            <SerpModal
                keyword={props.trackingKeywordDetailData.keyword}
                locationId={props.currentTrackingLocationId}
                onClose={props.onCloseSerpModal}
                platformId={props.currentTrackingPlatformId}
                visible={props.serpModalVisible}
                requestSnapshotImage={props.requestSnapshotImage}
                serpSnapshotId={props.trackingKeywordDetailData.serpSnapshotId}
                fetchingSnapshot={props.serpSnapshotFetching}
            />
            <ShareModal
                onClose={props.onCloseShareModal}
                shareToken={props.trackingDetailShareToken}
                trackingId={props.trackingDetailId}
                visible={props.shareModalVisible}
                fetchingToken={props.trackingDetailFetchingShareToken}
                requestTrackingShareTokenUpdate={props.requestTrackingShareTokenUpdate}
            />
            <CreatedTrackingTipsModal
                onClose={props.onCloseCreatedTrackingTipsModal}
                visibleModalTip={props.visibleModalTip}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    currentTrackingLocationId: trackingDetailLocationIdSelector(state),
    currentTrackingPlatformId: trackingDetailPlatformIdSelector(state),
    serpModalVisible: serpModalVisibilitySelector(state),
    shareModalVisible: shareModalVisibilitySelector(state),
    visibleModalTip: createdTrackingVisibleTipModalSelector(state),
    trackingDetailId: trackingDetailIdSelector(state),
    trackingDetailShareToken: trackingDetailShareTokenSelector(state),
    serpSnapshotFetching: serpSnapshotFetchingSelector(state),
    trackingKeywordDetailData: trackingKeywordDetailDataSelector(state),
    trackingDetailFetchingShareToken: trackingDetailFetchingShareTokenSelector(state),
});

const mapDispatchToProps = dispatch => ({
    onCloseShareModal() {
        dispatch(closeShareModal());
    },
    onCloseCreatedTrackingTipsModal() {
        dispatch(closeCreatedTrackingTipModal());
    },
    onCloseSerpModal() {
        dispatch(closeTrackingResultSerpModal());
    },
    requestSnapshotImage() {
        dispatch(requestedSnapshotImageAction());
    },
    requestTrackingShareTokenUpdate() {
        dispatch(requestedTrackingShareTokenUpdateAction());
    },
});

ModalContainer.propTypes = {
    currentTrackingLocationId: number,
    currentTrackingPlatformId: number,
    visibleModalTip: number,
    onCloseSerpModal: func.isRequired,
    onCloseShareModal: func.isRequired,
    serpModalVisible: bool.isRequired,
    shareModalVisible: bool.isRequired,
    trackingDetailId: string,
    trackingDetailShareToken: string,
    requestSnapshotImage: func.isRequired,
    serpSnapshotFetching: bool.isRequired,
    trackingKeywordDetailData: TrackingKeywordDetailType.isRequired,
    requestTrackingShareTokenUpdate: func.isRequired,
    onCloseCreatedTrackingTipsModal: func.isRequired,
    trackingDetailFetchingShareToken: bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
