import React, { useEffect, useCallback, useRef } from 'react';
import { useDropzone, FileRejection, FileError } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { isNil, isEmpty } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';

import { setCustomizeReportPanelDirty } from 'actions/uiActions';

import { whiteLabelSettingsSelector } from 'selectors/userSelectors';

import { CustomizeReportFormActionType } from 'components/panels/CustomizeReportPanel/CustomizeReportPanelContainer';

interface CustomizeReportPanelLogoUploadProps {
    logo: File | null;
    error: FileError | null;
    onDispatch: (action: CustomizeReportFormActionType) => void;
    currentLogoUrl: string;
    onReset: (payload: boolean) => void;
}

export const CustomizeReportPanelLogoUpload: React.FC<CustomizeReportPanelLogoUploadProps> = ({
    logo,
    onDispatch,
    currentLogoUrl,
    onReset,
    error,
}: CustomizeReportPanelLogoUploadProps) => {
    const dispatch = useDispatch();
    const readerRef = useRef(new FileReader());
    const { logo: uploadedLogo }: { logo: string | null } = useSelector(whiteLabelSettingsSelector);

    const handleReaderLoad = (e: any) => {
        onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'logo', value: e.target.result } });
    };

    useEffect(() => {
        readerRef.current = new FileReader();
        readerRef.current.addEventListener('load', handleReaderLoad);
    }, []);

    useEffect(() => {
        if (!isNil(logo)) {
            readerRef.current.readAsDataURL(logo);
        }
    }, [logo]);

    const handleResetLogo = (e: any) => {
        e.stopPropagation();

        onDispatch({ type: 'SET_FIELD_TOUCHED', payload: { field: 'logoFile' } });

        if (!isNil(error)) {
            onDispatch({ type: 'SET_FIELD_ERROR', payload: { field: 'logoFile', value: null } });
        } else if (currentLogoUrl.startsWith('data:image')) {
            const value = isNil(uploadedLogo) ? '' : uploadedLogo;

            onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'logo', value } });
        } else if (currentLogoUrl === uploadedLogo) {
            onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'logo', value: '' } });
            onReset(true);
        }

        // onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'logoFile', value: null } });
        onDispatch({ type: 'SET_FIELD_ERROR', payload: { field: 'logoFile', value: null } });
    };

    const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        dispatch(setCustomizeReportPanelDirty());
        onReset(false);

        if (acceptedFiles.length > 0) {
            onDispatch({
                type: 'SET_FIELD_VALUE',
                payload: { field: 'logoFile', value: acceptedFiles[0] },
            });
            onDispatch({ type: 'SET_FIELD_ERROR', payload: { field: 'logoFile', value: null } });
        }

        if (rejectedFiles.length > 0) {
            onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'logoFile', value: null } });
            // onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'logo', value: '' } });
            onDispatch({
                type: 'SET_FIELD_ERROR',
                payload: { field: 'logoFile', value: rejectedFiles[0].errors[0] },
            });
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop,
        accept: 'image/png',
        maxSize: 200000,
    });

    return (
        <>
            <div
                className={classnames('mg-margin-b-30', 'uk-position-relative', 'sw-customize-report-panel-logo', {
                    'is-importing': isDragActive,
                    'is-reject': (isDragActive && isDragReject) || (!isDragActive && !isNil(error)),
                })}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getRootProps()}
            >
                {/*  eslint-disable-next-line react/jsx-props-no-spreading */}
                <div className="mg-card uk-position-top-right uk-flex sw-customize-report-panel-logo-controls">
                    <button
                        type="reset"
                        className="mg-icon-btn is-white-red is-xsmall mg-margin-r-5"
                        onClick={handleResetLogo}
                        title="Delete logo"
                    >
                        <FontAwesomeIcon
                            icon={['far', 'trash-alt']}
                            aria-hidden="true"
                            className="uk-margin-remove font-10"
                        />
                    </button>
                    <button
                        type="button"
                        onClick={() => null}
                        className="mg-icon-btn is-xsmall is-white-blue"
                        title="Upload a new logo"
                    >
                        <FontAwesomeIcon icon="arrow-up" aria-hidden="true" className="uk-margin-remove font-10" />
                    </button>
                </div>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <input {...getInputProps()} />
                {!isEmpty(currentLogoUrl) && (
                    <div
                        className={classnames(
                            'sw-customize-report-panel-logo-preview uk-position-relative uk-text-center',
                        )}
                    >
                        <div
                            style={{
                                backgroundImage: `url(${currentLogoUrl})`,
                            }}
                        />
                    </div>
                )}
                {isEmpty(currentLogoUrl) && (
                    <div
                        style={{ height: '120px' }}
                        // eslint-disable-next-line max-len
                        className="mg-card is-placeholder font-14 mg-padding-15 uk-text-center uk-flex uk-flex-center uk-flex-middle"
                    >
                        <div>
                            <strong className="font-22 uk-visible-large">
                                <FontAwesomeIcon icon="cloud-upload-alt" className="mg-margin-r-5" />
                                Drag &amp; Drop
                            </strong>
                            <div className="mg-margin-b-10 uk-visible-large">your logo here or </div>
                            <button
                                type="button"
                                onClick={() => null}
                                className="mg-btn is-xsmall is-blue mg-margin-r-5"
                            >
                                Choose a file (.png)
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
