import React, { Component } from 'react';
import { bool, func, number } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MessageHolder from 'components/messages/MessageHolder';
import { formatEstimatedTime } from 'utils/formatEstimatedTime';

class NewTrackingInfoMessage extends Component {
    constructor() {
        super();
        this.renderPostponedMessage = this.renderPostponedMessage.bind(this);
    }

    renderPostponedMessage() {
        if (this.props.postponedProcessing === true) {
            return (
                <div className="mg-alert mg-margin-t-10 is-warning font-14">
                    <h4 className="font-18">Rank evaluation postponed</h4>
                    <p>
                        Some of your keywords will have&nbsp; rank evaluation postponed&nbsp; due to reaching your&nbsp;
                        <strong>
                            {this.props.keywordsLimit}/{this.props.keywordsLimit}
                        </strong>
                        &nbsp; rank evaluations per 24h limit.
                    </p>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        if (this.props.visible) {
            return (
                <MessageHolder onClose={this.props.onClose} classNames="uk-padding-remove">
                    <div className="mg-modal-header is-success">
                        <FontAwesomeIcon icon="check" aria-label="Success" />
                    </div>
                    <div className="mg-modal-content">
                        <h3 className="font-30 uk-text-bold">Tracking was created</h3>
                        <p>
                            Your ranks will be ready{' '}
                            <strong className="color-black">
                                in about {formatEstimatedTime(this.props.estimatedTimeProcessing)}
                            </strong>
                            . Don&apos;t forget to <strong className="color-black">reload the tracking</strong>
                            &nbsp;to see the newest rank tracking data.
                        </p>
                        {this.renderPostponedMessage()}
                        <button
                            className="mg-btn is-blue is-small uk-text-bold mg-margin-t-15"
                            onClick={this.props.onClose}
                            type="button"
                        >
                            Ok, got it
                        </button>
                    </div>
                </MessageHolder>
            );
        } else {
            return null;
        }
    }
}

NewTrackingInfoMessage.propTypes = {
    keywordsLimit: number.isRequired,
    estimatedTimeProcessing: number,
    onClose: func.isRequired,
    postponedProcessing: bool.isRequired,
    visible: bool.isRequired,
};

export default NewTrackingInfoMessage;
