import ActionTypes from 'constants/ActionTypes';
import { ReceivedSettingsAction } from 'types/actions/userActionsTypes';
import { UserCustomizeResultsState, UserWhitelabelState } from 'types/state/userReducer';

export const fetchingAction = () => ({
    type: ActionTypes.DATA_USER_DATA_FETCHING,
});

export const receivedAction = (payload: any) => ({
    type: ActionTypes.DATA_USER_DATA_RECEIVED,
    error: false,
    payload,
});

export const errorAction = (payload: any) => ({
    type: ActionTypes.DATA_USER_DATA_ERROR,
    error: true,
    payload,
});

export const requestedLimitsAction = () => ({
    type: ActionTypes.DATA_USER_LIMIT_DATA_REQUESTED,
});

export const fetchingLimitsAction = () => ({
    type: ActionTypes.DATA_USER_LIMIT_DATA_FETCHING,
});

export const receivedLimitsAction = (payload: any) => ({
    type: ActionTypes.DATA_USER_LIMIT_DATA_RECEIVED,
    error: false,
    payload,
});

export const errorLimitsAction = (payload: any) => ({
    type: ActionTypes.DATA_USER_LIMIT_DATA_ERROR,
    error: true,
    payload,
});

export const skippedLimitsAction = () => ({
    type: ActionTypes.DATA_USER_LIMIT_DATA_SKIPPED,
});

export const receivedLogoutAction = () => ({
    type: ActionTypes.DATA_USER_LOGOUT_RECEIVED,
});

export const requestedLogoutAction = () => ({
    type: ActionTypes.DATA_USER_LOGOUT_REQUESTED,
});

export const errorLogoutAction = (payload: any) => ({
    type: ActionTypes.DATA_USER_LOGOUT_ERROR,
    error: true,
    payload,
});

export const errorCheckStatusAction = (payload: any) => ({
    type: ActionTypes.DATA_USER_CHECK_STATUS_ERROR,
    error: true,
    payload,
});

export const skippedCheckStatusAction = () => ({
    type: ActionTypes.DATA_USER_CHECK_STATUS_SKIPPED,
});

export const requestedHideAuthTokensAction = () => ({
    type: ActionTypes.ROUTER_AUTH_TOKENS_HIDE_REQUESTED,
});

export const setAuthTokens = ({ ssoTicket, loginToken }: { ssoTicket: string; loginToken: string }) => ({
    type: ActionTypes.DATA_USER_AUTH_TOKENS_RECEIVED,
    error: false,
    payload: {
        ssoTicket,
        loginToken,
    },
});

export const skippedUserAction = () => ({
    type: ActionTypes.DATA_USER_DATA_SKIPPED,
});

export const requestedUserSettingsUpdateAction = (payload: {
    settings: { logo: File | null } & Omit<UserWhitelabelState, 'logo'>;
    removeLogo: boolean;
}) => ({
    type: ActionTypes.DATA_USER_SETTINGS_UPDATE_REQUESTED,
    payload,
});

export const receivedUserSettingsAction = (payload: ReceivedSettingsAction) => ({
    type: ActionTypes.DATA_USER_DATA_SETTINGS_UPDATE_RECEIVED,
    payload,
});

export const errorUpdateUserWhiteLabelSettingsAction = (payload: any) => ({
    type: ActionTypes.DATA_USER_SETTINGS_UPDATE_ERROR,
    error: true,
    payload,
});

export const fetchingUpdateUserSettingsAction = () => ({
    type: ActionTypes.DATA_USER_SETTINGS_UPDATE_FETCHING,
});

export const requestedToggleWhitelabelAction = (payload: boolean) => ({
    type: ActionTypes.DATA_USER_TOGGLE_WHITELABEL_REQUESTED,
    payload,
});

export const receivedToggleWhitelabelAction = (payload: boolean) => ({
    type: ActionTypes.DATA_USER_TOGGLE_WHITELABEL_RECEIVED,
    payload,
});

export const errorToggleWhitelabelAction = (payload: any) => ({
    type: ActionTypes.DATA_USER_TOGGLE_WHITELABEL_ERROR,
    error: true,
    payload,
});

export const setUnleashSessionAction = (payload: { unleashSession: string }) => ({
    type: ActionTypes.DATA_USER_SET_UNLEASH_SESSION,
    error: true,
    payload,
});

export const clearErrorToggleWhitelabelAction = () => ({
    type: ActionTypes.DATA_USER_TOGGLE_WHITELABEL_ERROR_CLEAR,
});

export const fetchingToggleWhitelabelAction = () => ({
    type: ActionTypes.DATA_USER_TOGGLE_WHITELABEL_FETCHING,
});

export const requestedCustomizationSettingsUpdateAction = (payload: UserCustomizeResultsState) => ({
    type: ActionTypes.DATA_USER_CUSTOMIZE_SETTINGS_UPDATE_REQUESTED,
    payload,
});

export const errorCustomizationSettingsUpdateAction = (payload: UserCustomizeResultsState) => ({
    type: ActionTypes.DATA_USER_CUSTOMIZE_SETTINGS_UPDATE_ERROR,
    payload,
});
