import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    accessDenied: {
        visibility: false,
    },
    addedKeywords: {
        keywords: [],
        postponedProcessing: false,
        estimatedTimeProcessing: null,
        visibility: false,
    },
    annotations: {
        visibleDate: null,
    },
    createTracking: {
        domain: null,
        fetching: false,
        platformLabel: '',
        templateTrackingId: null,
        visibility: false,
        estimatedTimeProcessing: null,
    },
    upsell: {
        visibility: false,
    },
    deleteConfirmation: {
        resourceName: '',
        resourceType: '',
        visibility: false,
        details: null,
    },
    export: {
        visibility: false,
    },
    failure: {
        visibility: false,
        details: null,
        header: null,
    },
    loggedOut: {
        visibility: false,
    },
    needToSignIn: {
        visibility: false,
    },
    needToSelectKeyword: {
        visibility: false,
    },
    newTrackingInfo: {
        postponedProcessing: false,
        estimatedTimeProcessing: null,
        visibility: false,
    },
    noConnection: {
        visibility: false,
    },
    pricing: {
        visibility: false,
    },
    shortcuts: {
        visibility: false,
    },
    topLevel: {
        announcement: {
            visibility: true,
        },
    },
    trackingAlreadyExists: {
        keywords: [],
        trackingId: null,
        visibility: false,
    },
    unauthorized: {
        visibility: false,
    },
    invalidShareToken: {
        visibility: false,
    },
    editTracking: {
        visibility: false,
        tracking: {
            id: null,
            domain: null,
            location: null,
            platformId: null,
        },
    },
    importKwfList: {
        visibility: false,
        importTarget: null,
    },
};

const messagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_SHOW: {
            return update(state, {
                deleteConfirmation: {
                    visibility: {
                        $set: true,
                    },
                    resourceName: {
                        $set: action.payload.resourceName,
                    },
                    resourceType: {
                        $set: action.payload.resourceType,
                    },
                    details: {
                        $set: action.payload.details,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM:
        case ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CLOSE: {
            return update(state, {
                deleteConfirmation: {
                    visibility: {
                        $set: false,
                    },
                    resourceName: {
                        $set: initialState.deleteConfirmation.resourceName,
                    },
                    resourceType: {
                        $set: initialState.deleteConfirmation.resourceType,
                    },
                    details: {
                        $set: initialState.deleteConfirmation.details,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_FAILURE_SHOW: {
            return update(state, {
                failure: {
                    visibility: {
                        $set: true,
                    },
                    details: {
                        $set: action.payload.details,
                    },
                    header: {
                        $set: action.payload.header,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_FAILURE_CLOSE: {
            return update(state, {
                failure: {
                    visibility: {
                        $set: false,
                    },
                    details: {
                        $set: null,
                    },
                    header: {
                        $set: null,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_UNAUTHORIZED_SHOW: {
            return update(state, {
                unauthorized: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_UNAUTHORIZED_CLOSE: {
            return update(state, {
                unauthorized: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEED_TO_SELECT_KEYWORD_SHOW: {
            return update(state, {
                needToSelectKeyword: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEED_TO_SELECT_KEYWORD_CLOSE: {
            return update(state, {
                needToSelectKeyword: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_LOGGED_OUT_SHOW: {
            return update(state, {
                loggedOut: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_LOGGED_OUT_CLOSE: {
            return update(state, {
                loggedOut: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.DATA_LISTS_EXPORT_REQUESTED: {
            return update(state, {
                export: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.DATA_SELECTED_KW_EXPORT_REQUESTED: {
            return update(state, {
                export: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.DATA_EXPORT_CANCELED: {
            return update(state, {
                export: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.DATA_EXPORT_RECEIVED: {
            return update(state, {
                export: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.DATA_EXPORT_ERROR: {
            return update(state, {
                export: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_SHOW: {
            return update(state, {
                needToSignIn: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE: {
            return update(state, {
                needToSignIn: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_PRICING_SHOW: {
            return update(state, {
                pricing: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_PRICING_CLOSE: {
            return update(state, {
                pricing: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_ACCESS_DENIED_SHOW: {
            return update(state, {
                accessDenied: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_ACCESS_DENIED_CLOSE: {
            return update(state, {
                accessDenied: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE: {
            return update(state, {
                topLevel: {
                    announcement: {
                        visibility: {
                            $set: false,
                        },
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEW_TRACKING_INFO_SHOW: {
            return update(state, {
                newTrackingInfo: {
                    postponedProcessing: {
                        $set: action.payload.postponedProcessing,
                    },
                    estimatedTimeProcessing: {
                        $set: action.payload.estimatedTimeProcessing,
                    },
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEW_TRACKING_INFO_CLOSE: {
            return update(state, {
                newTrackingInfo: {
                    postponedProcessing: {
                        $set: false,
                    },
                    estimatedTimeProcessing: {
                        $set: null,
                    },
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_TRACKING_ALREADY_EXISTS_SHOW: {
            return update(state, {
                trackingAlreadyExists: {
                    keywords: {
                        $set: action.payload.keywords,
                    },
                    trackingId: {
                        $set: action.payload.trackingId,
                    },
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_TRACKING_ALREADY_EXISTS_CLOSE: {
            return update(state, {
                trackingAlreadyExists: {
                    keywords: {
                        $set: [],
                    },
                    trackingId: {
                        $set: null,
                    },
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_CREATE_TRACKING_SHOW: {
            return update(state, {
                createTracking: {
                    domain: {
                        $set: action.payload.domain,
                    },
                    platformLabel: {
                        $set: action.payload.platformLabel,
                    },
                    templateTrackingId: {
                        $set: action.payload.templateTrackingId,
                    },
                    visibility: {
                        $set: true,
                    },
                    estimatedTimeProcessing: {
                        $set: action.payload.estimatedTimeProcessing,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_CREATE_TRACKING_CLOSE: {
            return update(state, {
                createTracking: {
                    domain: {
                        $set: null,
                    },
                    platformLabel: {
                        $set: '',
                    },
                    templateTrackingId: {
                        $set: null,
                    },
                    visibility: {
                        $set: false,
                    },
                    estimatedTimeProcessing: {
                        $set: null,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_UPSELL_SHOW: {
            return update(state, {
                upsell: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_UPSELL_CLOSE: {
            return update(state, {
                upsell: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.DATA_NEW_TRACKING_FETCHING: {
            return update(state, {
                createTracking: {
                    fetching: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.DATA_NEW_TRACKING_ERROR:
        case ActionTypes.DATA_NEW_TRACKING_RECEIVED: {
            return update(state, {
                createTracking: {
                    fetching: {
                        $set: false,
                    },
                    estimatedTimeProcessing: {
                        $set: action.payload.estimatedTimeProcessing,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NO_CONNECTION_SHOW: {
            return update(state, {
                noConnection: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NO_CONNECTION_CLOSE: {
            return update(state, {
                noConnection: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_ADDED_KEYWORDS_SHOW: {
            return update(state, {
                addedKeywords: {
                    keywords: {
                        $set: action.payload.keywords,
                    },
                    postponedProcessing: {
                        $set: action.payload.postponedProcessing,
                    },
                    estimatedTimeProcessing: {
                        $set: action.payload.estimatedTimeProcessing,
                    },
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_ADDED_KEYWORDS_CLOSE: {
            return update(state, {
                addedKeywords: {
                    keywords: {
                        $set: [],
                    },
                    postponedProcessing: {
                        $set: false,
                    },
                    estimatedTimeProcessing: {
                        $set: null,
                    },
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_ANNOTATIONS_SHOW: {
            return update(state, {
                annotations: {
                    visibleDate: {
                        $set: action.payload,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_ANNOTATIONS_CLOSE: {
            return update(state, {
                annotations: {
                    visibleDate: {
                        $set: null,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_SHORTCUTS_SHOW: {
            return update(state, {
                shortcuts: { visibility: { $set: true } },
            });
        }
        case ActionTypes.UI_MESSAGES_SHORTCUTS_CLOSE: {
            return update(state, {
                shortcuts: { visibility: { $set: false } },
            });
        }
        case ActionTypes.UI_MESSAGES_INVALID_SHARE_TOKEN_SHOW: {
            return update(state, {
                invalidShareToken: { visibility: { $set: true } },
            });
        }
        case ActionTypes.UI_MESSAGES_INVALID_SHARE_TOKEN_CLOSE: {
            return update(state, {
                invalidShareToken: { visibility: { $set: false } },
            });
        }
        case ActionTypes.UI_MESSAGES_EDIT_TRACKING_DOMAIN_SHOW: {
            return update(state, {
                editTracking: {
                    visibility: { $set: true },
                    tracking: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_EDIT_TRACKING_DOMAIN_CLOSE: {
            return update(state, {
                editTracking: { $set: initialState.editTracking },
            });
        }
        case ActionTypes.UI_MESSAGES_IMPORT_KWF_LIST_SHOW: {
            return update(state, {
                importKwfList: {
                    visibility: { $set: true },
                    importTarget: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_IMPORT_KWF_LIST_CLOSE: {
            return update(state, {
                importKwfList: { $set: initialState.importKwfList },
            });
        }
        case ActionTypes.UI_ALL_CLOSE: {
            return update(state, {
                accessDenied: {
                    visibility: { $set: false },
                },
                addedKeywords: {
                    keywords: { $set: [] },
                    postponedProcessing: { $set: false },
                    estimatedTimeProcessing: { $set: null },
                    visibility: { $set: false },
                },
                annotations: {
                    visibleDate: { $set: null },
                },
                upsell: {
                    visibility: { $set: false },
                },
                createTracking: {
                    domain: { $set: null },
                    platformLabel: { $set: '' },
                    templateTrackingId: { $set: null },
                    visibility: { $set: false },
                    estimatedTimeProcessing: { $set: null },
                },
                deleteConfirmation: {
                    resourceName: { $set: '' },
                    resourceType: { $set: '' },
                    details: { $set: null },
                    visibility: { $set: false },
                },
                export: {
                    visibility: { $set: false },
                },
                failure: {
                    visibility: { $set: false },
                    details: { $set: null },
                    header: { $set: null },
                },
                needToSelectKeyword: {
                    visibility: { $set: false },
                },
                newTrackingInfo: {
                    postponedProcessing: { $set: false },
                    estimatedTimeProcessing: { $set: null },
                    visibility: { $set: false },
                },
                noConnection: {
                    visibility: { $set: false },
                },
                pricing: {
                    visibility: { $set: false },
                },
                trackingAlreadyExists: {
                    keywords: { $set: [] },
                    trackingId: { $set: null },
                    visibility: { $set: false },
                },
                unauthorized: {
                    visibility: { $set: false },
                },
                shortcuts: {
                    visibility: { $set: false },
                },
                invalidShareToken: {
                    visibility: { $set: false },
                },
                editTracking: {
                    visibility: { $set: false },
                    tracking: { $set: { id: null, domain: null, location: null, platformId: null } },
                },
                importKwfList: { $set: initialState.importKwfList },
            });
        }
        default: {
            return state;
        }
    }
};

export default messagesReducer;
