import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';

import { currentRouteSelector } from 'selectors/commonSelectors';
import useChangeReaction from 'hooks/useChangeReaction';
import { closeCreatedTrackingTipModal, showCreatedTrackingTipModal } from 'actions/uiActions';
import ReportsLayout from 'components/newTracking/step/finish/ReportsLayout';
import WhiteLabelLayout from 'components/newTracking/step/finish/WhiteLabelLayout';
import OrganizeTrackingsLayout from 'components/newTracking/step/finish/OrganizeTrackingsLayout';

import reportsSvg from 'images/weekly-monthly-reports.svg';
import whiteLabelSvg from 'images/white-label-reports.svg';
import trackingTagsSvg from 'images/organize-trackings-with-tags.svg';
import TrackingTipsModalTab from 'components/modals/tracking/TrackingTipsModalTab';

const TIPS_MODAL_CONTAINER_ID = 'TIPS_MODAL_CONTAINER_ID';

const CreatedTrackingTipsModal = ({ visibleModalTip, onClose }: CreatedTrackingTipsModalProps) => {
    const pathname = useSelector(currentRouteSelector);
    const dispatch = useDispatch();

    const handleClick = useCallback(
        stepNumber => {
            dispatch(showCreatedTrackingTipModal(stepNumber));
        },
        [dispatch],
    );

    useChangeReaction(pathname, true, () => {
        dispatch(closeCreatedTrackingTipModal());
    });

    if (visibleModalTip === null) {
        return null;
    }

    const handleModalClick = e => {
        if (e.target.id === TIPS_MODAL_CONTAINER_ID) {
            e.stopPropagation();
            onClose();
        }
    };

    return (
        <div
            id={TIPS_MODAL_CONTAINER_ID}
            className="uk-modal uk-open uk-display-block mg-modal-metrics uk-text-center"
            onClick={handleModalClick}
        >
            <div className="uk-modal-dialog is-pricing uk-padding-remove sw-tracking-tips-modal">
                <button className="mg-close" onClick={onClose} type="button">
                    <FontAwesomeIcon icon="times" aria-label="Close" />
                </button>

                <div className="modal-menu uk-width-1-1 uk-grid-collapse uk-grid">
                    <TrackingTipsModalTab
                        handleClick={handleClick}
                        tabImage={reportsSvg}
                        tabText="Recurring reports"
                        stepNumber={1}
                        activeStepNumber={visibleModalTip}
                    />

                    <TrackingTipsModalTab
                        handleClick={handleClick}
                        tabImage={whiteLabelSvg}
                        tabText="White-labeling"
                        stepNumber={2}
                        activeStepNumber={visibleModalTip}
                    />

                    <TrackingTipsModalTab
                        handleClick={handleClick}
                        tabImage={trackingTagsSvg}
                        tabText="Tracking tag"
                        stepNumber={3}
                        activeStepNumber={visibleModalTip}
                    />
                </div>
                <div className="modal-content uk-margin-large-top uk-width-1-1 font-14">
                    <div className="mg-modal-content sw-overflow-visible uk-text-left">
                        {
                            {
                                1: <ReportsLayout />,
                                2: <WhiteLabelLayout />,
                                3: <OrganizeTrackingsLayout />,
                            }[visibleModalTip]
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

interface CreatedTrackingTipsModalProps {
    onClose(): void;
    visibleModalTip: number | null;
}

export default React.memo(CreatedTrackingTipsModal);
