import React from 'react';
import { node } from 'prop-types';

function MessageListWrapper(props) {
    return <div className="message-list-container uk-flex uk-flex-column">{props.children}</div>;
}

MessageListWrapper.propTypes = {
    children: node.isRequired,
};

export { MessageListWrapper };
