import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bool, func, number, shape, string } from 'prop-types';
import trim from 'ramda/src/trim';
import isNil from 'ramda/src/isNil';
import autosize from 'autosize';
import Urls from 'mangools-commons/lib/constants/Urls';

import AnnotationTypes from 'constants/AnnotationTypes';
import NumberOrNAType from 'types/commons/NumberOrNAType';
import StringOrNAType from 'types/commons/StringOrNAType';

class AnnotationsMessageItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newText: this.getAnnotationText(),
            nonShareable: props.nonShareable,
        };

        this.getAnnotationText = this.getAnnotationText.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleNewTextChange = this.handleNewTextChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isNonEditable = this.isNonEditable.bind(this);
        this.renderAnnotationAction = this.renderAnnotationAction.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.renderIconType = this.renderIconType.bind(this);
        this.updateText = this.updateText.bind(this);

        this.textInput = null;
    }

    componentDidMount() {
        if (!isNil(this.textInput)) {
            autosize(this.textInput);
        }
    }

    shouldComponentUpdate(newProps, newState) {
        return shallowCompare(this, newProps, newState);
    }

    getAnnotationText() {
        switch (this.props.type) {
            case AnnotationTypes.KW_CHANGE_ADD: {
                const change = this.props.content.keywordsCount;
                return `Added ${change} ${change > 1 ? 'keywords' : 'keyword'}`;
            }
            case AnnotationTypes.KW_CHANGE_REMOVE: {
                const change = this.props.content.keywordsCount;
                return `Removed ${change} ${change > 1 ? 'keywords' : 'keyword'}`;
            }
            case AnnotationTypes.VOLATILITY: {
                const volatility = this.props.content.avg;
                return `SERP Volatility: ${volatility}`;
            }
            case AnnotationTypes.GOOGLE: {
                return this.props.content.text;
            }
            case AnnotationTypes.SYSTEM: {
                return this.props.content.text;
            }
            case AnnotationTypes.USER: {
                return this.props.content.text;
            }
            default: {
                return this.props.content.text;
            }
        }
    }

    isNonEditable() {
        if (this.props.report === true) {
            return true;
        } else {
            switch (this.props.type) {
                case AnnotationTypes.KW_CHANGE_ADD: {
                    return true;
                }
                case AnnotationTypes.KW_CHANGE_REMOVE: {
                    return true;
                }
                case AnnotationTypes.VOLATILITY: {
                    return true;
                }
                case AnnotationTypes.SYSTEM: {
                    return true;
                }
                case AnnotationTypes.GOOGLE: {
                    return true;
                }
                case AnnotationTypes.USER: {
                    return false;
                }
                default: {
                    return false;
                }
            }
        }
    }

    handleNewTextChange(e) {
        this.setState({
            newText: e.target.value,
        });
    }

    handleCheckboxChange(e) {
        const nonShareable = e.target.checked;

        const newText = trim(this.state.newText);

        this.setState({
            nonShareable,
        });
        this.props.onEdit({ id: this.props.id, nonShareable, text: newText });
    }

    updateText() {
        const newText = trim(this.state.newText);

        if (newText.length >= 1) {
            if (newText !== this.props.content.text) {
                this.props.onEdit({ id: this.props.id, text: newText });
            } else {
                this.setState({ newText });
            }
        } else {
            this.setState({ newText: this.props.content.text });
        }
    }

    handleDelete() {
        this.props.onDelete(this.props.id);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.textInput.blur();
    }

    handleBlur() {
        this.updateText();
    }

    renderIconType() {
        switch (this.props.type) {
            case AnnotationTypes.KW_CHANGE_ADD: {
                return <FontAwesomeIcon icon="plus-square" aria-label="Keywords added" />;
            }
            case AnnotationTypes.KW_CHANGE_REMOVE: {
                return <FontAwesomeIcon icon="minus-square" aria-label="Keywords addeletedded" />;
            }
            case AnnotationTypes.GOOGLE:
            case AnnotationTypes.VOLATILITY: {
                return <FontAwesomeIcon icon={['fab', 'google']} aria-label="Google change" />;
            }
            case AnnotationTypes.SYSTEM: {
                return <FontAwesomeIcon icon="exclamation-triangle" aria-label="Warning" />;
            }
            case AnnotationTypes.USER: {
                return <FontAwesomeIcon icon="comment" aria-label="Comment" />;
            }
            default: {
                return <FontAwesomeIcon icon="comment" aria-label="Comment" />;
            }
        }
    }

    renderAnnotationAction() {
        if (
            this.props.type === AnnotationTypes.KW_CHANGE_ADD ||
            this.props.type === AnnotationTypes.KW_CHANGE_REMOVE ||
            this.props.type === AnnotationTypes.SYSTEM
        ) {
            return null;
        } else if (this.props.type === AnnotationTypes.VOLATILITY || this.props.type === AnnotationTypes.GOOGLE) {
            return (
                <a
                    className="font-14"
                    href={`${Urls.MANGOOLS_SERP_INSIGHTS_URL}?ref=ann-msg-app-sw`}
                    rel="noopenner"
                    target="_blank"
                >
                    Read more
                </a>
            );
        } else if (this.props.deleting) {
            return (
                <span className="mg-preloader-inline is-black">
                    <span />
                    <span />
                    <span />
                </span>
            );
        } else if (this.props.report === false) {
            return (
                <button className="mg-icon-btn" onClick={this.handleDelete} title="Delete annotation" type="button">
                    <FontAwesomeIcon
                        aria-label="Delete annotation"
                        icon={['far', 'trash-alt']}
                        className="uk-margin-remove"
                    />
                </button>
            );
        } else {
            return null;
        }
    }

    renderBody() {
        if (this.isNonEditable()) {
            return (
                <div className="uk-position-relative uk-width-1-1 uk-text-left">
                    <div className="font-16 uk-width-1-1">{this.getAnnotationText()}</div>
                </div>
            );
        } else {
            return (
                <form onSubmit={this.handleSubmit} className="uk-position-relative uk-width-1-1">
                    <textarea
                        className="mg-editable is-right font-16 uk-width-1-1 sw-annotation-input"
                        disabled={this.props.updating}
                        maxLength={100}
                        minLength={1}
                        onBlur={this.handleBlur}
                        onChange={this.handleNewTextChange}
                        ref={c => (this.textInput = c)}
                        required
                        title="Change annotation"
                        value={this.state.newText}
                    />
                    <FontAwesomeIcon icon="pencil-alt" style={{ top: '2px', width: '24px' }} />
                    <input hidden type="submit" />
                </form>
            );
        }
    }

    renderAnnotationSharable() {
        return (
            <div className="mg-truncate uk-width-2-3 uk-flex">
                <label htmlFor={`edit-${this.props.id}-nonSharable`} className="cursor-pointer">
                    <input
                        checked={this.state.nonShareable}
                        onChange={this.handleCheckboxChange}
                        type="checkbox"
                        className="mg-margin-r-10 cursor-pointer"
                        id={`edit-${this.props.id}-nonSharable`}
                    />
                    Don&apos;t show in reports
                </label>
            </div>
        );
    }

    render() {
        return (
            <section className="mg-padding-10-0 mg-border-b" role="listitem">
                <div className="uk-flex uk-flex-top">
                    <span className="color-grey mg-margin-r-10">{this.renderIconType()}</span>
                    <div className="uk-flex-item-auto">{this.renderBody()}</div>
                    <div className="uk-flex-item-none uk-text-center">{this.renderAnnotationAction()}</div>
                </div>
                {!this.props.report && !this.isNonEditable() && (
                    <div className="uk-flex-item-auto">{this.renderAnnotationSharable()}</div>
                )}
            </section>
        );
    }
}

AnnotationsMessageItem.propTypes = {
    content: shape({
        avg: NumberOrNAType.isRequired,
        keywordsCount: NumberOrNAType.isRequired,
        text: StringOrNAType.isRequired,
    }).isRequired,
    deleting: bool.isRequired,
    id: string.isRequired,
    onDelete: func.isRequired,
    onEdit: func.isRequired,
    report: bool.isRequired,
    type: number.isRequired,
    updating: bool.isRequired,
    nonShareable: bool.isRequired,
};

export default AnnotationsMessageItem;
