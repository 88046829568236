import ActionTypes from 'constants/ActionTypes';

export const closeAll = () => ({
    type: ActionTypes.UI_ALL_CLOSE,
});

export const showAccessDeniedMessage = () => ({
    type: ActionTypes.UI_MESSAGES_ACCESS_DENIED_SHOW,
});

export const closeAccessDeniedMessage = () => ({
    type: ActionTypes.UI_MESSAGES_ACCESS_DENIED_CLOSE,
});

export const showNeedToSignInMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_SHOW,
});

export const closeNeedToSignInMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE,
});

export const showPricingMessage = () => ({
    type: ActionTypes.UI_MESSAGES_PRICING_SHOW,
});

export const closePricingMessage = () => ({
    type: ActionTypes.UI_MESSAGES_PRICING_CLOSE,
});

export const showFailureMessage = ({ details, header }) => ({
    type: ActionTypes.UI_MESSAGES_FAILURE_SHOW,
    error: false,
    payload: { details, header },
});

export const closeFailureMessage = () => ({
    type: ActionTypes.UI_MESSAGES_FAILURE_CLOSE,
});

export const showNeedToSelectKeywordMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NEED_TO_SELECT_KEYWORD_SHOW,
});

export const closeNeedToSelectKeywordMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NEED_TO_SELECT_KEYWORD_CLOSE,
});

export const setNavigatedInternally = () => ({
    type: ActionTypes.UI_MISC_NAVIGATED_INTERNALLY_SET,
});

export const receivedErrorNotificationAction = (message, config) => ({
    type: ActionTypes.UI_MISC_NOTIFICATIONS_ERROR_SHOW,
    error: false,
    payload: { message, config },
});

export const receivedInfoNotificationAction = (message, config) => ({
    type: ActionTypes.UI_MISC_NOTIFICATIONS_INFO_SHOW,
    error: false,
    payload: { message, config },
});

export const requestedCloseAllNotificationsAction = () => ({
    type: ActionTypes.UI_MISC_NOTIFICATIONS_CLOSE_ALL,
});

export const setNewTrackingDomain = newDomain => ({
    type: ActionTypes.UI_NEW_TRACKING_DOMAIN_SET,
    error: false,
    payload: newDomain,
});

export const closeImportListDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_IMPORT_LIST_CLOSE,
});

export const showImportListDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_IMPORT_LIST_SHOW,
});

export const resetNewTracking = () => ({
    type: ActionTypes.UI_NEW_TRACKING_RESET,
});

export const fillNewTrackingFromTemplate = payload => ({
    type: ActionTypes.UI_NEW_TRACKING_TEMPLATE_FILL,
    error: false,
    payload,
});

export const setNewTrackingFilterSettings = payload => ({
    type: ActionTypes.UI_NEW_TRACKING_FILTER_SETTINGS_SET,
    error: false,
    payload,
});

export const setNewTrackingConfigData = payload => ({
    type: ActionTypes.UI_NEW_TRACKING_CONFIG_DATA_SET,
    error: false,
    payload,
});

export const resetNewTrackingConfigData = () => ({
    type: ActionTypes.UI_NEW_TRACKING_CONFIG_DATA_RESET,
    error: false,
});

export const setNewTrackingSelectedFilterSettings = payload => ({
    type: ActionTypes.UI_NEW_TRACKING_SELECTED_FILTER_SETTINGS_SET,
    error: false,
    payload,
});

export const updateNewTrackingFilterSettings = payload => ({
    type: ActionTypes.UI_NEW_TRACKING_FILTER_SETTINGS_UPDATE,
    error: false,
    payload,
});

export const setNewTrackingSearchFilterSettings = payload => ({
    type: ActionTypes.UI_NEW_TRACKING_QUICK_FILTER_SETTINGS_SEARCH_SET,
    error: false,
    payload,
});

export const setNewTrackingKeywords = keywords => ({
    type: ActionTypes.UI_NEW_TRACKING_KEYWORDS_SET,
    error: false,
    payload: keywords,
});

export const setNewTrackingKeywordsFilteredCount = filteredCount => ({
    type: ActionTypes.UI_NEW_TRACKING_KEYWORDS_FILTERED_COUNT_SET,
    error: false,
    payload: filteredCount,
});

export const resetNewTrackingKeywords = () => ({
    type: ActionTypes.UI_NEW_TRACKING_KEYWORDS_RESET,
});

export const setNewTrackingSelectedList = (listId, selected) => ({
    type: ActionTypes.UI_NEW_TRACKING_SELECTED_LIST_SET,
    error: false,
    payload: { listId, selected },
});

export const resetNewTrackingSelectedLists = () => ({
    type: ActionTypes.UI_NEW_TRACKING_SELECTED_LISTS_RESET,
});

export const showDeleteConfirmationMessage = payload => ({
    type: ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_SHOW,
    error: false,
    payload,
});

export const closeDeleteConfirmationMessage = () => ({
    type: ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CLOSE,
});

export const confirmDeleteConfirmationMessage = () => ({
    type: ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM,
});

export const closeTrackingListDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TRACKING_LIST_CLOSE,
});

export const setSearchTrackingListDropdown = payload => ({
    type: ActionTypes.UI_DROPDOWNS_TRACKING_LIST_SEARCH_SET,
    error: false,
    payload,
});

export const showTrackingListDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TRACKING_LIST_SHOW,
});

export const closeLocationsListDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_LOCATIONS_LIST_CLOSE,
});

export const setSearchLocationsListDropdown = payload => ({
    type: ActionTypes.UI_DROPDOWNS_LOCATIONS_LIST_SEARCH_SET,
    error: false,
    payload,
});

export const showLocationsListDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_LOCAITONS_LIST_SHOW,
});

export const closeAddKeywordsPanel = () => ({
    type: ActionTypes.UI_PANELS_ADD_KEYWORDS_CLOSE,
});

export const showAddKeywordsPanel = () => ({
    type: ActionTypes.UI_PANELS_ADD_KEYWORDS_SHOW,
});

export const closeReportsPanel = () => ({
    type: ActionTypes.UI_PANELS_REPORTS_CLOSE,
});

export const showReportsPanel = () => ({
    type: ActionTypes.UI_PANELS_REPORTS_SHOW,
});

export const resetTrackingFilter = () => ({
    type: ActionTypes.UI_TRACKING_FILTER_RESET,
});

export const setTrackingFilterActive = payload => ({
    type: ActionTypes.UI_TRACKING_FILTER_ACTIVE_SET,
    error: false,
    payload,
});

export const setTrackingQuickFilterSearch = payload => ({
    type: ActionTypes.UI_TRACKING_QUICK_FILTER_SETTINGS_SEARCH_SET,
    error: false,
    payload,
});

export const setTrackingFilterSettings = payload => ({
    type: ActionTypes.UI_TRACKING_FILTER_SETTINGS_SET,
    error: false,
    payload,
});

export const toggleTrackingFilterVisibility = () => ({
    type: ActionTypes.UI_TRACKING_FILTER_VISIBILITY_TOGGLE,
});

export const closeTimeframeDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TIMEFRAME_CLOSE,
});

export const showTimeframeDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TIMEFRAME_SHOW,
});

export const setTimeframe = ({ from, to, type }) => ({
    type: ActionTypes.UI_TRACKING_TIMEFRAME_SET,
    payload: { from, to, type },
});

export const resetTracking = () => ({
    type: ActionTypes.UI_TRACKING_RESET,
});

export const setSelectedTrackingKeywords = payload => ({
    type: ActionTypes.UI_TRACKING_KEYWORDS_SET,
    error: false,
    payload,
});

export const selectTrackingKeywords = keywordIds => ({
    type: ActionTypes.UI_TRACKING_KEYWORDS_SELECT,
    error: false,
    payload: keywordIds,
});

export const unselectTrackingKeywords = keywordIds => ({
    type: ActionTypes.UI_TRACKING_KEYWORDS_UNSELECT,
    error: false,
    payload: keywordIds,
});

export const unselectAllTrackingKeywords = () => ({
    type: ActionTypes.UI_TRACKING_KEYWORDS_UNSELECT_ALL,
});

export const requestedSelectAllTrackingKeywords = () => ({
    type: ActionTypes.UI_TRACKING_KEYWORDS_SELECT_ALL_REQUESTED,
});

export const showTrackingKeywordDetail = keywordId => ({
    type: ActionTypes.UI_TRACKING_KEYWORD_DETAIL_SHOW,
    error: false,
    payload: keywordId,
});

export const closeTrackingKeywordDetail = keywordId => ({
    type: ActionTypes.UI_TRACKING_KEYWORD_DETAIL_CLOSE,
    error: false,
    payload: keywordId,
});

export const resetTrackingKeywordDetail = () => ({
    type: ActionTypes.UI_TRACKING_KEYWORD_DETAIL_RESET,
});

export const showTrackingResultsSerpModal = ({ keyword, url }) => ({
    type: ActionTypes.UI_MODALS_SERP_MODAL_SHOW,
    error: false,
    payload: { keyword, url },
});

export const closeTrackingResultSerpModal = () => ({
    type: ActionTypes.UI_MODALS_SERP_MODAL_CLOSE,
});

export const setAddKeywordsPanelKeywords = ({ keywordsInLimit, keywordsInTracking, keywordsNotInLimit }) => ({
    type: ActionTypes.UI_PANELS_ADD_KEYWORDS_KEYWORDS_SET,
    error: false,
    payload: { keywordsInLimit, keywordsInTracking, keywordsNotInLimit },
});

export const requestedAddKeywordsPanelKeywordsAdd = ({ keywords, listCount = null }) => ({
    type: ActionTypes.UI_PANELS_ADD_KEYWORDS_KEYWORDS_ADD_REQUESTED,
    error: false,
    payload: { keywords, listCount },
});

export const requestedAddKeywordsPanelKeywordsSet = payload => ({
    type: ActionTypes.UI_PANELS_ADD_KEYWORDS_KEYWORDS_SET_REQUESTED,
    error: false,
    payload,
});

export const resetAddKeywordsPanelKeywords = () => ({
    type: ActionTypes.UI_PANELS_ADD_KEYWORDS_KEYWORDS_RESET,
});

export const setAddKeywordsPanelSelectedList = (listId, selected) => ({
    type: ActionTypes.UI_PANELS_ADD_KEYWORDS_SELECTED_LIST_SET,
    error: false,
    payload: { listId, selected },
});

export const resetAddKeywordsPanelSelectedLists = () => ({
    type: ActionTypes.UI_PANELS_ADD_KEYWORDS_SELECTED_LISTS_RESET,
});

export const closeHelpDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_HELP_CLOSE,
});

export const showHelpDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_HELP_SHOW,
});

export const showShareModal = () => ({
    type: ActionTypes.UI_MODALS_SHARE_MODAL_SHOW,
});

export const closeShareModal = () => ({
    type: ActionTypes.UI_MODALS_SHARE_MODAL_CLOSE,
});

export const showCreatedTrackingTipModal = tipId => ({
    type: ActionTypes.UI_MODALS_CREATED_TRACKING_TIPS_MODAL_SHOW,
    payload: tipId,
});

export const closeCreatedTrackingTipModal = () => ({
    type: ActionTypes.UI_MODALS_CREATED_TRACKING_TIPS_MODAL_CLOSE,
});

export const showUnauthorizedMessage = () => ({
    type: ActionTypes.UI_MESSAGES_UNAUTHORIZED_SHOW,
});

export const closeUnauthorizedMessage = () => ({
    type: ActionTypes.UI_MESSAGES_UNAUTHORIZED_CLOSE,
});

export const closeTrackingActionsDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TRACKING_ACTIONS_CLOSE,
});

export const showTrackingActionsDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TRACKING_ACTIONS_SHOW,
});

export const closeTrackingItemActionsDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TRACKING_ITEM_ACTIONS_CLOSE,
});

export const showTrackingItemActionsDropdown = trackingId => ({
    type: ActionTypes.UI_DROPDOWNS_TRACKING_ITEM_ACTIONS_SHOW,
    error: false,
    payload: trackingId,
});

export const closeTrackingsActionsDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TRACKINGS_ACTIONS_CLOSE,
});

export const showTrackingsActionsDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TRACKINGS_ACTIONS_SHOW,
    error: false,
});

export const setReportsPanelNewType = newType => ({
    type: ActionTypes.UI_PANELS_REPORTS_NEW_TYPE_SET,
    error: false,
    payload: newType,
});

export const setReportsPanelNewName = newName => ({
    type: ActionTypes.UI_PANELS_REPORTS_NEW_NAME_SET,
    error: false,
    payload: newName,
});

export const setReportsPanelNewEmails = newEmails => ({
    type: ActionTypes.UI_PANELS_REPORTS_NEW_EMAILS_SET,
    error: false,
    payload: newEmails,
});

export const setReportsPanelNewAlertTriggersSelected = (alertTriggerId, selected) => ({
    type: ActionTypes.UI_PANELS_REPORTS_NEW_ALERT_TRIGGERS_SELECTED_SET,
    error: false,
    payload: {
        id: alertTriggerId,
        selected,
    },
});

export const setReportsPanelEditType = newType => ({
    type: ActionTypes.UI_PANELS_REPORTS_EDIT_TYPE_SET,
    error: false,
    payload: newType,
});

export const setReportsPanelEditName = newName => ({
    type: ActionTypes.UI_PANELS_REPORTS_EDIT_NAME_SET,
    error: false,
    payload: newName,
});

export const setReportsPanelEditEmails = newEmails => ({
    type: ActionTypes.UI_PANELS_REPORTS_EDIT_EMAILS_SET,
    error: false,
    payload: newEmails,
});

export const setReportsPanelEditAlertTriggersSelected = (alertTriggerId, selected) => ({
    type: ActionTypes.UI_PANELS_REPORTS_EDIT_ALERT_TRIGGERS_SELECTED_SET,
    error: false,
    payload: {
        id: alertTriggerId,
        selected,
    },
});

export const requestedReportsPanelEdit = ({ alertTriggers, emails, name, type }) => ({
    type: ActionTypes.UI_PANELS_REPORTS_EDIT_REQUESTED,
    error: false,
    payload: {
        alertTriggers,
        emails,
        name,
        type,
    },
});

export const setReportsPanelCurrentReportId = newId => ({
    type: ActionTypes.UI_PANELS_REPORTS_CURRENT_REPORT_ID_SET,
    error: false,
    payload: newId,
});

export const setAddKeywordsPanelFilteredCount = filteredCount => ({
    type: ActionTypes.UI_PANELS_ADD_KEYWORDS_FILTERED_COUNT_SET,
    error: false,
    payload: filteredCount,
});

export const requestedNewTrackingKeywordsAdd = ({ keywords, listCount = null }) => ({
    type: ActionTypes.UI_NEW_TRACKING_KEYWORDS_ADD_REQUESTED,
    error: false,
    payload: { keywords, listCount },
});

export const requestedNewTrackingKeywordsSet = payload => ({
    type: ActionTypes.UI_NEW_TRACKING_KEYWORDS_SET_REQUESTED,
    error: false,
    payload,
});

export const requestedNewTrackingSuggestedKeywordsSet = payload => ({
    type: ActionTypes.UI_NEW_TRACKING_ALL_SUGGESTED_KEYWORDS_SET_REQUESTED,
    error: false,
    payload,
});

export const showTagsAddKwDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TAGS_ADDKW_SHOW,
});

export const closeTagsAddKwDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TAGS_ADDKW_CLOSE,
});

export const closeTagsBulkDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TAGS_BULK_CLOSE,
});

export const showTagsBulkDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TAGS_BULK_SHOW,
});

export const closeTagsDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TAGS_CLOSE,
});

export const showTagsDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TAGS_SHOW,
});

export const closeManageTagsPanel = () => ({
    type: ActionTypes.UI_PANELS_MANAGE_TAGS_CLOSE,
});

export const showManageTagsPanel = () => ({
    type: ActionTypes.UI_PANELS_MANAGE_TAGS_SHOW,
});

export const setAddKwTagsDropdownSelectedTag = ({ tagId, selected }) => ({
    type: ActionTypes.UI_DROPDOWNS_TAGS_ADDKW_SELECTED_TAG_SET,
    payload: { tagId, selected },
});

export const setBulkTagsDropdownSelectedTag = ({ tagId, selected }) => ({
    type: ActionTypes.UI_DROPDOWNS_TAGS_BULK_SELECTED_TAG_SET,
    error: false,
    payload: { tagId, selected },
});

export const resetBulkTagsDropdownSelectedTags = () => ({
    type: ActionTypes.UI_DROPDOWNS_TAGS_BULK_SELECTED_TAGS_RESET,
});

export const setTagsDropdownSelectedTag = ({ tagId, selected }) => ({
    type: ActionTypes.UI_DROPDOWNS_TAGS_SELECTED_TAG_SET,
    error: false,
    payload: { tagId, selected },
});

export const resetTagsDropdownSelectedTags = () => ({
    type: ActionTypes.UI_DROPDOWNS_TAGS_SELECTED_TAGS_RESET,
});

export const closeTopLevelAnnouncementMessage = () => ({
    type: ActionTypes.UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE,
});

export const showManageTagsColorDropdown = tagId => ({
    type: ActionTypes.UI_DROPDOWNS_MANAGE_TAGS_COLOR_SHOW,
    error: false,
    payload: tagId,
});

export const closeManageTagsColorDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_MANAGE_TAGS_COLOR_CLOSE,
});

export const showExportDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_EXPORT_SHOW,
});

export const closeExportDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_EXPORT_CLOSE,
});

export const showNewTrackingInfoMessage = ({ postponedProcessing, estimatedTimeProcessing }) => ({
    type: ActionTypes.UI_MESSAGES_NEW_TRACKING_INFO_SHOW,
    error: false,
    payload: {
        postponedProcessing,
        estimatedTimeProcessing,
    },
});

export const closeNewTrackingInfoMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NEW_TRACKING_INFO_CLOSE,
});

export const showTrackingAlreadyExistsMessage = ({ trackingId, keywords }) => ({
    type: ActionTypes.UI_MESSAGES_TRACKING_ALREADY_EXISTS_SHOW,
    error: false,
    payload: {
        trackingId,
        keywords,
    },
});

export const closeTrackingAlreadyExistsMessage = () => ({
    type: ActionTypes.UI_MESSAGES_TRACKING_ALREADY_EXISTS_CLOSE,
});

export const requestedTrackingOpenAndFillAddKeywordsPanel = ({ trackingId, keywords }) => ({
    type: ActionTypes.UI_MISC_OPEN_TRACKING_AND_FILL_KWS_PANEL,
    error: false,
    payload: {
        trackingId,
        keywords,
    },
});

export const showCreateTrackingMessage = ({ domain, platformLabel, templateTrackingId }) => ({
    type: ActionTypes.UI_MESSAGES_CREATE_TRACKING_SHOW,
    error: false,
    payload: {
        domain,
        platformLabel,
        templateTrackingId,
    },
});

export const closeCreateTrackingMessage = () => ({
    type: ActionTypes.UI_MESSAGES_CREATE_TRACKING_CLOSE,
});

export const showUpsellMessage = () => ({
    type: ActionTypes.UI_MESSAGES_UPSELL_SHOW,
});

export const closeUpsellMessage = () => ({
    type: ActionTypes.UI_MESSAGES_UPSELL_CLOSE,
});

export const setOnlineStatus = ({ onlineStatus }) => ({
    type: ActionTypes.UI_MISC_ONLINE_STATUS_SET,
    error: false,
    payload: {
        onlineStatus,
    },
});

export const showNoConnectionMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NO_CONNECTION_SHOW,
});

export const closeNoConnectionMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NO_CONNECTION_CLOSE,
});

export const showAddedKeywordsMessage = ({
    keywords,
    platformLabel,
    postponedProcessing,
    estimatedTimeProcessing,
    templateTrackingId,
}) => ({
    type: ActionTypes.UI_MESSAGES_ADDED_KEYWORDS_SHOW,
    error: false,
    payload: {
        keywords,
        platformLabel,
        postponedProcessing,
        estimatedTimeProcessing,
        templateTrackingId,
    },
});

export const closeAddedKeywordsMessage = () => ({
    type: ActionTypes.UI_MESSAGES_ADDED_KEYWORDS_CLOSE,
});

export const showAnnotationsMessage = date => ({
    type: ActionTypes.UI_MESSAGES_ANNOTATIONS_SHOW,
    error: false,
    payload: date,
});

export const closeAnnotationsMessage = () => ({
    type: ActionTypes.UI_MESSAGES_ANNOTATIONS_CLOSE,
});

export const setTrackingAnnotationDeletingId = annotationId => ({
    type: ActionTypes.UI_TRACKING_ANNOTATION_DELETING_ID_SET,
    error: false,
    payload: annotationId,
});

export const setTrackingAnnotationUpdatingId = annotationId => ({
    type: ActionTypes.UI_TRACKING_ANNOTATION_UPDATING_ID_SET,
    error: false,
    payload: annotationId,
});

export const setNewVersionNotificationShown = () => ({
    type: ActionTypes.UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET,
});

export const setTrackingFilter = filter => ({
    type: ActionTypes.UI_TRACKING_LIST_FILTER_SET,
    error: false,
    payload: filter,
});

export const setTrackingListOpenedCards = payload => ({
    type: ActionTypes.UI_TRACKING_LIST_OPENED_CARD_SET,
    error: false,
    payload,
});

export const showAppPanel = () => ({
    type: ActionTypes.UI_PANELS_APP_SHOW,
});

export const closeAppPanel = () => ({
    type: ActionTypes.UI_PANELS_APP_CLOSE,
});

export const showLoggedOutMessage = () => ({
    type: ActionTypes.UI_MESSAGES_LOGGED_OUT_SHOW,
});

export const closeLoggedOutMessage = () => ({
    type: ActionTypes.UI_MESSAGES_LOGGED_OUT_CLOSE,
});

export const setAddKwTags = tagIds => ({
    type: ActionTypes.UI_PANELS_SET_ADDKW_TAGS,
    payload: tagIds,
});

export const unassignAddKwTagsDropdownTag = tagId => ({
    type: ActionTypes.UI_PANELS_UNASSIGN_ADDKW_TAG,
    payload: tagId,
});

export const resetAddKwTagsDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TAGS_ADDKW_SELECTED_TAGS_RESET,
});

export const showShortcutsMessage = () => ({
    type: ActionTypes.UI_MESSAGES_SHORTCUTS_SHOW,
});

export const closeShortcutsMessage = () => ({
    type: ActionTypes.UI_MESSAGES_SHORTCUTS_CLOSE,
});

export const setScrollResultListKwId = kwId => ({
    type: ActionTypes.UI_MISC_SCROLL_RESULT_LIST_KW_ID_SET,
    payload: kwId,
});

export const setColorScheme = payload => ({
    type: ActionTypes.UI_MISC_COLOR_SCHEME_SET,
    payload,
});

export const showInvalidShareTokenMessage = () => ({
    type: ActionTypes.UI_MESSAGES_INVALID_SHARE_TOKEN_SHOW,
});

export const closeInvalidShareTokenMessage = () => ({
    type: ActionTypes.UI_MESSAGES_INVALID_SHARE_TOKEN_CLOSE,
});

export const setTrackingQuickFilterTagIds = payload => ({
    type: ActionTypes.UI_TRACKING_QUICK_FILTER_SETTINGS_TAG_IDS_SET,
    error: false,
    payload,
});

export const closeTagsFilterDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TAGS_FILTER_CLOSE,
});

export const showTagsFilterDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_TAGS_FILTER_SHOW,
});

export const updateTagFilter = payload => ({
    type: ActionTypes.UI_TRACKING_QUICK_FILTER_TAGS_UPDATE,
    payload,
});

export const showEditTrackingMessage = ({ domain, location, id, platformId, trackingConfig }) => ({
    type: ActionTypes.UI_MESSAGES_EDIT_TRACKING_DOMAIN_SHOW,
    payload: { domain, location, id, platformId, trackingConfig },
});

export const closeEditTrackingMessage = () => ({
    type: ActionTypes.UI_MESSAGES_EDIT_TRACKING_DOMAIN_CLOSE,
});

export const showImportKwfListMessage = target => {
    return {
        type: ActionTypes.UI_MESSAGES_IMPORT_KWF_LIST_SHOW,
        payload: target,
    };
};

export const closeImportKwfListMessage = () => ({
    type: ActionTypes.UI_MESSAGES_IMPORT_KWF_LIST_CLOSE,
});

export const showCustomizeReportPanel = () => ({
    type: ActionTypes.UI_PANELS_CUSTOMIZE_REPORTS_SHOW,
});

export const closeCustomizeReportPanel = () => ({
    type: ActionTypes.UI_PANELS_CUSTOMIZE_REPORTS_CLOSE,
});

export const requestCustomizeReportPanelClose = () => ({
    type: ActionTypes.UI_PANELS_CUSTOMIZE_REPORTS_CLOSE_REQUESTED,
});

export const setCustomizeReportPanelDirty = () => ({
    type: ActionTypes.UI_PANELS_CUSTOMIZE_REPORTS_DIRTY_SET,
});

export const showCustomizeResultsPanel = () => ({
    type: ActionTypes.UI_PANELS_CUSTOMIZE_RESULTS_SHOW,
});

export const closeCustomizeResultsPanel = () => ({
    type: ActionTypes.UI_PANELS_CUSTOMIZE_RESULTS_CLOSE,
});

export const setIsWithDeletedTrackingsSetting = isWithDeletedTrackings => ({
    type: ActionTypes.UI_SETTINGS_IS_WITH_DELETED_TRACKINGS,
    payload: isWithDeletedTrackings,
});

export const setIsWithDeletedKeywordsSetting = isWithDeletedKeywords => ({
    type: ActionTypes.UI_SETTINGS_IS_WITH_DELETED_KEYWORDS,
    payload: isWithDeletedKeywords,
});

export const setIsWithGroupedByDomain = isGroupedByDomain => ({
    type: ActionTypes.UI_SETTINGS_IS_WITH_GROUPED_BY_DOMAIN,
    payload: isGroupedByDomain,
});
