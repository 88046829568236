import { map, pipe } from 'ramda';

// const PARSED_INDEX_DATA = [{
//     id: '566ef1b8d089f81d5464d8d1',
//     createdAt: 1450111416249,
//     keywordCount: 2,
//     keywordIds: [
//         '23a4f3b86f225415ded39c4e568d0efa',
//         '19b354eeb9b6192614c310daf2f19c9d'
//     ],
//     name: 'Test list',
//     userId: '559ef2cd69702d0cd9020000'
// }];

export const parseIndex = rawData =>
    rawData.map(item => ({
        id: item._id, // eslint-disable-line no-underscore-dangle
        createdAt: item.created_at,
        keywordIds: item.keyword_ids,
        name: item.name,
        userId: item.user_id,
    }));

export const parseDetail = pipe(
    data => data.keywords,
    map(keyword => keyword.kw),
);
