import { useEffect, useState } from 'react';

const useChangeReaction = (currentValue: boolean, reactionPredicate: boolean, callback: () => void) => {
    const [isDone, setDone] = useState(false);
    const [previousValue, setPreviousValue] = useState(currentValue);

    useEffect(() => {
        if (currentValue !== previousValue && reactionPredicate) {
            callback();
            setDone(true);
        }
        setPreviousValue(currentValue);
    }, [currentValue, isDone, reactionPredicate]);

    return isDone;
};

export default useChangeReaction;