import { useState } from 'react';

function useSearch(initialValue = '') {
    const [search, setSearch] = useState(initialValue);
    const sanitizedSearch = search.trim().toLowerCase();

    return [{ search, sanitizedSearch }, setSearch];
}

export { useSearch };
