import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MessageHolder from 'components/messages/MessageHolder';
import DeleteResourceTypes from 'constants/DeleteResourceTypes';

const DeleteConfirmationMessage = props => {
    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose}>
                <div className="mg-modal-header is-error">
                    <FontAwesomeIcon icon={['far', 'trash-alt']} aria-label="Delete" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold">Delete {props.resourceType}?</h3>

                    {props.resourceType === DeleteResourceTypes.TRACKING ? (
                        <p className="uk-margin-top color-grey font-18">
                            Do you really want to
                            <strong className="color-black">
                                {/* eslint-disable-next-line max-len */}
                                {` delete ${props.resourceDetails.domain}  / ${props.resourceDetails.location.label} / ${props.resourceDetails.platform} tracking?`}
                            </strong>
                            &nbsp;
                        </p>
                    ) : (
                        <p className="uk-margin-top color-grey font-18">
                            Do you really want to
                            <strong className="color-black">
                                &nbsp;delete&nbsp;{props.resourceName}&nbsp;{props.resourceType}?
                            </strong>
                            &nbsp;
                        </p>
                    )}
                    <div>
                        <button
                            className="mg-btn is-red is-small mg-margin-r-5"
                            onClick={props.onConfirm}
                            type="button"
                        >
                            Yes, delete {props.resourceType}
                        </button>
                        <button className="mg-btn is-link is-small" onClick={props.onClose} type="button">
                            No, take me back
                        </button>
                    </div>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
};

DeleteConfirmationMessage.propTypes = {
    onClose: func.isRequired,
    onConfirm: func.isRequired,
    resourceName: string.isRequired,
    resourceDetails: shape({
        location: shape({
            label: string.isRequired,
        }),
        domain: string.isRequired,
        platform: string.isRequired,
    }),
    resourceType: string.isRequired,
    visible: bool.isRequired,
};

export default DeleteConfirmationMessage;
