import update from 'immutability-helper';
import concat from 'ramda/src/concat';
import includes from 'ramda/src/includes';
import ActionTypes from 'constants/ActionTypes';

import { CREATED_FROM_BULK_DROPDOWN, CREATED_FROM_DROPDOWN, CREATED_FROM_ADDKW_DROPDOWN } from 'constants/Other';

const initialState = {
    export: {
        visibility: false,
    },
    help: {
        visibility: false,
    },
    importList: {
        visibility: false,
    },
    manageTagsColor: {
        visibleId: null,
    },
    tags: {
        selectedIds: [],
        visibility: false,
    },
    tagsBulk: {
        selectedIds: [],
        visibility: false,
    },
    tagsAddKw: {
        selectedIds: [],
        visibility: false,
    },
    tagsFilter: {
        selectedIds: [],
        visibility: false,
    },
    timeframe: {
        visibility: false,
    },
    trackingActions: {
        visibility: false,
    },
    trackingItemActions: {
        visibleId: null,
    },
    trackingsActions: {
        visible: false,
    },
    trackingList: {
        visibility: false,
        search: '',
    },
    locationsList: {
        visibility: false,
        search: '',
    },
    user: {
        visibility: false,
    },
};

const dropdownsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_DROPDOWNS_EXPORT_CLOSE: {
            return update(state, {
                export: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_EXPORT_SHOW: {
            return update(state, {
                export: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_HELP_CLOSE: {
            return update(state, {
                help: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_HELP_SHOW: {
            return update(state, {
                help: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_LINKS_CLOSE: {
            return update(state, {
                links: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_LINKS_SHOW: {
            return update(state, {
                links: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TIMEFRAME_CLOSE: {
            return update(state, {
                timeframe: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TIMEFRAME_SHOW: {
            return update(state, {
                timeframe: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TRACKING_LIST_CLOSE: {
            return update(state, {
                trackingList: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TRACKING_LIST_SHOW: {
            return update(state, {
                trackingList: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TRACKING_LIST_SEARCH_SET: {
            return update(state, {
                trackingList: {
                    search: {
                        $set: action.payload,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_LOCATIONS_LIST_CLOSE: {
            return update(state, {
                locationsList: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_LOCAITONS_LIST_SHOW: {
            return update(state, {
                locationsList: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_LOCATIONS_LIST_SEARCH_SET: {
            return update(state, {
                locationsList: {
                    search: {
                        $set: action.payload,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TRACKING_ACTIONS_CLOSE: {
            return update(state, {
                trackingActions: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TRACKING_ACTIONS_SHOW: {
            return update(state, {
                trackingActions: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TRACKING_ITEM_ACTIONS_CLOSE: {
            return update(state, {
                trackingItemActions: {
                    visibleId: {
                        $set: null,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TRACKING_ITEM_ACTIONS_SHOW: {
            return update(state, {
                trackingItemActions: {
                    visibleId: {
                        $set: action.payload,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TRACKINGS_ACTIONS_CLOSE: {
            return update(state, {
                trackingsActions: {
                    visible: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TRACKINGS_ACTIONS_SHOW: {
            return update(state, {
                trackingsActions: {
                    visible: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_MANAGE_TAGS_COLOR_SHOW: {
            return update(state, {
                manageTagsColor: {
                    visibleId: {
                        $set: action.payload,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_MANAGE_TAGS_COLOR_CLOSE: {
            return update(state, {
                manageTagsColor: {
                    visibleId: {
                        $set: null,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_IMPORT_LIST_CLOSE: {
            return update(state, {
                importList: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_IMPORT_LIST_SHOW: {
            return update(state, {
                importList: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TAGS_BULK_CLOSE: {
            return update(state, {
                tagsBulk: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TAGS_BULK_SHOW: {
            return update(state, {
                tagsBulk: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TAGS_CLOSE: {
            return update(state, {
                tags: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TAGS_ADDKW_SHOW: {
            return update(state, {
                tagsAddKw: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TAGS_ADDKW_CLOSE: {
            return update(state, {
                tagsAddKw: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TAGS_SHOW: {
            return update(state, {
                tags: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TAGS_FILTER_CLOSE: {
            return update(state, {
                tagsFilter: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TAGS_FILTER_SHOW: {
            return update(state, {
                tagsFilter: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TAGS_FILTER_SELECTED_TAG_SET: {
            const { tagId, selected } = action.payload;

            if (selected === true) {
                return update(state, {
                    tagsFilter: {
                        selectedIds: {
                            $apply: ids => {
                                if (includes(ids, tagId)) {
                                    return ids;
                                } else {
                                    return concat(ids, [tagId]);
                                }
                            },
                        },
                    },
                });
            } else {
                return update(state, {
                    tagsFilter: {
                        selectedIds: {
                            $apply: ids => ids.filter(id => id !== tagId),
                        },
                    },
                });
            }
        }
        case ActionTypes.UI_DROPDOWNS_TAGS_ADDKW_SELECTED_TAG_SET: {
            const { tagId, selected } = action.payload;

            if (selected === true) {
                return update(state, {
                    tagsAddKw: {
                        selectedIds: {
                            $apply: ids => {
                                if (includes(ids, tagId)) {
                                    return ids;
                                } else {
                                    return concat(ids, [tagId]);
                                }
                            },
                        },
                    },
                });
            } else {
                return update(state, {
                    tagsAddKw: {
                        selectedIds: {
                            $apply: ids => ids.filter(id => id !== tagId),
                        },
                    },
                });
            }
        }
        case ActionTypes.UI_DROPDOWNS_TAGS_ADDKW_SELECTED_TAGS_RESET: {
            return update(state, {
                tagsAddKw: {
                    selectedIds: { $set: [] },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TAGS_BULK_SELECTED_TAG_SET: {
            const { tagId, selected } = action.payload;

            if (selected === true) {
                return update(state, {
                    tagsBulk: {
                        selectedIds: {
                            $apply: ids => {
                                if (includes(ids, tagId)) {
                                    return ids;
                                } else {
                                    return concat(ids, [tagId]);
                                }
                            },
                        },
                    },
                });
            } else {
                return update(state, {
                    tagsBulk: {
                        selectedIds: {
                            $apply: ids => ids.filter(id => id !== tagId),
                        },
                    },
                });
            }
        }
        case ActionTypes.UI_DROPDOWNS_TAGS_BULK_SELECTED_TAGS_RESET: {
            return update(state, {
                tagsBulk: {
                    selectedIds: { $set: [] },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_TAGS_SELECTED_TAG_SET: {
            const { tagId, selected } = action.payload;

            if (selected === true) {
                return update(state, {
                    tags: {
                        selectedIds: {
                            $apply: ids => {
                                if (includes(ids, tagId)) {
                                    return ids;
                                } else {
                                    return concat(ids, [tagId]);
                                }
                            },
                        },
                    },
                });
            } else {
                return update(state, {
                    tags: {
                        selectedIds: {
                            $apply: ids => ids.filter(id => id !== tagId),
                        },
                    },
                });
            }
        }
        case ActionTypes.UI_DROPDOWNS_TAGS_SELECTED_TAGS_RESET: {
            return update(state, {
                tags: {
                    selectedIds: { $set: [] },
                },
            });
        }
        case ActionTypes.UI_TRACKING_KEYWORD_DETAIL_SHOW: {
            return update(state, {
                tags: {
                    visibility: { $set: false },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_CREATE_RECEIVED: {
            if (action.payload.createdFrom === CREATED_FROM_BULK_DROPDOWN) {
                return update(state, {
                    tagsBulk: {
                        selectedIds: {
                            $push: [action.payload.tag.id],
                        },
                    },
                });
            } else if (action.payload.createdFrom === CREATED_FROM_DROPDOWN) {
                return update(state, {
                    tags: {
                        selectedIds: {
                            $push: [action.payload.tag.id],
                        },
                    },
                });
            } else if (action.payload.createdFrom === CREATED_FROM_ADDKW_DROPDOWN) {
                return update(state, {
                    tagsAddKw: {
                        selectedIds: {
                            $push: [action.payload.tag.id],
                        },
                    },
                });
            } else {
                return state;
            }
        }
        case ActionTypes.DATA_TRACKING_TAGS_BULK_ASSIGN_RECEIVED:
        case ActionTypes.DATA_TRACKING_TAGS_BULK_UNASSIGN_RECEIVED: {
            return update(state, {
                tagsBulk: {
                    selectedIds: {
                        $set: [],
                    },
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_ASSIGN_RECEIVED:
        case ActionTypes.DATA_TRACKING_TAGS_UNASSIGN_RECEIVED: {
            return update(state, {
                tags: {
                    selectedIds: {
                        $set: [],
                    },
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_TRACKING_RESET:
        case ActionTypes.UI_ALL_CLOSE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default dropdownsReducer;
